import React from "react"
import { Spinner } from "reactstrap"
export function Loader() {
    return <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spinner
            color="primary"
            size="lg"
            type="grow"
        />
    </div>
}