import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { SampleCard } from "../../../../constant";
import { productData, productColumns } from "../../../../data/product-list";
import ClientData from "../../../clients/clientData";
import { useParams } from "react-router-dom";

export function ApiHelper(url, data = {}, method = "GET") {
  let bearer = "Bearer " + localStorage.getItem("user_token");
  return fetch(url, {
    // Return promise
    method: method,
    withCredentials: true,
    // credentials: 'include',
    headers: {
      // 'Authorization': bearer,
      // 'X-FP-API-KEY': 'chaptoken',
      // 'Content-Type': 'application/json'
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        error = error;
      }
    );
}

const Home = () => {
  ApiHelper("https://jsonplaceholder.typicode.aaacom/posts?_limit=10").then(
    (resposnse) => {}
  );

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        paddingLeft: "0 8px",
        justifyContent: "center",
        backgroundColor: "#FFA500",
      },
    },
  };

  const [addModal, setaddModal] = useState(false);
  const [clients, setClients] = useState({
    total_records: 0,
    data: [],
  });
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    setClients({
      total_records: 2,
      data: [
        {
          id: "ID 1",
          name: "namename",
          cell: "cellcell",
          city: "citycity",
          createdAt: "2023-02-22",
        },
        {
          id: "ID 1",
          name: "namename",
          cell: "cellcell",
          city: "citycity",
          createdAt: "2023-02-24",
        },
      ],
    });
  }, []);

  // let searchParams = [];
  // {
  //     SearchConfig.params.map((items) =>
  //         searchParams[items.name] = ''
  //     )
  // }
  // searchParams = { ...searchParams }
  // const [searchReq, setSearchReq] = useState(searchParams);
  // const searchConfig = { ...searchReq, SearchConfig}

  // let params = useParams();
  // const type = (params.type) ? params.type : "Search" + " Clients";
  const type = "Search Clients";

  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortBy: "id",
    order: "ASC",
    hideOnNoData: 0,
  });

  const handleFavClient = (id) => {};

  const handlePagination = (paginationData) => {
    setPaginationConfig(paginationData);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    // setSearchReq(searchReq)
  };

  const addToggle = () => {
    setaddModal(!addModal);
  };
  return (
    <Fragment>
      <Breadcrumb parent="Project" title="Overview" />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Home</h5>
                <span>
                  {"lorem ipsum dolor sit amet, consectetur adipisicing elit"}
                </span>
              </CardHeader>
              <CardBody>
                <button
                  className="badge-light-primary btn-block btn-mail"
                  onClick={addToggle}
                >
                  {"Open Modal"}
                </button>
                <Modal isOpen={addModal} toggle={addToggle} size="lg">
                  <ModalHeader toggle={addToggle}>{"AddTask"}</ModalHeader>
                  <ModalBody>
                    <Form
                      className="form-bookmark needs-validation"
                      onSubmit={handleSubmit("Addtask")}
                    >
                      <div className="form-row">
                        <FormGroup className="col-md-12">
                          <Label>{"TaskTitle"}</Label>
                          <Input
                            className="form-control"
                            name="title"
                            type="text"
                            innerRef={register({ required: true })}
                          />
                          <span style={{ color: "red" }}>
                            {errors.title && "Title is required"}
                          </span>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <Label>{"Collection"}</Label>
                          <Input
                            className="js-example-disabled-results"
                            name="collection"
                            type="select"
                            innerRef={register({ required: true })}
                          >
                            <option value="general">{"General"}</option>
                            <option value="fs">{"Fs"}</option>
                          </Input>
                        </FormGroup>
                        <FormGroup className="col-md-12">
                          <Label>{"Description"}</Label>
                          <Input
                            className="form-control"
                            name="desc"
                            type="textarea"
                            innerRef={register({ required: true })}
                          ></Input>
                          <span style={{ color: "red" }}>
                            {errors.desc && "Description is required"}
                          </span>
                        </FormGroup>
                      </div>
                      <Button color="secondary" className="mr-1">
                        {"Save"}
                      </Button>
                      <Button color="primary" onClick={addToggle}>
                        {"Cancel"}
                      </Button>
                    </Form>
                  </ModalBody>
                </Modal>

                <div className="table-responsive product-table">
                  <Row>
                    <Col sm="12">
                      <ClientData
                        loading={false}
                        type={type}
                        paginationConfig={paginationConfig}
                        data={clients}
                        onClickFav={(value) => handleFavClient(value)}
                        onClickSort={(value) => handleSorting(value)}
                        onClickPagination={(value) => handlePagination(value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    columns={productColumns}
                    data={productData}
                    customStyles={tableCustomStyles}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Home;
