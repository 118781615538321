import React from "react";

class TrialEndedMessage extends React.Component {
  render() {
    return (
      <div
        className="container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="card text-center" style={{ maxWidth: "300px" }}>
          <div className="card-body">
            <h2 className="card-title">Your Free Trial Has Ended</h2>
            <p className="card-text">
              We appreciate you trying out our service. If you would like to
              continue, please consider subscribing to our premium plan.
            </p>
            {/* Add a button or link to redirect users to the subscription page */}
            {/* <button
              className="btn btn-primary"
              onClick={() => alert("Redirect to subscription page")}
            >
              Subscribe Now
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default TrialEndedMessage;
