import React, { Fragment } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
// import SortBy from '../../../../common/sortBy';

import { useHistory } from "react-router-dom";
import TableComponent from "../../../common/TableComponent";
import Spinner from "../../../common/spinner";
const CategoryData = (props) => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const handleAction = (id) => {
    history.push(`/branch/edit-branch/${id}`);
    // props.onClickAction(id)
  };
  const schoolTableColumns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: false,
    },
    // {
    //     name: 'Employee Count',
    //     selector: 'employee_count',
    //     sortable: true,
    //     center: true,
    // },
    // {
    //     name: 'Address',
    //     selector: 'address',
    //     sortable: true,
    //     center: true,
    // },
    // {
    //     name: 'No. of Student',
    //     selector: 'studentsCount',
    //     sortable: true,
    //     center: true,
    // },
    // {
    //     name: 'No. of Teacher',
    //     selector: 'teachersCount',
    //     sortable: true,
    //     center: true,
    // },
    {
      name: "Actions",
      selector: "actions",
      center: true,
    },
  ];

  useEffect(() => {
    var BranchData = [];
    const data = props.categories;
    data.forEach((element) => {
      const mappedData = {
        name: element.name,
        // employee_count: element.employee_count,
        // address: element.address,
        // studentsCount: element.studentsCount,
        // teachersCount: element.teachersCount,
        actions: [
          <i
            className="fa fa-pencil font-primary"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: `/category/edit`,
                state: {
                  category: element,
                },
              })
            }
          ></i>,
        ],
      };
      BranchData.push(mappedData);
    });

    setData(BranchData);
  }, [props]);

  const tableConfig = {
    tableColumns: schoolTableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };

  return (
    <Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <TableComponent
          loading={props.loading}
          tableConfig={tableConfig}
          numPages={props.data?.numPages}
          setPaginationConfig={props.setPaginationConfig}
        />
      )}
    </Fragment>
  );
};

export default CategoryData;
