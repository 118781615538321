import React, { Fragment, useContext } from "react";
import { TABLE_COMPONENT_CONTEXT } from '../../../../../contexts/schoolRequests';
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { Link } from 'react-router-dom';

export function TeacherFilters() {
    const { paginationConfig, setPaginationConfig } = useContext(TABLE_COMPONENT_CONTEXT)
    let handleSortBy = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["pageNumber"] = 1
        temp["sortDirection"] = input.value
        setPaginationConfig(temp)
    }
    let handleRecords = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["pageSize"] = input.value
        setPaginationConfig(temp)
    }
    let handleStatus = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["pageNumber"] = 1
        temp["status"] = input.value
        setPaginationConfig(temp)
    }
    return <Fragment>
        <Row>
            <Col md="3">
                <FormGroup>
                    <Label for="exampleSelect">
                        Sort By
                    </Label>
                    <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={handleSortBy}
                    >
                        <option value="ASC" selected={paginationConfig.sortDirection === "ASC"}>
                            Ascending
                        </option>
                        <option value="DESC" selected={paginationConfig.sortDirection === "DESC"}>
                            Descending
                        </option>

                    </Input>
                </FormGroup>

            </Col>
            <Col md="3">
                <FormGroup>
                    <Label for="exampleSelect">
                        Show Records
                    </Label>
                    <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={handleRecords}
                    >
                        {[5, 10, 15, 25, 50].map((value, key) => <option value={value} selected={paginationConfig.pageNumber === value}>
                            {value}
                        </option>)}


                    </Input>
                </FormGroup>
            </Col>
            
            <Col md="3">
                <Link className="mt-4 btn btn-primary" to="/teacher/create">Redirect</Link>
                
            </Col>
            
        </Row>
    </Fragment>
}