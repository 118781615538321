import React from "react";
import { Card, Row, Col, CardBody } from "reactstrap";
import ApexCharts from "react-apexcharts";
import ConfigDB from "../../../data/customizer/config";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../redux/apiCall";
import { appointment_data, sales_data } from "../../../constant/url";
import { Loader } from "../../utils/Loader";

const primary =
  localStorage.getItem("default_color") || ConfigDB.data.color.primary_color;
const secondary =
  localStorage.getItem("secondary_color") ||
  ConfigDB.data.color.secondary_color;
export function SalesChart() {
  const [loading, setLoading] = React.useState(false);
  const [dataSpan, setDataSpan] = React.useState("m");
  const [Currentlysale, setCurrentlySale] = React.useState({
    series: [
      {
        name: "Total Sales",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        stacked: true,
      },
      colors: [secondary, primary],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      xaxis: {
        type: "string",
      },
    },
  });
  const dispatch = useDispatch();
  const fetchAppointmentData = () => {
    setLoading(true);
    dispatch(
      apiCall(sales_data, "POST", { data_span: dataSpan }, (resp) => {
        let _currentlySale = { ...Currentlysale };
        _currentlySale.series[0].data = resp.data.dataSet;
        _currentlySale.options.xaxis.categories = resp.data.labels;
        setCurrentlySale(_currentlySale);
        setLoading(false);
      })
    );
  };
  React.useEffect(() => {
    fetchAppointmentData();
  }, [dataSpan]);
  return (
    <div>
      <h5>{"Sales Summary"}</h5>
      <Card className="earning-card">
        <CardBody className="p-0">
          <Row className="m-0">
            <Col xl="12" lg="12" md="12" sm="12" className="p-0">
              <div className="chart-right">
                <Row className="m-0 p-tb w-100">
                  <Col xl="4" md="4" sm="4">
                    <div className="inner-top-right">
                      <h5>Sales Data</h5>
                    </div>
                  </Col>
                  <Col xl="8" md="8" sm="8" className="col-12 p-0">
                    <div className="inner-top-left">
                      <ul className="d-flex list-unstyled justify-content-end">
                        <li
                          style={{ cursor: "pointer" }}
                          className={dataSpan === "d" ? "active" : ""}
                          onClick={() => setDataSpan("d")}
                        >
                          {"Daily"}
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          className={dataSpan === "w" ? "active" : ""}
                          onClick={() => setDataSpan("w")}
                        >
                          {"Weekly"}
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          className={dataSpan === "m" ? "active" : ""}
                          onClick={() => setDataSpan("m")}
                        >
                          {"Monthly"}
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          className={dataSpan === "y" ? "active" : ""}
                          onClick={() => setDataSpan("y")}
                        >
                          {"Yearly"}
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    {loading ? (
                      <Loader />
                    ) : (
                      <CardBody className="p-0">
                        {Currentlysale?.series[0].data.length ? (
                          <div className="current-sale-container">
                            <ApexCharts
                              id="chart-currently"
                              options={Currentlysale.options}
                              series={Currentlysale.series}
                              type="area"
                              height={240}
                            />
                          </div>
                        ) : (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ height: "240px" }}
                          >
                            <h5>No Data To Display</h5>
                          </div>
                        )}
                      </CardBody>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
