export const fields = [
    {
        name: "Invoice",
        type: "select",
        label: "Invoice",
        placeholder: "Item Code",
        required: true,
    }, 
    {
        name: "PaymentMethod",
        type: "select",
        label: "Payment Method",
        placeholder: "Item Name",
        required: true,
    }, 
    {
        name: "DepositNumber",
        type: "text",
        label: "Deposit Number",
        // placeholder: "Item Name",
        required: true,
    }, 
    {
        name: "PaymentNumber",
        type: "text",
        label: "Payment Number",
        // placeholder: "Item Name",
        required: true,
    }, 
    {
        name: "BankName",
        type: "text",
        label: "Bank Name",
        // placeholder: "Item Name",
        required: true,
    }, 
    {
        name: "Attachment",
        type: "file",
        label: "Attachment",
        // placeholder: "Item Name",
        required: true,
    }, 
]