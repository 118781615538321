import React, { Fragment } from 'react';
import { Container } from 'reactstrap'

const Spinners = (props) => {
  return (
    <Fragment>
      <Container fluid={true} className="mt-5">
        <div className="loader-box">
          <div className="loader-10"></div>
        </div>
      </Container>
    </Fragment>
  );
}

export default Spinners;