import {
  Row,
  Input,
  Form,
  Button,
  Col,
  FormGroup,
  Label,
  Container,
} from "reactstrap";
import React from "react";
import { Submit } from "../../../../constant";
import { RequiredField } from "../../../utils/fieldRequired";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../redux/apiCall";
import { create_saloon } from "../../../../constant/url";
import { toast } from "react-toastify";
import { Loader } from "../../../utils/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export function CreateSaloon({ qna, setSaloonData }) {
  const dispatch = useDispatch();
  const [answers, setAnswers] = React.useState({});
  const [initialValues, setInitialValues] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const history = useHistory();
  const onSubmit = (e) => {
    e.preventDefault();
    let _questions = Object.keys(answers);
    let _answers = Object.values(answers);
    let values = {
      question_id: _questions.join(","),
      answer: _answers.join(","),
      name: initialValues.name,
      logo: initialValues.logo,
    };
    let form = new FormData();
    for (let key in values) {
      form.append(key, values[key]);
    }
    dispatch(
      apiCall(create_saloon, "POST", form, (resp) => {
        if (resp.status == 200) {
          toast.success("Salon created successfully");
          localStorage.setItem("saloon", JSON.stringify(resp.data));
          history.push("/dashboard");
        } else {
          toast.error(resp.message);
        }
      })
    );
  };
  const handleChange = ({ currentTarget: input }) => {
    let _initialValues = { ...initialValues };
    _initialValues[input.name] = input.value;
    setInitialValues(_initialValues);
  };
  const qnaChange = ({ currentTarget: input }) => {
    let _answers = { ...answers };
    _answers[input.name] = input.value;
    setAnswers(_answers);
  };
  return (
    <Container fluid>
      <Form className="p-3 mt-2">
        {qna.length ? (
          <>
            <Row>
              {qna.map(({ id, question, type, options }, index) => (
                <Col md="6" sm="6" xs="12">
                  <FormGroup key={index}>
                    <Label for={question}>{question}</Label>
                    {type === "select" ? (
                      <Input
                        name={id}
                        onChange={qnaChange}
                        type={type}
                        size={"sm"}
                      >
                        <option> Select....</option>
                        {options.map(({ name, value }, index) => (
                          <option key={index} value={value}>
                            {name}
                          </option>
                        ))}
                      </Input>
                    ) : (
                      <Input
                        name={id}
                        onChange={qnaChange}
                        type={type}
                        size={"sm"}
                      />
                    )}
                  </FormGroup>
                </Col>
              ))}
            </Row>
            <Row>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label>
                    Name <RequiredField />
                  </Label>
                  <Input
                    name={"name"}
                    onChange={handleChange}
                    type={"text"}
                    size={"sm"}
                    placeholder={"Salon Name"}
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label>Logo</Label>
                  <Input
                    name={"logo"}
                    onChange={handleChange}
                    size={"sm"}
                    type={"file"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-right">
              <Button
                color="primary"
                className="mr-1"
                type="button"
                onClick={onSubmit}
                disabled={isSubmitting}
              >
                {Submit}
              </Button>
            </div>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </Form>
    </Container>
  );
}
