import React, { Fragment } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { fields } from "./fields";
import { RequiredField } from "../../../utils/fieldRequired";
import ErrorMessage from "../../../utils/errorMessage";
import { Submit } from "../../../../constant";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { useDispatch } from "react-redux";
import { createBranch } from "../../../../redux/branches/branchActions";
import { toast } from "react-toastify";
import { apiCall } from "../../../../redux/apiCall";
import {
  add_staff,
  create_branch,
  get_branches,
  get_roles,
} from "../../../../constant/url";
import { getSaloon } from "../../../utils/getSaloon";
import Select from "react-select";

export function CreateStaff() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [branches, setBranches] = React.useState([]);

  const [images, setImages] = React.useState({});
  const saloon = getSaloon();

  const dispatch = useDispatch();
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    role_ids: [],
    image: "",
    documents: "",
  };
  React.useEffect(() => {
    getRoles();
    fetchBranches();
  }, []);
  const fetchBranches = () => {
    dispatch(
      apiCall(get_branches, "POST", { saloon_id: saloon.id }, (resp) => {
        setBranches(resp.data);
      })
    );
  };
  const getRoles = async () => {
    if (saloon) {
      dispatch(
        apiCall(get_roles, "POST", { saloon_id: saloon.id }, (resp) => {
          if (resp.status) {
            let options = [];
            let { data: result } = resp;
            for (let role of result) {
              options.push({
                value: role.id,
                label: role.name,
              });
            }
            setRoles(options);
          }
        })
      );
    }
  };
  const handleImageUploads = (e) => {
    let _images = { ...images };
    _images[e.target.name] = e.target.files[0];
    setImages(_images);
  };
  const onSubmit = (values, formActions) => {
    formActions.setSubmitting(false);
    let formData = new FormData();
    let { first_name, last_name, email, phone, address, role_ids, branch_id } =
      values;
    formData.append("first_name", first_name);
    formData.append("branch_id", branch_id);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("image", images.image);
    formData.append("documents", images.documents);
    for (let role of role_ids) {
      formData.append("role_ids[]", role.value);
    }

    dispatch(
      apiCall(add_staff, "POST", formData, (resp) => {
        if (resp.status === 200) {
          formActions.setSubmitting(false);
          setIsSubmitting(false);
          formActions.resetForm();
          toast.success("Staff added successfully!");
        } else {
          formActions.setSubmitting(false);
          setIsSubmitting(false);
          toast.error(resp.message);
        }
      })
    );
  };
  return (
    <Fragment>
      <Breadcrumb parent="dashboard" title={"Add Staff"} />

      <Container fluid={true}>
        <Card>
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
              }) => (
                <Form className="p-3" onSubmit={handleSubmit}>
                  <Row>
                    {fields.map(
                      ({ name, label, type, placeholder, required }, key) => (
                        <Col md="6" sm="6" xs="12">
                          <FormGroup key={key}>
                            <Label for={name}>
                              {label} {required ? <RequiredField /> : ""}
                            </Label>
                            <Input
                              name={name}
                              size={"sm"}
                              onChange={(e) => {
                                if (type !== "file") {
                                  handleChange(e);
                                } else {
                                  handleChange(e);
                                  handleImageUploads(e);
                                }
                              }}
                              type={type}
                              placeholder={placeholder}
                              value={values[name]}
                            />
                            {errors[name] && touched[name] && (
                              <ErrorMessage message={errors[name]} />
                            )}
                          </FormGroup>
                        </Col>
                      )
                    )}
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"name"}>
                          Branch <RequiredField />
                        </Label>
                        <Input
                          name={"branch_id"}
                          onChange={handleChange}
                          type={"select"}
                          size={"sm"}
                          value={values["branch_id"]}
                        >
                          <option> Select....</option>
                          {branches.map(({ name, id }, index) => (
                            <option key={index} value={id}>
                              {name}
                            </option>
                          ))}
                        </Input>
                        {errors["branch_id"] && touched["branch_id"] && (
                          <ErrorMessage message={errors["branch_id"]} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"role"}>
                          Roles <RequiredField />
                        </Label>
                        <Select
                          isMulti
                          name="role_ids"
                          onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "role_ids",
                                value: selectedOption,
                                type: "select",
                              },
                            };
                            handleChange(event);
                          }}
                          options={roles}
                          value={values?.role_ids}
                          classNamePrefix="service_type"
                        />
                        {errors["role_ids"] && touched["role_ids"] && (
                          <ErrorMessage message={errors["role_ids"]} />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <Button
                      color="primary"
                      className="mr-1"
                      type="submit"
                      disabled={isSubmitting || !saloon}
                    >
                      {Submit}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}
