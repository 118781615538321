import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'

const Class = () => {
    return ( 
        <Fragment>
        <Breadcrumb parent="Project" title="Class" />
        <h1>Class</h1>
        </Fragment>
     );
}
 
export default Class;