import ClassApi from "./classApi";
const classApi = new ClassApi();


export const getPaginatedClasses = (state, cb) => async () => {
    try {
        const { data } = await classApi.getPaginatedClasses(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};
export const getGrades = (cb) => async () => {
    try {
        const { data } = await classApi.getGrades();
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};

export const getGradesLevelSections = (state, cb) => async () => {
    try {
        const { data } = await classApi.getGradesLevelSections(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};

export const createGradeSection = (state, cb) => async () => {
    try {
        const { data } = await classApi.createGradeSection(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};
export const createClass = (state, cb) => async () => {
    try {
        const { data } = await classApi.createClass(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};