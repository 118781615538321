import GradeApi from "./gradeApi";
const gradeApi = new GradeApi();


export const getGrades = (state, cb) => async () => {
    try {
        const { data } = await gradeApi.getGrade(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};

export const createGradeRequest = (values, cb) => async () => {
    try {
        const { data } = await gradeApi.createGrade(values);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};

export const updateGradeRequest = (values, cb) => async () => {
    try {
        const { data } = await gradeApi.updateGrade(values);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};
export const branchById = (values, cb) => async () => {
    try {
        const { data } = await gradeApi.branchById(values);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};

export const updateBranch = (values, cb) => async () => {
    try {
        const { data } = await gradeApi.updateBranch(values);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};