import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const SignInRoute = ({ component: Component, roles, ...rest }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const RedirectTo = (props) => {
        if (user) {
            switch (user.role.id) {

                case 1:
                    return <Redirect to={`${process.env.PUBLIC_URL}/superadmin/schools`} />
                case 2:
                    return <Redirect to={`${process.env.PUBLIC_URL}/branch`} />
                case 4:
                    return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />

                default:
                    return <Component {...props} />
            }
        }
        else {
            return <Component {...props} />
        }
    }

    return (
        <Route
            {...rest}
            render={(props) => RedirectTo(props)
            }
        />
    );
};

export default SignInRoute;