import React, { Fragment } from "react";
import {
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import SortBy from "../../../../common/sortBy";
import TableComponent from "../../../../common/TableComponent";
import Spinner from "../../../../common/spinner";
import { useHistory } from "react-router-dom";
import { apiCall } from "../../../../../redux/apiCall";
import { deactivate_staff } from "../../../../../constant/url";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const StaffData = (props) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleAction = (id) => {
    history.push(`/staff/schedule/${id}`);
    // props.onClickAction(id)
  };
  const schoolTableColumns = [
    {
      name: "First Name",
      selector: "first_name",
      sortable: true,
      center: true,
    },

    {
      name: "Last Name",
      selector: "last_name",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
      center: true,
    },
    {
      name: "Role",
      selector: "roles",
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      selector: "actions",
      center: true,
    },
  ];
  const deactivateStaff = (id) => {
    dispatch(
      apiCall(deactivate_staff, "POST", { staff_id: id }, (resp) => {
        if (resp.status) {
          toast.success("Staff deactivated successfully!");
          props.refreshTable();
        } else {
          toast.error(resp.message);
        }
      })
    );
  };
  useEffect(() => {
    var BranchData = [];
    const data = props.data.data;
    data.forEach((element) => {
      const mappedData = {
        first_name: element.first_name,
        last_name: element.last_name,
        email: element.email,
        phone: element.phone,
        roles: element.roles.join(", "),
        actions: (
          <span>
            <i
              className="fa fa-eye font-primary"
              style={{ cursor: "pointer" }}
              onClick={() => handleAction(element.staff_id)}
            ></i>
            <i
              className="fa fa-stop-circle-o ml-1 font-danger"
              style={{ cursor: "pointer" }}
              onClick={() => deactivateStaff(element.staff_id)}
              data-toggle="tooltip"
              data-placement="top"
              title="Deactivate User"
            ></i>
          </span>
        ),
      };
      BranchData.push(mappedData);
    });

    setData(BranchData);
  }, [props]);

  const tableConfig = {
    tableColumns: schoolTableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };

  return (
    <Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <TableComponent
          loading={props.loading}
          tableConfig={tableConfig}
          numPages={props.data?.numPages}
          setPaginationConfig={props.setPaginationConfig}
        />
      )}
    </Fragment>
  );
};

export default StaffData;
