export const fields = [
    {
        name: "code",
        type: "text",
        label: "Item Code",
        placeholder: "Item Code",
        required: true,
    }, 
    {
        name: "name",
        type: "text",
        label: "Item Name",
        placeholder: "Item Name",
        required: true,
    },  
    {
        name: "amount",
        type: "text",
        label: "Amount",
        placeholder: "Amount",
        required: true,
    }, 
]