import React, { Fragment } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { RequiredField } from "../../../utils/fieldRequired";
import ErrorMessage from "../../../utils/errorMessage";
import { Submit } from "../../../../constant";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { useDispatch } from "react-redux";
import { createBranch } from "../../../../redux/branches/branchActions";
import { toast } from "react-toastify";
import { apiCall } from "../../../../redux/apiCall";
import DatePicker from "react-datepicker";

import {
  create_branch,
  create_categories,
  create_service,
  get_branches,
  get_categories,
  get_service_types,
  update_service,
} from "../../../../constant/url";
import { getSaloon } from "../../../utils/getSaloon";
import { useParams, useLocation, useHistory } from "react-router-dom";
export function CreateServices() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    name: "",
    branch_id: "",
    category_id: "",
    description: "",
    price: "",
    available_for: "",
    duration_in_mins: "",
    online_booking: false,
    staff_commission: false,
  });
  const { state } = useLocation();
  const { status } = useParams();
  const history = useHistory();
  const available_for = [
    { id: "men", name: "Men" },
    { id: "women", name: "Women" },
    { id: "both", name: "Both" },
  ];
  const saloon = getSaloon();

  const dispatch = useDispatch();

  React.useEffect(() => {
    fetchBranches();
    fetchServices();
    fetchCategories();
  }, []);

  React.useEffect(() => {
    if (status === "edit") {
      const { service } = state;
      setInitialValues({ ...initialValues, ...service });
    }
  }, [status]);
  const fetchBranches = () => {
    dispatch(
      apiCall(get_branches, "POST", { saloon_id: saloon.id }, (resp) => {
        setBranches(resp.data);
      })
    );
  };
  const fetchServices = () => {
    dispatch(
      apiCall(get_service_types, "GET", {}, (resp) => {
        setServices(resp.data);
      })
    );
  };
  const fetchCategories = () => {
    dispatch(
      apiCall(get_categories, "GET", {}, (resp) => {
        setCategories(resp.data);
      })
    );
  };
  const onSubmit = (values, formActions) => {
    let _values =
      status === "create"
        ? { ...values }
        : { ...values, service_id: values.id };
    dispatch(
      apiCall(
        status === "create" ? create_service : update_service,
        "POST",
        _values,
        (resp) => {
          if (resp.status === 200) {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            formActions.resetForm();
            toast.success(`Service ${status}d successfully!`);
            setTimeout(() => {
              history.push("/services", { replace: true });
            }, 2000);
          } else {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            toast.error(resp.message);
          }
        }
      )
    );
  };
  const timeInMinutes = [15, 30, 45, 60, 90, 120];
  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title={`${status === "create" ? "Create" : "Edit"} Service`}
      />

      <Container fluid={true}>
        <Card>
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form className="p-3" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"name"}>
                          Name <RequiredField />
                        </Label>
                        <Input
                          name={"name"}
                          onChange={handleChange}
                          type={"text"}
                          size={"sm"}
                          placeholder={"Service Name"}
                          value={values["name"]}
                        />
                        {errors["name"] && touched["name"] && (
                          <ErrorMessage message={errors["name"]} />
                        )}
                      </FormGroup>
                    </Col>

                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"name"}>
                          Branch <RequiredField />
                        </Label>
                        <Input
                          name={"branch_id"}
                          onChange={handleChange}
                          type={"select"}
                          size={"sm"}
                          value={values["branch_id"]}
                        >
                          <option> Select....</option>
                          {branches.map(({ name, id, value }, index) => (
                            <option key={index} value={id}>
                              {name}
                            </option>
                          ))}
                        </Input>
                        {errors["branch_id"] && touched["branch_id"] && (
                          <ErrorMessage message={errors["branch_id"]} />
                        )}
                      </FormGroup>
                    </Col>
                    {/* <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"name"}>
                          Service Type <RequiredField />
                        </Label>
                        <Input
                          name={"service_type_id"}
                          onChange={handleChange}
                          type={"select"}
                          size={"sm"}
                        >
                          <option> Select....</option>
                          {services.map(({ name, id }, index) => (
                            <option key={index} value={id}>
                              {name}
                            </option>
                          ))}
                        </Input>
                        {errors["service_type_id"] &&
                          touched["service_type_id"] && (
                            <ErrorMessage message={errors["service_type_id"]} />
                          )}
                      </FormGroup>
                    </Col> */}
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"name"}>
                          Category <RequiredField />
                        </Label>
                        <Input
                          name={"category_id"}
                          onChange={handleChange}
                          type={"select"}
                          size={"sm"}
                          value={values["category_id"]}
                        >
                          <option> Select....</option>
                          {categories.map(({ name, id }, index) => (
                            <option key={index} value={id}>
                              {name}
                            </option>
                          ))}
                        </Input>
                        {errors["category_id"] && touched["category_id"] && (
                          <ErrorMessage message={errors["category_id"]} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"price"}>
                          Price <RequiredField />
                        </Label>
                        <Input
                          name={"price"}
                          onChange={handleChange}
                          type={"text"}
                          size={"sm"}
                          value={values["price"]}
                        />
                        {errors["price"] && touched["price"] && (
                          <ErrorMessage message={errors["price"]} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"available_for"}>
                          Available for <RequiredField />
                        </Label>
                        {/* <Input
                                                name={"available_for"}
                                                onChange={handleChange}
                                                type={"select"}
                                                size={"sm"}
                                            >
                                                <option> Select....</option>
                                                {available_for.map(({ name, id }, index) => <option key={index} value={id}>{name}</option>)}
                                            </Input> */}
                        <div>
                          {available_for.map((available, index) => (
                            <div
                              className="form-check form-check-inline "
                              key={index}
                            >
                              <Input
                                type="radio"
                                name={"available_for"}
                                value={available.id}
                                checked={
                                  values["available_for"] === available.id
                                }
                                onChange={handleChange}
                              />{" "}
                              <Label className="mb-0">{available.name}</Label>
                            </div>
                          ))}
                        </div>
                        {errors["available_for"] &&
                          touched["available_for"] && (
                            <ErrorMessage message={errors["available_for"]} />
                          )}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"name"}>
                          Duration(in mins) <RequiredField />
                        </Label>

                        <Input
                          name={"duration_in_mins"}
                          onChange={handleChange}
                          type={"select"}
                          size={"sm"}
                          value={values["duration_in_mins"]}
                        >
                          <option value="">Select...</option>
                          {timeInMinutes.map((item) => (
                            <option value={item}>{`${item} mins.`}</option>
                          ))}
                        </Input>
                        {errors["duration_in_mins"] &&
                          touched["duration_in_mins"] && (
                            <ErrorMessage
                              message={errors["duration_in_mins"]}
                            />
                          )}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"name"}>Description</Label>
                        <Input
                          name={"description"}
                          onChange={handleChange}
                          type={"textarea"}
                          size={"sm"}
                          placeholder={"Add description"}
                          value={values["description"]}
                        />
                        {errors["description"] && touched["description"] && (
                          <ErrorMessage message={errors["description"]} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          name="online_booking"
                          checked={values["online_booking"] == 1}
                          onChange={(e) =>
                            setFieldValue(
                              "online_booking",
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                        <Label check>Online Booking</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          name="staff_commission"
                          checked={values["staff_commission"] == 1}
                          onChange={(e) =>
                            setFieldValue(
                              "staff_commission",
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                        <Label check>Staff Commission</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <Button
                      color="primary"
                      className="mr-1"
                      type="submit"
                      disabled={isSubmitting || !saloon}
                    >
                      {Submit}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}
