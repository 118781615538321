import React from "react";
export const SCHOOL_REQUEST_CONTEXT = React.createContext()






// Usama's Context
export const TABLE_COMPONENT_CONTEXT = React.createContext()
// export const TEACHER_REQUEST_CONTEXT = React.createContext()
