import React, { useState } from "react";
import { useEffect } from "react";
import { FormGroup, Input, Label, InputGroup } from "reactstrap";

export function FormInput(props) {
    // Set default type to "text"
    const { apendIcon = '', label, type = 'text', required = 0, placeholder = '', options = [], name, selectedId = props.value, onchange = '' } = props;
    const [componentValue, setValue] = useState(props.value);

    // useEffect((props) => {
    //     const value = props.value
    //     setValue(value)
    // },[])


    const onChange = (event) => {
        let slectedValue = event.target.value;
        let value = event.target.value
        setValue(slectedValue);

        let eventDetails={
            name:event.target.name,
            value
        }
        if (onchange) { props.onchange(eventDetails); }
    };





    switch (type) {
        case 'select':
            return selectField()
        case 'hidden':
            return hiddenField()
        default:
            return textField()
    }


    function selectField() {
        
        return (
            <FormGroup>
                <Label htmlFor={name}>{label}</Label>
                <Input type={type} id={name} name={name} className="custom-select" defaultValue={selectedId} onChange={onChange}>
                    <option key={'disable'} disabled >{"Select a value"}</option>
                    <option key={'any'} value="">{"Any"}</option>
                    {options.map(item => (
                        <option key={item.id} value={item.id}>{item.title}</option>
                    ))}
                    
                </Input>
            </FormGroup>
        )
    }

    function textField() {
        return (
            <FormGroup>
                <Label>{label}</Label>
                <InputGroup>
                    {apendIcon}
                    <Input
                        type={type}
                        id={name}
                        name={name}
                        value={componentValue}
                        onChange={onChange}
                        required={required}
                        placeholder={placeholder}
                    />
                </InputGroup>

            </FormGroup>
        )
    }

    function hiddenField() {
        return (

            <Input
                type={type}
                id={name}
                name={name}
                value={componentValue}
            />

        )
    }

}

