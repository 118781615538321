//Saloon
export const get_saloon_qna = "/saloon/get-onboarding-qas";
export const create_saloon = "/saloon/submit-onboarding-qas";
export const get_saloon_by_id = "/saloon/details";
//Branch
export const create_branch = "/saloon/create-branch";
export const get_branches = "/saloon/get-branches";
//Categories
export const create_categories = "/saloon/add-categories";
export const update_categories = "/saloon/update-categories";
export const get_categories = "/saloon/categories";
//Services
export const get_service_types = "/saloon/services-types";
export const create_service = "/saloon/add-service";
export const update_service = "/saloon/update-service";
export const get_services = "/saloon/services";

//Appointments
export const get_appointments_types = "/appointment/appointment-types";
export const create_appointment = "/appointment/appointment-confirm";
export const update_appointment = "/appointment/appointment-update";
export const get_appointments_paginated = "/appointment/appointment-list";
export const reschedule_appointment = "/appointment/appointment-rescheduling";
export const appointment_details = "/appointment/appointment-details";
export const update_appointment_status =
  "/appointment/appointment-change-status";
export const appointment_status_list = "/appointment/appointment-status";

//Roles
export const create_role = "/saloon/add-role";
export const get_roles = "/saloon/get-role";
export const edit_role = "/saloon/edit-role";
export const remove_role = "/saloon/delete-role";

//Staff
export const add_staff = "/saloon/add-staff";
export const get_staff = "/saloon/get-staff";
export const deactivate_staff = "/saloon/deactivate-staff";

//Staff Schedule
export const get_staff_schedule = "/saloon/get-schedule";
export const update_staff_schedule = "/saloon/set-schedule";

// Public Pages Api
export const public_saloon_details = "/saloon/details";
export const public_saloon_service = "/service/details";

//Analytics
export const total_appointments = "/analytics/total-appointments";
export const appointment_data = "/analytics/appointment-summary";
export const sales_data = "/analytics/sale-summary";
export const top_categories = "/analytics/top-categories";

//Checkout
export const checkout_url = "/appointment/checkout";

//Update Password
export const update_password = "/forgot-password/submit";

//Verify Email
export const verify_email = "/account/verify";
