import React, { Fragment } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import DataTable from 'react-data-table-component';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const TableComponent = (props) => {
    const [data, setData] = useState([])
    const [tableColumns, setTableColumn] = useState([])
    useEffect(() => {
        setData(props.tableConfig.data)
        setTableColumn(props.tableConfig.tableColumns)
    }, [props])
    return (
        <Fragment>
            <div className="table-responsive">
                <DataTable
                    data={data}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    persistTableHead
                    pagination
                />
            </div>            
        </Fragment>
    );
};

export default TableComponent;