import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import SchoolData from "./school-data/SchoolData";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { SuperAdminAPIUrl, RequestError, APIUrl } from "../../../constant";
import axios_request from "../helpFunctions";
import { useDispatch } from "react-redux";
import {
  schoolRequestAction,
  schoolRequestDetails,
  schoolRequests,
} from "../../../redux/requests/requestsActions";
import { TABLE_COMPONENT_CONTEXT } from "../../../contexts/schoolRequests";
import { SchoolFilters } from "./school-data/SchoolFilters";

const SchoolRequests = () => {
  const [addModal, setaddModal] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [clients, setClients] = useState({
    total_records: 0,
    data: [],
  });
  const dispatch = useDispatch();
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    pageSize: 5,
    sortBy: "id",
    sortDirection: "DESC",
    status: "pending",
    hideOnNoData: 0,
  });

  const getSchoolRequest = async () => {
    dispatch(
      schoolRequests(paginationConfig, (resp) => {
        if (resp.status) {
          setClients({
            total_records: resp.data.pagination.totalCount,
            numPages: resp.data.pagination.numPages,
            data: resp.data.records,
          });
        }
      })
    );
  };

  useEffect(() => {
    getSchoolRequest();
  }, [paginationConfig]);

  const type = "Search Clients";
  const { register, handleSubmit, errors } = useForm();

  const handleFavClient = (id) => {};

  const handlePagination = (paginationData) => {
    let temp = { ...paginationConfig };
    temp["pageNumber"] = paginationData.current;
    setPaginationConfig(temp);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    // setSearchReq(searchReq)
  };
  const handleActionId = (id) => {
    dispatch(
      schoolRequestDetails({ requestId: id }, (resp) => {
        setRequestId(resp.data.details.id);
        addToggle();
      })
    );
  };

  const addToggle = () => {
    setaddModal(!addModal);
  };

  const onSubmit = (data) => {
    data["requestId"] = requestId;
    dispatch(
      schoolRequestAction(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          getSchoolRequest();
          addToggle();
        }
      })
    );
  };

  return (
    <Fragment>
      <Breadcrumb parent="Project" title="School Requests" />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="pt-0">
                <Row className="p-3">
                  <Col sm="12">
                    <TABLE_COMPONENT_CONTEXT.Provider
                      value={{
                        paginationConfig,
                        handlePagination,
                        setPaginationConfig,
                      }}
                    >
                      <SchoolFilters />
                      <div>
                        <h6>Total Records : {clients.total_records}</h6>
                      </div>
                      <SchoolData
                        loading={false}
                        type={type}
                        paginationConfig={paginationConfig}
                        data={clients}
                        onClickSort={(value) => handleSorting(value)}
                        onClickPagination={(value) => handlePagination(value)}
                        onClickAction={(e) => handleActionId(e)}
                        setPaginationConfig={setPaginationConfig}
                      />
                    </TABLE_COMPONENT_CONTEXT.Provider>
                  </Col>
                </Row>

                <Modal isOpen={addModal} toggle={addToggle} size="lg">
                  <ModalHeader toggle={addToggle}>
                    {"Change School Request Status"}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      className="form-bookmark needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-row">
                        <FormGroup className="col-md-6">
                          <Label>{"Action"}</Label>
                          <Input
                            className="js-example-disabled-results"
                            name="status"
                            type="select"
                            innerRef={register({ required: true })}
                          >
                            <option value="approved">{"Approved"}</option>
                            <option value="rejected">{"Rejected"}</option>
                          </Input>
                        </FormGroup>
                        <FormGroup className="col-md-12">
                          <Label>{"Remarks"}</Label>
                          <Input
                            className="form-control"
                            name="remarks"
                            type="textarea"
                            innerRef={register({ required: true })}
                          ></Input>
                          <span style={{ color: "red" }}>
                            {errors.remarks && "Remarks is required"}
                          </span>
                        </FormGroup>
                      </div>
                      <Button color="secondary" className="mr-1">
                        {"Save"}
                      </Button>
                      <Button color="primary" onClick={addToggle}>
                        {"Cancel"}
                      </Button>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SchoolRequests;
