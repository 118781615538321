import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import ClientDocuments from './clientDocuments';
import { useEffect } from 'react';
import ClientNotes from './ClientNotes';
import ClientDetails  from './clientDetails';
import ClientLeads from './ClientLeads';
import ClientAllocation from './clientAllocation';

const TabCard = () => {
    let params = useParams();
    const clientId = params.id
    const [activeTab, setActiveTab] = useState('1');

    return (
        <Fragment>
            <ClientDetails  clientId={clientId}/>
            <Container fluid={true}>
                <Row>
                    <Col lg="12 box-col-12" xl="12 xl-100">
                        <Card>
                            <CardBody>
                                <Nav tabs className="border-tab">
                                    <NavItem>
                                        <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                            Client Leads
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                            Documents
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                            Allocation
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                            <CardBody className="tabbed-card">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <ClientLeads clientId={clientId}/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <ClientDocuments clientId={clientId}/>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <ClientAllocation clientId={clientId}/>
                                    </TabPane>
                                   
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default TabCard;