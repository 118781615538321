import React from "react";
import { Redirect } from "react-router-dom";
import { disableDashboard } from "../components/utils/disabledDashboard";

function withRoleAccess(Component) {
  const pathname = window.location.pathname;

  return function (props) {
    const saloon = JSON.parse(localStorage.getItem("saloon"));
    if (
      pathname === "/dashboard" ||
      pathname.split("/").includes("dashboard")
    ) {
      if (disableDashboard()) {
        window.location.href = "/appointments";
      }
    }

    if (saloon) {
      const ETE = atob(localStorage.getItem("TET"));
      const parsedETE = JSON.parse(ETE);
      if (parsedETE?.is_trail && parseInt(parsedETE?.trail_end_in) === 0) {
        return <Redirect to={`${process.env.PUBLIC_URL}/trial`} />;
      }
      return <Component {...props} />;
    } else {
      return <Redirect to={`${process.env.PUBLIC_URL}/salon`} />;
    }
  };
}

export default withRoleAccess;
