import { INSTANCE } from "../../config/axiosInstance";

export default class BranchApi {
    createBranch = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/school-admin/branch/create",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    getBranches = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "GET",
                url: "/saloon/get-branches",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    branchById = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/school-admin/branch-details",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    updateBranch = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/school-admin/branch/update",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };

}
