import React, { Fragment, useState, useLayoutEffect } from "react";
import { Col } from "reactstrap";
import { Sliders } from "react-feather";
import { Link } from "react-router-dom";
import { DashboardNav, ClientNav } from "../../constant";
const Leftbar = (props) => {
  const [dashboardNav, setDashboardNav] = useState(true);
  const [clientNav, setClientNav] = useState(false);

  const [sidebartoggle, setSidebartoggle] = useState(true);

  const width = useWindowSize();
  const saloon = JSON.parse(localStorage.getItem("saloon"));
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  // useEffect(() => {

  //   var ignoreClick_On_Out_side_Element = document.getElementById('out_side_click');
  //   var ignoreClick_On_Main_Nav_Element = document.getElementById('sidebar-menu');
  //   document.addEventListener('click', function (event) {
  //     var isClickOutSideElement = ignoreClick_On_Out_side_Element.contains(event.target);
  //     var isClickMainNavElement = ignoreClick_On_Main_Nav_Element.contains(event.target);
  //     if (window.innerWidth <= 991 && !isClickOutSideElement && !isClickMainNavElement) {
  //       //Do something click is outside specified element
  //       document.querySelector(".page-header").className = "page-header close_icon";
  //       document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
  //     }
  //   });

  // }, [width])

  const responsive_openCloseSidebar = (toggle) => {
    if (width <= 991) {
      // setNavMenu(false)
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    } else {
      if (toggle) {
        setSidebartoggle(!toggle);
        document.querySelector(".page-header").className =
          "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper close_icon ";
        document
          .querySelector(".mega-menu-container")
          .classList.remove("d-block");
      } else {
        setSidebartoggle(!toggle);
        document.querySelector(".page-header").className = "page-header";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper ";
      }
    }
  };

  const handleChangeNav = (value) => {
    setClientNav(false);
    setDashboardNav(false);
    if (value === "dashboard") {
      setDashboardNav(true);
    } else if (value === "clients") {
      setClientNav(true);
    }
  };

  return (
    <Fragment>
      <div className="header-logo-wrapper" id="out_side_click">
        <div className="logo-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
            <img
              className="img-fluid for-light"
              src={require("../../assets/images/logo/logo.jpg")}
              alt=""
            />
            <img
              className="img-fluid for-dark"
              src={require("../../assets/images/logo/logo_dark.png")}
              alt=""
            />
          </Link>
        </div>
        <div
          className="toggle-sidebar"
          onClick={() => responsive_openCloseSidebar(sidebartoggle)}
          style={
            window.innerWidth <= 991
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <Sliders
            className="status_toggle middle sidebar-toggle"
            id="sidebar-toggle"
          />
        </div>
      </div>
      <Col className="left-header horizontal-wrapper pl-0">
        <ul className="horizontal-menu">
          <li className="mega-menu outside">
            <h6 className="text-dark mb-0">{`${saloon?.name}${
              saloon?.branch ? ` - ${saloon.branch.name}` : ""
            }`}</h6>
            {/* <Link
              className={`ml-2 nav-link ${dashboardNav ? "active" : ""}`}
              onClick={() => {
                props.onchange("dashboard");
                handleChangeNav("dashboard");
              }}
              to={"/dashboard"}
            >
              {DashboardNav}
            </Link>

            <Link
              className={`ml-2 nav-link ${clientNav ? "active" : ""}`}
              onClick={() => {
                props.onchange("clients");
                handleChangeNav("clients");
              }}
              to={"/clients/summary"}
            >
              {ClientNav}
            </Link> */}
          </li>
        </ul>
      </Col>
    </Fragment>
  );
};

export default Leftbar;
