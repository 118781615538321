import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Button, Media, CardBody } from 'reactstrap'
import Tablet from './tabsets';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Ratings from 'react-ratings-declarative'
import { Truck, Gift, CreditCard, Clock } from 'react-feather';
import {useParams} from "react-router-dom"
import { apiCall } from '../../../../redux/apiCall';
import { public_saloon_service } from '../../../../constant/url';
import { CreateAppointmentModal } from '../appointment-modal/CreateAppointmentModal';
import { Loader } from '../../../utils/Loader';
const PublicService = (props) => {
    const history = useHistory()
    const {id} = useParams()
    const [state, setState] = useState({ nav1: null, nav2: null });
    const [rating, setRating] = useState(0)
    const [open,setOpen] = useState(false)
    const [selectedService,setSelectedService] = useState(null)
    const [loading,setLoading] = useState(true)
    // eslint-disable-next-line
    const [quantity, Setquantity] = useState(1)
    const [serviceDetail,setServiceDetail] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(apiCall(`${public_saloon_service}/${id}`,'GET',{},resp=>{
            setServiceDetail(resp.data)
            setSelectedService({id:resp.data.service_details.id, name:resp.data.service_details.name})
            setLoading(false)
        }))
    }, []);
    const { nav1, nav2 } = state;
    const singleItem = useSelector(content => content.data.singleItem)
    const symbol = useSelector(content => content.data.symbol)

  

    return (
        <Fragment>
            <Container >
             {loading ? <Loader /> :   <Row>
                    <Col>
                        <Card>
                            <Row className="product-page-main">

                                <Col xl="5 xl-100">
                                    <Card>
                                        <CardBody>
                                            <div className="product-page-details">
                                                <h3>{serviceDetail?.service_details?.name}</h3>
                                            </div>
                                            <div className="product-price f-28">
                                                {"Pkr"}{singleItem.price} 100
                                                {/* <del>{symbol}{singleItem.discountPrice}</del> */}
                                            </div>
                                            {/* <ul className="product-color m-t-15">
                                                <li className="bg-primary"></li>
                                                <li className="bg-secondary"></li>
                                                <li className="bg-success"></li>
                                                <li className="bg-info"></li>
                                                <li className="bg-warning"></li>
                                            </ul> */}
                                            <hr />
                                            <p>
                                                {serviceDetail?.service_details?.description}</p>
                                            <hr />
                                            <div>
                                                <table className="product-page-width">
                                                    <tbody>
                                                        <tr>
                                                            <td> <b>{"Branch"} &nbsp;&nbsp;&nbsp;:</b></td>
                                                            <td>{serviceDetail?.branch_details?.address}</td>
                                                        </tr>
                                                        <tr>
                                                            <td> <b>{"Type"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                                            <td className="txt-success">{serviceDetail?.category_details?.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td> <b>{"Available for "} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                                            <td>{serviceDetail?.service_details?.available_for === "both" ? "Both Men & Women" : serviceDetail?.service_details?.available_for }</td>
                                                        </tr>
                                                        <tr>
                                                            <td> <b>{"Online Booking"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                                            <td>{serviceDetail?.service_details?.online_booking ? "Yes" : "No"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <hr />
                                            <Row>
                                                <Col md="6">
                                                <Button color='dark' onClick={()=>setOpen(true)}>Book Now</Button>
                                                </Col>
                                                <Col md="6">
                                                    <div className="product-icon">
                                                        <ul className="product-social">
                                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                                                        </ul>
                                                        <form className="d-inline-block f-right"></form>
                                                    </div>
                                                </Col>
                                            </Row>
                                          
                                            {/* <hr />
                                            <div className="m-t-15">
                                                <Button color="primary" className="m-r-10" onClick={() => addcart(singleItem, quantity)} >
                                                    <i className="fa fa-shopping-basket mr-1"></i>{"AddToCart"}
                                                </Button>
                                                <Button color="success" className="m-r-10" onClick={() => buyProduct(singleItem, quantity)}>
                                                    <i className="fa fa-shopping-cart mr-1"></i>{"BuyNow"}
                                                </Button>
                                                <Button color="secondary" onClick={() => addWishList(singleItem)}>
                                                    <i className="fa fa-heart mr-1"></i>{"Add To WishList"}
                                                </Button>
                                            </div> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        <Tablet /></Col>
                </Row>}
            </Container>
            <CreateAppointmentModal open={open} setOpen={() => {
                if (open) {
                    setSelectedService(null)
                }
                setOpen(!open)
            }} getAppointments={() => setSelectedService(null)} service={selectedService} />
        </Fragment>
    );
}
export default PublicService
