import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Card, CardBody, CardFooter, Button, Form } from "reactstrap";
import { Search } from "../../constant";
import { FormInput } from "../forms/formBuilder";

const SearchPanel = (props) => {
  const config = props.searchConfig;
  const [inputFields, setInputFields] = useState([]);
  const searchInput = config.SearchConfig;

  useEffect(() => {
    let tempArray = [];
    searchInput.params.map((item) => {
      tempArray[item.name] = config[item.name];
    });
    tempArray = { ...tempArray };
    setInputFields(tempArray);
  }, [props, searchInput]);

  const handleFormChange = (change) => {
    let data = { ...inputFields };
    data[change.name] = change.value;
    setInputFields(data);
  };

  const handleSearchClick = (event) => {
    event.preventDefault();
    props.onSearchClick(inputFields);
  };

  return (
    <Card>
      <Form className="form theme-form" onSubmit={handleSearchClick}>
        <CardBody>
          <Row>
            {searchInput.params.map((items, index) => (
              <Col sm="4" key={index}>
                <FormInput
                  label={items.title}
                  options={items.options}
                  onchange={(change) => handleFormChange(change)}
                  name={items.name}
                  type={items.type}
                  placeholder={items.placeholder}
                  value={inputFields[items.name]}
                />
              </Col>
            ))}
          </Row>
        </CardBody>
        <CardFooter>
          <Button color="primary" className="mr-1" type="submit">
            {Search}
          </Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default SearchPanel;
