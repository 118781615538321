import React, { Fragment } from 'react';
import { Card, CardBody, Button } from 'reactstrap'
import { useState } from 'react';
import { useEffect } from 'react';
import SortBy from '../../../../../common/sortBy';
import TableComponent from '../../../../../common/TableComponent';
import Spinner from '../../../../../common/spinner'
import { TABLE_COMPONENT_CONTEXT } from '../../../../../../contexts/schoolRequests';
import { useHistory } from 'react-router-dom';


const TableData = (props) => {
    const [data, setData] = useState([])
    const history = useHistory()
    const { handleTeacherId, tableColumns } = React.useContext(TABLE_COMPONENT_CONTEXT)
    
    useEffect(() => {
        var GirdData = [];
        const data = props.data.data
        data.forEach(element => {
            const mappedData = {
                code: element.code,
                name: element.name,
                amount: element.amount,
                amountType: element.amountType,
                status: element.status ? 'Active ' : "InActive " ,
                actions: [
                    <i className="fa fa-pencil font-primary" style={{ cursor: "pointer" }} onClick={() => history.push(`/item/update/${element.id}`)} ></i>,
                ]
            }
            GirdData.push(mappedData)
        });


        setData(GirdData)
    }, [props])


    const tableConfig = {
        tableColumns: tableColumns,
        data,
        onClickPagination: props.onClickPagination,
        paginationConfig: props.paginationConfig
    }

    return (
        <Fragment>
            {props.loading ? <Spinner /> : <TableComponent loading={props.loading} tableConfig={tableConfig} numPages={props.data?.numPages} setPaginationConfig={props.setPaginationConfig} totalRecords={props.data.total_records} />}
        </Fragment>

    );
};

export default TableData;