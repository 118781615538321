import React, { useState, useEffect } from "react";
import man from "../../../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap";
import { toast } from "react-toastify";
import { withRouter, useHistory } from "react-router-dom";
import {
  Password,
  EmailAddress,
  ForgotPassword,
  JWT,
  LoginWithJWT,
  SignIn,
  SuperAdminAPIUrl,
  RequestError,
  APIUrl,
} from "../../../constant";
import axios from "axios";
import { useForm } from "react-hook-form";
import validator from "../../../helper/validation";
import { useDispatch } from "react-redux";
import { resendEmail } from "../../../redux/auth/authActions";
import { Link } from "react-router-dom/cjs/react-router-dom";
const ResendEmail = (props) => {
  const { register, handleSubmit, errors } = useForm();

  const initalState = {
    email: "",
    password: "",
  };

  const [togglePassword, setTogglePassword] = useState(false);

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  const onSubmit = async (e) => {
    const formData = { email: localStorage.getItem("resendEmail") };
    dispatch(
      resendEmail(formData, async (data) => {
        if (data.status == 200) {
          toast.dark("Verfication email sent!");
        } else {
          toast.error(data.message);
        }
      })
    );
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img
                    className="img-fluid for-light"
                    style={{ height: "65px" }}
                    src={require("../../../assets/images/logo/login.png")}
                    alt=""
                  />
                  <img
                    className="img-fluid for-dark"
                    style={{ height: "65px" }}
                    src={require("../../../assets/images/logo/logo_dark.png")}
                    alt=""
                  />
                </a>
              </div>
              <div className="login-main login-tab">
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <h4>Email Verification</h4>
                  <Alert color="secondary">
                    A verification email has been sent to your registered email
                    address; please follow the instructions to complete the
                    process. If you didn't receive the email, click here to
                    <Link to="#" onClick={() => onSubmit()}>
                      {" "}
                      Resend
                    </Link>{" "}
                    it now.{" "}
                  </Alert>
                  <div className="form-group mb-0">
                    <p className="mt-4 mb-0">
                      <Link to="/">{"Back to Login"}</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(ResendEmail);
