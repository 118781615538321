import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'

const Student = () => {
    return ( 
        <Fragment>
        <Breadcrumb parent="Project" title="Student" />
        <h1>Student</h1>
        </Fragment>
     );
}
 
export default Student;