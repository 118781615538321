import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    title: Yup.string().required('Please provide a title.'),
    startDate: Yup.string().required('Please provide fiscal year start date.'),
    endDate: Yup.string().required('Please provide fiscal year end date.'),
});
export const TariffItem = Yup.object().shape({
    itemId: Yup.string().required('Please select an item'),
    amount: Yup.number().required('Please provide a valid amount'),
    scheduleType: Yup.string().required('Please select schedule type'),
    scheduleDate: Yup.string().required('Please provide a scheduled date')
})