import React, { Fragment } from "react"
import Breadcrumb from "../../../../layout/breadcrumb"
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button } from "reactstrap"
import { useDispatch } from "react-redux"
import { apiCall } from "../../../../redux/apiCall"
import { get_branches, get_staff_schedule } from "../../../../constant/url"
import { getSaloon } from "../../../utils/getSaloon"
import Select from 'react-select';
import { useParams } from "react-router-dom"
import { ScheduleField } from "./ScheduleField"

export function StaffSchedule() {
    const dispatch = useDispatch()
    const saloon = getSaloon()
    const { id } = useParams()
    const [schedule, setSchedule] = React.useState([])
    const [branches, setBranches] = React.useState([])

    React.useEffect(() => {
        fetchSchedule()
        fetchBranches()
    }, [])
    const fetchSchedule = () => {
        dispatch(apiCall(get_staff_schedule, 'POST', { staff_id: id }, resp => {
            setSchedule(resp.data)
        }))
    }
    const fetchBranches = () => {
        dispatch(apiCall(get_branches, 'POST', { saloon_id: saloon.id }, (resp) => {

            if (resp.status) {

                setBranches(

                    resp.data
                )

            }
        }))
    }

    return <Fragment>

        <Breadcrumb parent="dashboard" title={"Staff Schedule"
        } />

        <Container fluid={true}>
            <Card>
                <CardBody>
                    {schedule.map((item, index) => <div key={index}>
                        <ScheduleField schedule={item} branches={branches} />
                    </div>)}
                </CardBody>

            </Card>
        </Container>
    </Fragment>
}