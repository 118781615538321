import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'

import { Container, Row, Col, Card, CardBody, CardHeader, Table } from 'reactstrap'

import { ProjectBreakdown, StaffBreakdown, RatingBreakdown, ClientTypeBreakdown } from '../../constant'

import { ProjectBreakdownArray,StaffBreakdownArray,RatingBreakdownArray,ClientTypeBreakdownArray } from '../../data/clients-data';

const Summary = (props) => {
  const [projectBreakdown, setProjectBreakdown] = useState([]);
  const [staffBreakdown, setStaffBreakdown] = useState([]);
  const [ratingBreakdown,setRatingBreakdown]=useState([]);
  const [clientTypeBreakdown,setClientTypeBreakdown]=useState([]);

  useEffect(() => {
    setProjectBreakdown(ProjectBreakdownArray)
    setStaffBreakdown(StaffBreakdownArray)
    setRatingBreakdown(RatingBreakdownArray)
    setClientTypeBreakdown(ClientTypeBreakdownArray)

  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Clients Summary" />
      <Container fluid={true}>
        <Row className="size-column">
          <Col xl="4 xl-50" className="box-col-12">
            <Card>
              <CardHeader className="card-no-border">
                <h5>{ProjectBreakdown}</h5>
                <div className="card-header-right">
                  <ul className="list-unstyled card-option">
                    <li><i className="fa fa-spin fa-cog"></i></li>
                    <li><i className="view-html fa fa-code"></i></li>
                    <li><i className="icofont icofont-maximize full-card"></i></li>
                    <li><i className="icofont icofont-minus minimize-card"></i></li>
                    <li><i className="icofont icofont-refresh reload-card"></i></li>
                    <li><i className="icofont icofont-error close-card"></i></li>
                  </ul>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="our-product">
                  <div className="table-responsive">
                    <Table borderless>
                      <thead className="f-w-500">
                        <th>Projects</th>
                        <th style={{ textAlign: "right" }}>Clients</th>
                      </thead><br></br>
                      <tbody className="f-w-500">
                        {projectBreakdown.map((item) =>
                          <tr>
                            <td>
                              <div>
                                <span>{item.name}</span>
                              </div>
                            </td>
                            <td>
                              <span>{item.clients}</span>
                            </td>
                          </tr>
                        )}


                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4 xl-50" className="box-col-12">
            <Card>
              <CardHeader className="card-no-border">
                <h5>{StaffBreakdown}</h5>
                <div className="card-header-right">
                  <ul className="list-unstyled card-option">
                    <li><i className="fa fa-spin fa-cog"></i></li>
                    <li><i className="view-html fa fa-code"></i></li>
                    <li><i className="icofont icofont-maximize full-card"></i></li>
                    <li><i className="icofont icofont-minus minimize-card"></i></li>
                    <li><i className="icofont icofont-refresh reload-card"></i></li>
                    <li><i className="icofont icofont-error close-card"></i></li>
                  </ul>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="our-product">
                  <div className="table-responsive">
                    <Table borderless>
                      <thead className="f-w-500">
                        <th>Staff</th>
                        <th style={{ textAlign: "right" }}>Clients</th>
                      </thead><br></br>
                      <tbody className="f-w-500">
                      {staffBreakdown.map((item) =>
                          <tr>
                            <td>
                              <div>
                                <span>{item.name}</span>
                              </div>
                            </td>
                            <td>
                              <span>{item.clients}</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
             
            </Card>
          </Col>
          <Col xl="4 xl-50" className="box-col-12">
            <Card>
              <CardHeader className="card-no-border">
                <h5>{RatingBreakdown}</h5>
                <div className="card-header-right">
                  <ul className="list-unstyled card-option">
                    <li><i className="fa fa-spin fa-cog"></i></li>
                    <li><i className="view-html fa fa-code"></i></li>
                    <li><i className="icofont icofont-maximize full-card"></i></li>
                    <li><i className="icofont icofont-minus minimize-card"></i></li>
                    <li><i className="icofont icofont-refresh reload-card"></i></li>
                    <li><i className="icofont icofont-error close-card"></i></li>
                  </ul>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="our-product">
                  <div className="table-responsive">
                    <Table borderless>
                      <thead className="f-w-500">
                        <th>Staff</th>
                        <th style={{ textAlign: "right" }}>Clients</th>
                      </thead><br></br>
                      <tbody className="f-w-500">
                      {ratingBreakdown.map((item) =>
                          <tr>
                            <td>
                              <div>
                                <span>{item.name}</span>
                              </div>
                            </td>
                            <td>
                              <span>{item.clients}</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
             
            </Card>
          </Col>
          <Col xl="4 xl-50" className="box-col-12">
            <Card>
              <CardHeader className="card-no-border">
                <h5>{ClientTypeBreakdown}</h5>
                <div className="card-header-right">
                  <ul className="list-unstyled card-option">
                    <li><i className="fa fa-spin fa-cog"></i></li>
                    <li><i className="view-html fa fa-code"></i></li>
                    <li><i className="icofont icofont-maximize full-card"></i></li>
                    <li><i className="icofont icofont-minus minimize-card"></i></li>
                    <li><i className="icofont icofont-refresh reload-card"></i></li>
                    <li><i className="icofont icofont-error close-card"></i></li>
                  </ul>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="our-product">
                  <div className="table-responsive">
                    <Table borderless>
                      <thead className="f-w-500">
                        <th>Staff</th>
                        <th style={{ textAlign: "right" }}>Clients</th>
                      </thead><br></br>
                      <tbody className="f-w-500">
                      {clientTypeBreakdown.map((item) =>
                          <tr>
                            <td>
                              <div>
                                <span>{item.name}</span>
                              </div>
                            </td>
                            <td>
                              <span>{item.clients}</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
             
            </Card>
          </Col>
          
        </Row>
      </Container>
    </Fragment>
  );
}

export default Summary;