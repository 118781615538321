import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import BranchData from "./branch-data/BranchData";
import { getBranches } from "../../../../redux/branches/branchActions";
import { TABLE_COMPONENT_CONTEXT } from "../../../../contexts/schoolRequests";
import { BranchFilters } from "./branch-data/BranchFilters";
import { apiCall } from "../../../../redux/apiCall";
import { get_branches } from "../../../../constant/url";
import { Link } from "react-router-dom";

export function Branches() {
  const type = "Branches";
  const [requestId, setRequestId] = useState(0);
  const [branches, setBranch] = useState({
    total_records: 0,
    data: [],
  });
  const saloon = localStorage.getItem("saloon")
    ? JSON.parse(localStorage.getItem("saloon"))
    : null;
  const dispatch = useDispatch();
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    pageSize: 5,
    sortBy: "id",
    sortDirection: "DESC",
    status: "pending",
    hideOnNoData: 0,
  });

  const getSchoolRequest = async () => {
    if (saloon) {
      dispatch(
        apiCall(get_branches, "POST", { saloon_id: saloon.id }, (resp) => {
          if (resp.status) {
            setBranch({
              total_records: resp.data.length,
              numPages: resp.data.length,
              data: resp.data,
            });
          }
        })
      );
    }
  };

  useEffect(() => {
    getSchoolRequest();
  }, [paginationConfig]);

  const handlePagination = (paginationData) => {
    let temp = { ...paginationConfig };
    temp["pageNumber"] = paginationData.current;
    setPaginationConfig(temp);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    // setSearchReq(searchReq)
  };
  const handleActionId = (id) => {
    // dispatch(schoolRequestDetails({ "requestId": id }, (resp) => {
    //     setRequestId(resp.data.details.id);
    //     addToggle();
    // }))
  };

  return (
    <Fragment>
      <Breadcrumb parent="dashboard" title={"Branches"} />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="pt-0">
                <Row className="p-3">
                  <Col sm="12">
                    <TABLE_COMPONENT_CONTEXT.Provider
                      value={{
                        paginationConfig,
                        handlePagination,
                        setPaginationConfig,
                      }}
                    >
                      {/* <BranchFilters /> */}
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                          <h6>Total Records : {branches.total_records}</h6>
                        </div>
                        <Link to="/branch/create" className="btn btn-dark">
                          {" "}
                          Add branch
                        </Link>
                      </div>
                      <BranchData
                        loading={false}
                        type={type}
                        paginationConfig={paginationConfig}
                        data={branches}
                        onClickSort={(value) => handleSorting(value)}
                        onClickPagination={(value) => handlePagination(value)}
                        onClickAction={(e) => handleActionId(e)}
                        setPaginationConfig={setPaginationConfig}
                      />
                    </TABLE_COMPONENT_CONTEXT.Provider>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
