import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Branch name is required"),
  branch_id: Yup.string().required("Please select branch"),
  // service_type_id: Yup.string().required('Please select service'),
  category_id: Yup.string().required("Please select category"),
  price: Yup.string().required("Please add service price"),
  //   description: Yup.string().required("Please add a description"),
  duration_in_mins: Yup.string().required("Please provide service duration"),

  available_for: Yup.string().required("Please select service availability"),
});
