import React, { Fragment } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import SortBy from "../../../common/sortBy";
import TableComponent from "../../../common/TableComponent";
import Spinner from "../../../common/spinner";

const SchoolData = (props) => {
  const [data, setData] = useState([]);

  const handleAction = (id) => {
    props.onClickAction(id);
  };
  const schoolTableColumns = [
    {
      name: "Name",
      selector: "schoolName",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
      center: true,
    },
    {
      name: "Contacted Person",
      selector: "contactPersonDetails",
      sortable: true,
      center: true,
    },
    {
      name: "No. of Branch",
      selector: "branchesCount",
      sortable: true,
      center: true,
    },
    {
      name: "No. of Student",
      selector: "studentsCount",
      sortable: true,
      center: true,
    },
    {
      name: "No. of Teacher",
      selector: "teachersCount",
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: "status",
      center: true,
    },
    {
      name: "Actions",
      selector: "actions",
      center: true,
    },
  ];

  useEffect(() => {
    var schoolData = [];
    const data = props.data.data;
    data.forEach((element) => {
      const mappedData = {
        schoolName: element.schoolName,
        email: element.email,
        phone: element.phone,
        contactPersonDetails: element.contactPersonDetails,
        branchesCount: element.branchesCount,
        studentsCount: element.studentsCount,
        teachersCount: element.teachersCount,
        status: element.status,
        actions: [
          <i
            className="fa fa-eye font-primary"
            onClick={() => handleAction(element.id)}
          ></i>,
        ],
      };
      schoolData.push(mappedData);
    });

    setData(schoolData);
  }, [props]);

  const tableConfig = {
    tableColumns: schoolTableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };

  return (
    <Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <TableComponent
          loading={props.loading}
          tableConfig={tableConfig}
          numPages={props.data?.numPages}
          setPaginationConfig={props.setPaginationConfig}
        />
      )}
    </Fragment>
  );
};

export default SchoolData;
