import * as Yup from 'yup';
export const addresValidation = Yup.object().shape({
    // email: Yup.string().email('Please give proper email').required('Email is required'),
    country: Yup.string().required('Please provide country name'),
    state: Yup.string().required('Invalid state'),
    city: Yup.string().required('Invalid City'),
    zip: Yup.string().required('Invalid Zip Code'),
});

export const medicalValidation = Yup.object().shape({
    // email: Yup.string().email('Please give proper email').required('Email is required'),
    height: Yup.string().required('Invalid height'),
    weight: Yup.string().required('Invalid weight'),
    massIndex: Yup.string().required('Invalid mass index'),
    bloodGroup: Yup.string().required('Invalid blood group'),
});

export const guardianValidation = Yup.object().shape({
    relation: Yup.string().required('Relation is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    IdNumber: Yup.string().required('ID Number is required'),
    qualification: Yup.string().required('Qualification is required'),
    occupation: Yup.string().required('Occupation is required'),
    organization: Yup.string().required('Organization is required'),
    salary: Yup.string().required('Salary is required'),
    contactNumber: Yup.string().required('Contact No is required'),
    emergencyContactNumber: Yup.string().required('Emergency Contact No is required'),
});