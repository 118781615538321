import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, CardHeader, CardBody, Card } from 'reactstrap';
import { FormInput } from '../forms/formBuilder';
import { useHistory } from 'react-router-dom';
import { clientsNotesColumns } from './clientDataConfig';
import TableComponent from '../common/TableComponent';
import { useEffect } from 'react';


const ClientNotes = (props) => {
    const clientId = props.clientId
    const endpoint = localStorage.getItem('api-endpoint');
    const token = localStorage.getItem('token')
    const history = useHistory();
    const [notes, setNotes] = useState([]);
    const [noteModal, setNoteModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [paginationConfig, setPaginationConfig] = useState({
        pageNumber: 1,
        pageSize: 10,
        sortBy: 'id',
        order: 'ASC',
        hideOnNoData: 1
    });


    const onSubmit = data => {
        const title = document.querySelector('#title').value
        const note = document.querySelector('#note').value
        const clientId = document.querySelector('#clientId').value
        const formData = {
            title, note, clientId
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        };
        fetch(endpoint + "clients-notes", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === true) {
                        toast.success(result.message)
                        setReload(true)
                        noteModalToggle()
                    } else {
                        let erros = result.payload
                        erros.map((element) => (
                            toast.error(element.msg)
                        ))

                    }
                },
                (error) => {
                    toast.error(error)
                }
            )



    };


    const noteModalToggle = () => setNoteModal(!noteModal);


    useEffect(() => {
        fetch(endpoint + "clients-notes/" + clientId, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => res.json())
            .then(
                (result) => {
                    setNotes(result.package.data)
                },
                (error) => {
                    toast.error(error)
                }
            )
    }, [notes])


    const handlePagination = (paginationData) => {
        setPaginationConfig(paginationData)
    }

    const tableConfig = {
        tableColumns: clientsNotesColumns,
        data: notes,
        onClickPagination: "s",
        paginationConfig: paginationConfig
    };
    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <h4 className="card-title mb-0">Notes</h4>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                        <Button color="primary" onClick={noteModalToggle}>{"Add Note"}</Button>
                        <Modal isOpen={noteModal} toggle={noteModalToggle} centered>
                            <Form className="needs-validation" noValidate="" onSubmit={onSubmit}>
                                <ModalHeader toggle={noteModalToggle}>
                                    {'Add Note'}
                                </ModalHeader>
                                <ModalBody>
                                    <Col md="12 mb-3">
                                        <FormInput value={clientId} name="clientId" type="hidden" required={1} />
                                        <FormInput label="Title *" name="title" placeholder="Title" required={1} />
                                    </Col>
                                    <Col md="12 mb-3">
                                        <FormInput label="Note *" type="textarea" name="note" placeholder="Message" required={1} />
                                    </Col>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={noteModalToggle}>{'Close'}</Button>
                                    <Button color="primary" onClick={onSubmit}>{"Submit"}</Button>
                                </ModalFooter>
                            </Form>
                        </Modal>
                    </div>
                </CardHeader>
                <CardBody>
                    <TableComponent tableConfig={tableConfig} />
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ClientNotes;