import React from "react";
import { Alert, Button, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Row, FormGroup, Input, Label, Col } from "reactstrap";
import { RequiredField } from "../../utils/fieldRequired";
import ErrorMessage from "../../utils/errorMessage";
import { Formik } from "formik";
import { validationSchema } from "./price-modal/validationSchema";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../redux/apiCall";
import { checkout_url } from "../../../constant/url";
import { toast } from "react-toastify";

export function Checkout({ open, setOpen, appointment, callBack }) {
  const initialValues = {
    pay_with: "",
    amount_paid: "",
    discount_amount: "",
  };
  const dispatch = useDispatch();
  const onSubmit = (values, formActions) => {
    const _appointment =
      appointment || JSON.parse(localStorage.getItem("appointment"));
    const _values = {
      ...values,
      appointment_id: _appointment?.appointment_details?.id,
    };
    dispatch(
      apiCall(checkout_url, "POST", _values, (resp) => {
        if (resp.status) {
          formActions.setSubmitting(false);
          formActions.resetForm();
          setOpen();
          toast.dark("Operation Successfull!");
          localStorage.removeItem("appointment");
          if (callBack) {
            callBack();
          }
        } else {
          formActions.setSubmitting(false);
          toast.error(resp.message);
        }
      })
    );
  };

  return (
    <Modal isOpen={open} toggle={setOpen} size="md">
      <ModalHeader toggle={setOpen}>Checkout</ModalHeader>
      <ModalBody>
        <div className="p-4">
          <Alert>
            The total amount to be paid is Rs.{" "}
            {appointment?.appointment_details?.total_price}
          </Alert>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              isValid,
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="p-3" onSubmit={handleSubmit} id="reciept">
                <Row>
                  <Col md="12" sm="12" xs="12">
                    <FormGroup>
                      <Label for={"pay_with"}>
                        Amount Paid <RequiredField />
                      </Label>

                      <Input
                        className="form-control"
                        name="amount_paid"
                        onChange={handleChange}
                      />
                      {errors["pay_with"] && touched["pay_with"] && (
                        <ErrorMessage message={errors["pay_with"]} />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="12" sm="12" xs="12">
                    <FormGroup>
                      <Label for={"pay_with"}>Discount Amount</Label>

                      <Input
                        className="form-control"
                        name="discount_amount"
                        onChange={handleChange}
                      />
                      {errors["discount_amount"] &&
                        touched["discount_amount"] && (
                          <ErrorMessage message={errors["discount_amount"]} />
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"pay_with"}>
                        Pay with <RequiredField />
                      </Label>

                      <div className="form-check form-check-inline d-flex align-iems-center">
                        <Input
                          type="radio"
                          name={"pay_with"}
                          value={"cash"}
                          onChange={handleChange}
                        />{" "}
                        <Label className="mb-0">Cash</Label>
                      </div>
                      <div className="form-check form-check-inline d-flex align-iems-center">
                        <Input
                          type="radio"
                          name={"pay_with"}
                          value="card"
                          onChange={handleChange}
                        />{" "}
                        <Label className="mb-0">Card</Label>
                      </div>

                      {errors["pay_with"] && touched["pay_with"] && (
                        <ErrorMessage message={errors["pay_with"]} />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-right">
                  <Button
                    color="primary"
                    className="mr-1"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
}
