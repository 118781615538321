import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardHeader, CardBody } from 'reactstrap';
import { FormInput } from '../forms/formBuilder';
import { useHistory } from 'react-router-dom';
import TableComponent from '../common/TableComponentSimple';
import { useEffect } from 'react';
import { MonthArray } from '../../constant'
import { clientsEditLogColumns } from './clientDataConfig';

const ClientEditLogModal = (props) => {

    const clientId = props.clientId
    const endpoint = localStorage.getItem('api-endpoint');
    const token = localStorage.getItem('token');
    const [editLog, setEditLog] = useState([]);
    const history = useHistory();
    const [reload, setReload] = useState(false);
    const [paginationConfig, setPaginationConfig] = useState({
        pageNumber: 1,
        pageSize: 10,
        sortBy: 'id',
        order: 'ASC',
        hideOnNoData: 1
    });
    const onToggle = data => {
        props.clientEditLogModalToggle()
    };

    const MapClientEditLogObject = (resultObject) => {
        let clientObject =[];
        resultObject.forEach(element => {
            let date =new Date(element.createdAt);
            let dateString =date.getDate() +' '+  MonthArray[date.getMonth()]+' '+date.getFullYear()
            clientObject.push ({
                createdAt : dateString,
                name:element.name,
                email:element.email,
                phone:element.phone,
                cells:element.cell,
                updatedBy:element.updatedByData.fullName,
                reason:element.reason
              });
        });
        
    
        return clientObject
      }

    useEffect(() => {
        fetch(endpoint + "clients/edit-log/" + clientId, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => res.json())
            .then(
                (result) => {
                    setEditLog(MapClientEditLogObject(result.package.data))
                },
                (error) => {
                    toast.error(error)
                }
            )
    }, [reload])


    const tableConfig = {
        tableColumns: clientsEditLogColumns,
        data: editLog,
        onClickPagination: "s",
        paginationConfig: paginationConfig
    };

    return (
        <Fragment>
            <Modal isOpen={props.clientEditLogModal} toggle={onToggle} centered size='xl'>
                <Card>
                    <CardBody>
                    <h4 className="card-title mb-0">Client Edit Log</h4>
                        <TableComponent tableConfig={tableConfig} />
                    </CardBody>
                </Card>
            </Modal>
        </Fragment>
    );
};

export default ClientEditLogModal;