import React, { Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col } from "reactstrap";
import ClientData from "./clientData";
import SearchPanel from "../common/searchPanel";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { SearchConfig } from "./clientDataConfig";

const ClientClassification = (props) => {
  const [clients, setClients] = useState({
    total_records: 0,
    data: [],
  });

  const endpoint = localStorage.getItem("api-endpoint");
  const token = localStorage.getItem("token");
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortBy: "id",
    order: "ASC",
    hideOnNoData: 0,
  });

  let searchParams = [];
  {
    SearchConfig.params.map((items) => (searchParams[items.name] = ""));
  }
  searchParams = { ...searchParams };
  const [searchReq, setSearchReq] = useState(searchParams);
  const searchConfig = { ...searchReq, SearchConfig };
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  let params = useParams();
  const type = params.type ? params.type : "Search" + " Clients";

  useEffect(() => {
    setLoading(true);
    fetchOptions();
    fetchClientData();
  }, [paginationConfig, params.type, searchReq]);

  const fetchClientData = () => {
    const formData = {
      pageNumber: paginationConfig.pageNumber,
      pageSize: paginationConfig.pageSize,
      order: paginationConfig.order,
      sortBy: paginationConfig.sortBy,
      searchBy: searchReq,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    fetch(endpoint + "clients/manage/" + params.type, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === true) {
            setClients({
              total_records: result.package.data.total_records,
              data: result.package.data.data,
            });
            setLoading(false); // Hide loading screen
          } else {
            let erros = result.payload;
            erros.map((element) => toast.error(element.msg));
          }
        },
        (error) => {
          toast.error(error);
        }
      );
  };
  const fetchOptions = () => {
    fetch(endpoint + "clients/get-client-add-form-data", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          const data = {
            assignedTo: result.package.teamMembers,
            clientRatingId: result.package.rating,
            clientTypeId: result.package.types,
          };
          setOptions(data);
        },
        (error) => {
          toast.error(error);
        }
      );
    return SearchConfig;
  };

  const handleFavClient = (id) => {};

  const handlePagination = (paginationData) => {
    setPaginationConfig(paginationData);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    setSearchReq(searchReq);
  };
  return (
    <Fragment>
      <Breadcrumb parent="dashboard" title={type} />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <SearchPanel
              searchConfig={searchConfig}
              options={options}
              onSearchClick={(value) => handleSearch(value)}
            />
          </Col>
          <Col sm="12">
            <ClientData
              loading={loading}
              type={type}
              paginationConfig={paginationConfig}
              data={clients}
              onClickFav={(value) => handleFavClient(value)}
              onClickSort={(value) => handleSorting(value)}
              onClickPagination={(value) => handlePagination(value)}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ClientClassification;
