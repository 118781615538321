import React from "react";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  Table,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { RequiredField } from "../../../../utils/fieldRequired";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { apiCall } from "../../../../../redux/apiCall";
import {
  student_pevschool_add,
  student_pevschool_delete,
  student_pevschool_update,
  student_previous_schools,
} from "../../../../../redux/endpoints";
import ErrorMessage from "../../../../utils/errorMessage";
import { Loader } from "../../../../utils/Loader";

export function PreviousSchoolInformation() {
  const [schoolInfo, setSchoolInfo] = React.useState([{}]);
  const [loading, setLoading] = React.useState(true);
  const { id } = useParams();
  const [errors, setErrors] = React.useState([{}]);
  const dispatch = useDispatch();

  const removeSchoolInfo = (index) => {
    let originalRecords = [...schoolInfo];
    let values = { ...originalRecords[index] };
    if (values && values.id) {
      dispatch(
        apiCall(
          student_pevschool_delete,
          "POST",
          { studentId: id, previousSchoolId: values.id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Previous school record removed successfully!");
                let records = originalRecords.filter(
                  (item, key) => key !== index
                );
                setSchoolInfo(records);
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    } else {
      let records = originalRecords.filter((item, key) => key !== index);
      setSchoolInfo(records);
    }
  };
  const fetchPreviousSchools = () => {
    dispatch(
      apiCall(student_previous_schools, "POST", { studentId: id }, (resp) => {
        if (resp.data.length) {
          setSchoolInfo(resp.data);
        }
        setLoading(false);
      })
    );
  };
  const handleChange = ({ currentTarget: input }, index) => {
    let _schoolInfo = [...schoolInfo];
    let _errors = [...errors];
    _errors[index][`${input.name}`] = null;
    _schoolInfo[index][input.name] = input.value;
    setSchoolInfo(_schoolInfo);
    setErrors(_errors);
  };
  React.useState(() => {
    fetchPreviousSchools();
  }, []);
  const onSubmit = (values, index) => {
    let e = checkErrors(values, index);
    if (Object.keys(e).length) {
      let _errors = [...errors];
      _errors[index] = e;
      setErrors(_errors);
      return;
    }

    if (values.id) {
      dispatch(
        apiCall(
          student_pevschool_update,
          "POST",
          { ...values, studentId: id, previousSchoolId: values.id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Previous school record updated successfully!");
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    } else {
      dispatch(
        apiCall(
          student_pevschool_add,
          "POST",
          { ...values, studentId: id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Previous school added successfully!");
                let _schoolInfo = [...schoolInfo];
                _schoolInfo[index] = resp.data;
                setSchoolInfo(_schoolInfo);
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    }
  };
  const checkErrors = (values) => {
    let _errors = {};
    if (!values.schoolName || !values.schoolName.length) {
      _errors.schoolName = "Provide School Name";
    }
    if (!values.boardName || !values.boardName.length) {
      _errors.boardName = "Provide Board Name";
    }
    if (!values.marks || !values.marks.length) {
      _errors.marks = "Provide marks";
    }

    return _errors;
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      {schoolInfo.map((record, index) => (
        <div className="py-2">
          <div className="d-flex justify-content-between w-100">
            <h6 className="text-primary">{index + 1} .</h6>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => removeSchoolInfo(index)}
            >
              <i className="fa fa-times text-danger"></i>
            </div>{" "}
          </div>
          <Row>
            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"schoolName"}>
                  School Name <RequiredField />
                </Label>
                <Input
                  name={"schoolName"}
                  type={"text"}
                  size={"sm"}
                  value={record.schoolName}
                  onChange={(e) => handleChange(e, index)}
                ></Input>
                {errors[index]?.schoolName && (
                  <ErrorMessage message={errors[index]["schoolName"]} />
                )}
              </FormGroup>
            </Col>
            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"boardName"}>
                  Board Name <RequiredField />
                </Label>
                <Input
                  name={"boardName"}
                  type={"text"}
                  value={record.boardName}
                  size={"sm"}
                  onChange={(e) => handleChange(e, index)}
                ></Input>
                {errors[index]?.boardName && (
                  <ErrorMessage message={errors[index]["boardName"]} />
                )}
              </FormGroup>
            </Col>
            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"marks"}>
                  Grades <RequiredField />
                </Label>
                <Input
                  name={"marks"}
                  type={"text"}
                  size={"sm"}
                  value={record.marks}
                  onChange={(e) => handleChange(e, index)}
                ></Input>
                {errors[index]?.marks && (
                  <ErrorMessage message={errors[index]["marks"]} />
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex w-100 justify-content-end">
            <Button
              color="primary"
              className="mr-1"
              type="button"
              onClick={(e) => onSubmit(record, index)}
            >
              {record.id ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      ))}
      <div className="d-flex w-100 justify-content-start">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setSchoolInfo([
              ...schoolInfo,
              {
                studentId: id,
                schoolName: "",
                boardName: "",
                marks: "",
              },
            ]);
            setErrors([
              ...errors,
              {
                schoolName: "",
                boardName: "",
                marks: "",
              },
            ]);
          }}
        >
          Add More
        </button>
      </div>
    </>
  );
}
