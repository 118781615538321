export const fields = [
    {
        name: "code",
        type: "text",
        label: "Subject Code",
        placeholder: "Subject Code",
        required: true,
    }, 
    {
        name: "name",
        type: "text",
        label: "Subject Name",
        placeholder: "Subject Name",
        required: true,
    }, 

]