import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  CardHeader,
  CardBody,
  Card,
} from "reactstrap";
import { FormInput } from "../forms/formBuilder";
import { useHistory } from "react-router-dom";
import { clientsNotesColumns } from "./clientDataConfig";
import { useEffect } from "react";

const ClientNotes = (props) => {
  const clientId = props.clientId;
  const endpoint = localStorage.getItem("api-endpoint");
  const token = localStorage.getItem("token");
  const [teamMembers, setTeamMembers] = useState([]);
  const [showAllocation, setShowAllocation] = useState(false);
  const [assignedTo, setAssignedTo] = useState({});

  const onSubmit = (data) => {
    const assignedTo = document.querySelector("#assignedTo").value;
    const clientId = document.querySelector("#clientId").value;

    const formData = {
      assignedTo,
      clientId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    fetch(endpoint + "clients/change-allocation", requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === true) {
            toast.success(result.message);
            setAssignedTo(result.package.data);
            allocationToggle();
          } else {
            toast.error(result.message);
          }
        },
        (error) => {
          toast.error(error);
        }
      );
  };
  const allocationToggle = () => setShowAllocation(!showAllocation);

  useEffect(() => {
    const assignedToObj = props.assignedTo
      ? props.assignedTo
      : {
          id: "",
          fullName: "",
        };

    setAssignedTo(assignedToObj);

    fetch(endpoint + "/get-team-members/", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTeamMembers(result.package.data);
        },
        (error) => {
          toast.error(error);
        }
      );
  }, [props]);

  return (
    <Fragment>
      <br></br>
      <div className="modal-dialog modal-lg mt-3">
        <div className="modal-dialog ">
          <div className="modal-content">
            <ModalBody>
              <p className="text-info">
                <i className="fa fa-info-circle"></i>
                {" Client is Allocated to: " + assignedTo.fullName + "    "}
                <span onClick={allocationToggle}>
                  <i className="fa fa-edit"></i>
                </span>
              </p>
            </ModalBody>
            {showAllocation ? (
              <Form
                className="needs-validation"
                noValidate=""
                onSubmit={onSubmit}
              >
                <Col md="6 mb-3">
                  <FormInput
                    value={clientId}
                    name="clientId"
                    type="hidden"
                    required={1}
                  />
                  <FormInput
                    type="select"
                    name="assignedTo"
                    required={0}
                    options={teamMembers}
                    value={assignedTo.id}
                  />
                  <Button color="primary" onClick={onSubmit}>
                    {"Change Allocation"}
                  </Button>
                </Col>
              </Form>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ClientNotes;
