import React, { Fragment } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { RequiredField } from "../../../utils/fieldRequired";
import ErrorMessage from "../../../utils/errorMessage";
import { Submit } from "../../../../constant";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { useDispatch } from "react-redux";
import { createBranch } from "../../../../redux/branches/branchActions";
import { toast } from "react-toastify";
import { apiCall } from "../../../../redux/apiCall";
import {
  create_branch,
  create_categories,
  get_branches,
  update_categories,
} from "../../../../constant/url";
import { getSaloon } from "../../../utils/getSaloon";
import { useParams, useLocation, useHistory } from "react-router-dom";
export function CreateCategories() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    name: "",
    color: "#ffff",
  });
  const { status } = useParams();
  const saloon = getSaloon();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    fetchBranches();
  }, []);
  React.useEffect(() => {
    if (status === "edit") {
      setInitialValues({ ...initialValues, name: state?.category?.name });
    }
  }, [status, state]);
  const fetchBranches = () => {
    dispatch(
      apiCall(get_branches, "POST", { saloon_id: saloon.id }, (resp) => {
        setBranches(resp.data);
      })
    );
  };
  const onSubmit = (values, formActions) => {
    try {
      let _values =
        status === "create"
          ? { ...values }
          : { ...values, category_id: state?.category?.id };

      dispatch(
        apiCall(
          status === "create" ? create_categories : update_categories,
          "POST",
          _values,
          (resp) => {
            if (resp.status) {
              formActions.setSubmitting(false);
              formActions.resetForm();
              toast.success(`Category ${status}ed successfully!`);
              setTimeout(() => {
                history.push("/categories", { replace: true });
              }, 2000);
            } else {
              formActions.setSubmitting(false);
              toast.error(resp.message);
            }
          }
        )
      );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title={`${status === "create" ? "Create" : "Edit"} Category`}
      />

      <Container fluid={true}>
        <Card>
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Form className="p-3" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"name"}>
                          Name <RequiredField />
                        </Label>
                        <Input
                          name={"name"}
                          onChange={handleChange}
                          type={"text"}
                          size={"sm"}
                          placeholder={"Category Name"}
                          value={values["name"]}
                        />
                        {errors["name"] && touched["name"] && (
                          <ErrorMessage message={errors["name"]} />
                        )}
                      </FormGroup>
                    </Col>

                    {/* <Col md="6" sm="6" xs='12'>
                                        <FormGroup >
                                            <Label for={"name"}>
                                                Branch <RequiredField />
                                            </Label>
                                            <Input
                                                name={"branch_id"}
                                                onChange={handleChange}
                                                type={"select"}
                                                size={"sm"}
                                            >
                                                <option> Select....</option>
                                                {branches.map(({ name, id, value }, index) => <option key={index} value={id}>{name}</option>)}
                                            </Input>
                                            {errors["branch_id"] && touched["branch_id"] && (
                                                <ErrorMessage message={errors["branch_id"]} />
                                            )}
                                        </FormGroup>
                                    </Col> */}
                    {/* <Col md="6" sm="6" xs='12'>
                                        <FormGroup >
                                            <Label for={"name"}>
                                                Color <RequiredField />
                                            </Label>
                                            <Input
                                                name={"color"}
                                                onChange={handleChange}
                                                type={"color"}
                                                size={"sm"}
                                                value={values["color"]}
                                            />
                                            {errors["color"] && touched["color"] && (
                                                <ErrorMessage message={errors["color"]} />
                                            )}
                                        </FormGroup>
                                    </Col> */}
                  </Row>
                  <div className="text-right">
                    <Button color="primary" className="mr-1" type="submit">
                      {Submit}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}
