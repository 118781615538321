import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
import {
  Database,
  ShoppingBag,
  MessageCircle,
  UserPlus,
  Layers,
  ShoppingCart,
  DollarSign,
  ArrowDown,
  ArrowUp,
  CloudDrizzle,
  Phone,
} from "react-feather";
import DatePicker from "react-datepicker";
import ApexCharts from "react-apexcharts";
import Chart from "react-apexcharts";
import ChartistChart from "react-chartist";
import "./dashboard.scss";
import Knob from "knob";
import { Currentlysale, Marketvalue } from "./chartData/apex-charts-data";
import {
  smallchart1data,
  smallchart1option,
  smallchart2data,
  smallchart2option,
  smallchart3data,
  smallchart3option,
  smallchart4data,
  smallchart4option,
} from "./chartData/chartist-charts-data";
import { Send, Clock } from "react-feather";
import {
  Dashboard,
  Summary,
  NewsUpdate,
  Appointment,
  Notification,
  MarketValue,
  Chat,
  New,
  Tomorrow,
  Yesterday,
  Daily,
  Weekly,
  Monthly,
  Store,
  Online,
  ReferralEarning,
  CashBalance,
  SalesForcasting,
  Purchase,
  Sales,
  SalesReturn,
  PurchaseRet,
  PurchaseOrderValue,
  ProductOrderValue,
  Pending,
  Yearly,
  Hot,
  Today,
  VenterLoren,
  Done,
  JohnLoren,
  Year,
  Month,
  Day,
  RightNow,
  ProductsCart,
} from "../../constant";
import { radialChartLive } from "../widgets/charts/charts-data";
import axios from "axios";
import { apiCall } from "../../redux/apiCall";
import { total_appointments } from "../../constant/url";
import { useDispatch } from "react-redux";
import { DashboardChartCard } from "./DashboardChartCards";
import CountUp from "react-countup";
import { ReactComponent as RevenueIcon } from "../../assets/svg/revenue.svg";
import { AppointmentChart } from "./appointment-chart/AppointmentChart";
import { TopCategories } from "./top-catogories/TopCategories";
import { SalesChart } from "./sales-chart/SalesChart";
const Default = (props) => {
  const dispatch = useDispatch();
  const [daytimes, setDayTimes] = useState();
  const today = new Date();
  const curHr = today.getHours();
  const curMi = today.getMinutes();
  const [meridiem, setMeridiem] = useState("AM");
  // eslint-disable-next-line
  const [date, setDate] = useState({ date: new Date() });
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(new Date());
  const [generalData, setGeneralData] = useState([]);
  const [appointments, setAppointments] = useState(null);
  const { clients, cartProducts, employeeStatus } = generalData;
  const handleChange = (date) => {
    setDate(date);
  };
  const fetchAnalytics = async () => {
    dispatch(
      apiCall(total_appointments, "POST", {}, (resp) => {
        setAppointments(resp.data);
      })
    );
  };
  useEffect(() => {
    axios
      .get(`${process.env.PUBLIC_URL}/api/general.json`)
      .then((res) => setGeneralData(res.data.result));

    fetchAnalytics();
  }, []);

  useEffect(() => {
    if (curHr < 12) {
      setDayTimes("Good Morning");
    } else if (curHr < 18) {
      setDayTimes("Good Afternoon");
    } else {
      setDayTimes("Good Evening");
    }

    if (curHr >= 12) {
      setMeridiem("PM");
    } else {
      setMeridiem("AM");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Analytics" />
      <Container fluid={true}>
        {appointments && (
          <Row className="second-chart-list third-news-update">
            <Col sm="6" xl="5" lg="6">
              <Card>
                <div className="d-flex analytics-card w-100">
                  <div className="icon">
                    <RevenueIcon />
                  </div>
                  <div className="figures">
                    <p className="title">Total Appointments</p>
                    <p className="value">{appointments?.total}</p>
                    <div className="appointment-values">
                      <div className="items ">
                        <div className="type-values">
                          <p className="title">Walk in</p>
                          <p className="value">{appointments?.["Walk In"]}</p>
                        </div>
                        <div className="type-values">
                          <p className="title">Phone</p>
                          <p className="value">
                            {appointments?.["Phone-Appointment"]}
                          </p>
                        </div>
                      </div>
                      <div className="divider"></div>

                      <div className="items ml-3">
                        <div className="type-values">
                          <p className="title">New</p>
                          <p className="value">{appointments?.new}</p>
                        </div>
                        <div className="type-values ml-1">
                          <p className="title">Returning</p>
                          <p className="value">{appointments?.returning}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>

            <Col xl="8 xl-100" className="dashboard-sec box-col-12">
              <AppointmentChart />
            </Col>
            <Col xl="8 xl-100" className="dashboard-sec box-col-12">
              <SalesChart />
            </Col>
            <Col xl="8 xl-100" className="dashboard-sec box-col-12">
              <TopCategories />

              {/* <CardBody className="p-0">
                  <Row className="m-0">
                    <Col xl="9" className="p-0">
                      <div className="chart-right">
                        <Row>
                          <Col xl="12">
                            <CardBody className="p-0">
                              <div className="chart-container">
                                <div
                                  id="circlechart"
                                  style={{ marginTop: "25px" }}
                                >
                                  <Chart
                                    options={}
                                    series={[
                                      appointments?.new,
                                      appointments?.["returning"],
                                      appointments?.["Walk In"],
                                      appointments?.["Phone-Appointment"],
                                    ]}
                                    height="350"
                                    type="donut"
                                  />
                                </div>
                              </div>
                            </CardBody>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody> */}
            </Col>
            {/* <Col xl="6" className="xl-100 box-col-12">
              <Card>
                <CardHeader>
                  <h5>{ProductsCart}</h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"Details"}</th>
                          <th scope="col">{"Quantity"}</th>
                          <th scope="col">{"Status"}</th>
                          <th scope="col">{"Price"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartProducts?.map((data) => (
                          <tr key={data.details}>
                            <td>{data.details}</td>
                            <td className="digits">{data.qty}</td>
                            <td className={`font-${data.statusColor}`}>
                              {data.status}
                            </td>
                            <td>
                              <div
                                className={`span badge badge-pill ${data.className}`}
                              >
                                {data.price}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="9 xl-100" className="chart_data_left box-col-12">
              <Card>
                <CardBody className="p-0">
                  <Row className="m-0 chart-main">
                    <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="hospital-small-chart">
                          <div className="small-bar">
                            <ChartistChart
                              className="small-chart flot-chart-container"
                              data={smallchart1data}
                              options={smallchart1option}
                              type={"Bar"}
                              listener={{
                                draw: function (data) {
                                  if (data.type === "bar") {
                                    data.element.attr({
                                      style: "stroke-width: 3px",
                                    });
                                  }
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>{"1001"}</h4>
                            <span>{Purchase} </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="hospital-small-chart">
                          <div className="small-bar">
                            <ChartistChart
                              className="small-chart1 flot-chart-container"
                              data={smallchart2data}
                              options={smallchart2option}
                              type={"Bar"}
                              listener={{
                                draw: function (data) {
                                  if (data.type === "bar") {
                                    data.element.attr({
                                      style: "stroke-width: 3px",
                                    });
                                  }
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>{"1005"}</h4>
                            <span>{Sales}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="hospital-small-chart">
                          <div className="small-bar">
                            <ChartistChart
                              className="small-chart2 flot-chart-container"
                              data={smallchart3data}
                              options={smallchart3option}
                              type={"Bar"}
                              listener={{
                                draw: function (data) {
                                  if (data.type === "bar") {
                                    data.element.attr({
                                      style: "stroke-width: 3px",
                                    });
                                  }
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>{"100"}</h4>
                            <span>{SalesReturn}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                      <div className="media border-none align-items-center">
                        <div className="hospital-small-chart">
                          <div className="small-bar">
                            <ChartistChart
                              className="small-chart3 flot-chart-container"
                              data={smallchart4data}
                              options={smallchart4option}
                              type={"Bar"}
                              listener={{
                                draw: function (data) {
                                  if (data.type === "bar") {
                                    data.element.attr({
                                      style: "stroke-width: 3px",
                                    });
                                  }
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>{"101"}</h4>
                            <span>{PurchaseRet}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl="4 xl-50" className="news box-col-6">
              <Card>
                <CardHeader>
                  <div className="header-top">
                    <h5 className="m-0">{NewsUpdate}</h5>
                    <div className="card-header-right-icon">
                      <select className="button btn btn-primary">
                        <option>{Today}</option>
                        <option>{Tomorrow}</option>
                        <option>{Yesterday}</option>
                      </select>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="news-update">
                    <h6>{"36% off For pixel lights Couslations Types."}</h6>
                    <span>{"Lorem Ipsum is simply dummy..."}</span>
                  </div>
                  <div className="news-update">
                    <h6>{"We are produce new product this"}</h6>
                    <span>
                      {" "}
                      {"Lorem Ipsum is simply text of the printing... "}
                    </span>
                  </div>
                  <div className="news-update">
                    <h6>{"50% off For COVID Couslations Types."}</h6>
                    <span>{"Lorem Ipsum is simply dummy..."}</span>
                  </div>
                </CardBody>
                <div className="card-footer">
                  <div className="bottom-btn">
                    <a href="#javascript">{"More..."}</a>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xl="4 xl-50" className="appointment-sec box-col-6">
              <Row>
                <Col xl="12" className="appointment">
                  <Card>
                    <CardHeader className="card-no-border">
                      <div className="header-top">
                        <h5 className="m-0">{Appointment}</h5>
                        <div className="card-header-right-icon">
                          <select className="button btn btn-primary">
                            <option>{Today}</option>
                            <option>{Tomorrow}</option>
                            <option>{Yesterday}</option>
                          </select>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <div className="appointment-table table-responsive">
                        <table className="table table-bordernone">
                          <tbody>
                            <tr>
                              <td>
                                <img
                                  className="img-fluid img-40 rounded-circle mb-3"
                                  src={require("../../assets/images/appointment/app-ent.jpg")}
                                  alt=""
                                />
                                <div className="status-circle bg-primary"></div>
                              </td>
                              <td className="img-content-box">
                                <span className="d-block">{VenterLoren}</span>
                                <span className="font-roboto">Now</span>
                              </td>
                              <td>
                                <p className="m-0 font-primary">{"28 Sept"}</p>
                              </td>
                              <td className="text-right">
                                <div className="button btn btn-primary">
                                  {Done}
                                  <i className="fa fa-check-circle ml-2"></i>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  className="img-fluid img-40 rounded-circle"
                                  src={require("../../assets/images/appointment/app-ent.jpg")}
                                  alt=""
                                />
                                <div className="status-circle bg-primary"></div>
                              </td>
                              <td className="img-content-box">
                                <span className="d-block">{JohnLoren}</span>
                                <span className="font-roboto">{"11:00"}</span>
                              </td>
                              <td>
                                <p className="m-0 font-primary">{"22 Sept"}</p>
                              </td>
                              <td className="text-right">
                                <div className="button btn btn-danger">
                                  {Pending}
                                  <i className="fa fa-check-circle ml-2"></i>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="12" className="alert-sec">
                  <Card className="bg-img">
                    <CardHeader>
                      <div className="header-top">
                        <h5 className="m-0">{"Alert"} </h5>
                        <div className="dot-right-icon">
                          <i className="fa fa-ellipsis-h"></i>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="body-bottom">
                        <h6> {"10% off For drama lights Couslations..."}</h6>
                        <span className="font-roboto">
                          {
                            "Lorem Ipsum is simply dummy...It is a long established fact that a reader will be distracted by "
                          }{" "}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xl="4 xl-50" className="notification box-col-6">
              <Card>
                <CardHeader className="card-no-border">
                  <div className="header-top">
                    <h5 className="m-0">{Notification}</h5>
                    <div className="card-header-right-icon">
                      <select className="button btn btn-primary">
                        <option>{Today}</option>
                        <option>{Tomorrow}</option>
                        <option>{Yesterday}</option>
                      </select>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <div className="media">
                    <div className="media-body">
                      <p>
                        {"20-04-2020"} <span>{"10:10"}</span>
                      </p>
                      <h6>
                        {"Updated Product"}
                        <span className="dot-notification"></span>
                      </h6>
                      <span>
                        {"Quisque a consequat ante sit amet magna..."}
                      </span>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-body">
                      <p>
                        {"20-04-2020"}
                        <span className="pl-1">{Today}</span>
                        <span className="badge badge-secondary">{New}</span>
                      </p>
                      <h6>
                        {"Tello just like your product"}
                        <span className="dot-notification"></span>
                      </h6>
                      <span>
                        {"Quisque a consequat ante sit amet magna... "}
                      </span>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-body">
                      <div className="d-flex mb-3">
                        <div className="inner-img">
                          <img
                            className="img-fluid"
                            src={require("../../assets/images/notification/1.jpg")}
                            alt="Product-1"
                          />
                        </div>
                        <div className="inner-img">
                          <img
                            className="img-fluid"
                            src={require("../../assets/images/notification/2.jpg")}
                            alt="Product-2"
                          />
                        </div>
                      </div>
                      <span className="mt-3">
                        {"Quisque a consequat ante sit amet magna..."}
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4 xl-50" className="appointment box-col-6">
              <Card>
                <CardHeader>
                  <div className="header-top">
                    <h5 className="m-0">{MarketValue}</h5>
                    <div className="card-header-right-icon">
                      <select className="button btn btn-primary">
                        <option>{Year}</option>
                        <option>{Month}</option>
                        <option>{Day}</option>
                      </select>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="radar-chart">
                    <ApexCharts
                      id="marketchart"
                      options={Marketvalue.options}
                      series={Marketvalue.series}
                      type="radar"
                      height={300}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4 xl-100" className="chat-sec box-col-6">
              <Card className="chat-default">
                <CardHeader className="card-no-border">
                  <div className="media media-dashboard">
                    <div className="media-body">
                      <h5 className="mb-0">{Chat}</h5>
                    </div>
                    <div className="icon-box">
                      <i className="fa fa-ellipsis-h"></i>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="chat-box">
                  <div className="chat">
                    <div className="media left-side-chat">
                      <div className="media-body d-flex">
                        <div className="img-profile">
                          {" "}
                          <img
                            className="img-fluid"
                            src={require("../../assets/images/User.jpg")}
                            alt="Profile"
                          />
                        </div>
                        <div className="main-chat">
                          <div className="message-main">
                            <span className="mb-0">
                              {"Hi deo, Please send me link."}
                            </span>
                          </div>
                          <div className="sub-message message-main">
                            <span className="mb-0">{RightNow}</span>
                          </div>
                        </div>
                      </div>
                      <p className="f-w-400">{"7:28 PM"}</p>
                    </div>
                    <div className="media right-side-chat">
                      <p className="f-w-400">{"7:28 PM"}</p>
                      <div className="media-body text-right">
                        <div className="message-main pull-right">
                          <span className="mb-0 text-left">
                            {"How can do for you"}
                          </span>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                    <div className="media left-side-chat">
                      <div className="media-body d-flex">
                        <div className="img-profile">
                          {" "}
                          <img
                            className="img-fluid"
                            src={require("../../assets/images/User.jpg")}
                            alt="Profile"
                          />
                        </div>
                        <div className="main-chat">
                          <div className="sub-message message-main mt-0">
                            <span>{"It's argenty"}</span>
                          </div>
                        </div>
                      </div>
                      <p className="f-w-400">{"7:28 PM"}</p>
                    </div>
                    <div className="media right-side-chat">
                      <div className="media-body text-right">
                        <div className="message-main pull-right">
                          <span
                            className="loader-span mb-0 text-left"
                            id="wave"
                          >
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control"
                        id="mail"
                        type="text"
                        placeholder="Type Your Message..."
                        name="text"
                      />
                      <div className="send-msg">
                        <Send />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default Default;
