import React, { Fragment } from "react"
import Breadcrumb from "../../../../layout/breadcrumb"
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button } from "reactstrap"
import { fields } from "./fields"
import { RequiredField } from "../../../utils/fieldRequired"
import ErrorMessage from "../../../utils/errorMessage"
import { Update } from "../../../../constant"
import { Formik } from 'formik';
import { validationSchema } from "./validationScheme"
import { useDispatch } from "react-redux"
import { branchById, updateBranch } from "../../../../redux/branches/branchActions"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import { Loader } from "../../../utils/Loader"
export function EditBranch() {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true)
    const [initialValues, setInitialValues] = React.useState({
        branchName: '',
        address: '',
        branchEmail: '',
        branchPhone: '',
        studentsCount: '',
        teachersCount: ''
    })
    const { id } = useParams()
    const dispatch = useDispatch()
    React.useEffect(() => {
        fetchDetails()

    }, [])
    const fetchDetails = () => {
        dispatch(branchById({ branchId: id }, (resp) => {
            let { email,
                name,
                address,
                phone,
                studentsCount,
                teachersCount } = resp.data.branch

            let data = {
                branchName: name,
                address,
                branchPhone: phone,
                branchEmail: email,
                studentsCount,
                teachersCount
            }
            setInitialValues(data)
            setIsLoading(false)
        }))
    }
    const onSubmit = (values, formActions) => {
        setIsLoading(true)
        let _values = { ...values }
        _values.branchId = id
        dispatch(updateBranch(_values, (resp) => {
            if (resp.status) {
                formActions.setSubmitting(false);
                setIsSubmitting(false)
                toast.success("Branch updated successfully!")
                setInitialValues(_values)
            }
            else {
                formActions.setSubmitting(false);
                setIsSubmitting(false)
                toast.error(resp.message)
            }
            setIsLoading(false)

        }))

    };
    return <Fragment>

        <Breadcrumb parent="dashboard" title={"Edit Branch"
        } />

        <Container fluid={true}>
            <Card>
                {isLoading ? <><Loader /></>
                    :
                    <CardBody>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                                <Form className="p-3" onSubmit={handleSubmit}>
                                    <Row>
                                        {fields.map(({ name, label, type, placeholder, required }, key) => <Col md="6" sm="6" xs='12'>
                                            <FormGroup key={key}>
                                                <Label for={name}>
                                                    {label} {required ? <RequiredField /> : ""}
                                                </Label>
                                                <Input
                                                    name={name}
                                                    onChange={handleChange}
                                                    type={type}
                                                    placeholder={placeholder
                                                    }
                                                    value={values[name]}
                                                />
                                                {errors[name] && touched[name] && (
                                                    <ErrorMessage message={errors[name]} />
                                                )}
                                            </FormGroup>
                                        </Col>)}
                                    </Row>
                                    <div className="text-right">
                                        <Button color="primary" className="mr-1" type='submit' disabled={isSubmitting}>{Update}</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </CardBody>
                }

            </Card>
        </Container>
    </Fragment>
}