import React, { Fragment, useContext } from "react";
import { TABLE_COMPONENT_CONTEXT } from '../../../../../../contexts/schoolRequests';
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { PagesArray } from '../../../../../../constant';

export function Filters() {
    const { paginationConfig, setPaginationConfig, tableColumns } = useContext(TABLE_COMPONENT_CONTEXT)
    let handleSortBy = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["pageNumber"] = 1
        temp["sortDirection"] = input.value
        setPaginationConfig(temp)
    }
    let handleRecords = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["pageSize"] = input.value
        setPaginationConfig(temp)
    }
    let handleStatus = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["pageNumber"] = 1
        temp["status"] = input.value
        setPaginationConfig(temp)
    }
    let handleSort = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["sortBy"] = input.value
        setPaginationConfig(temp)

    }
    return <Fragment>
        <Row>
            <Col md="4">
                <FormGroup>
                    <Label for="exampleSelect">
                        Sort
                    </Label>
                    <div className="d-flex align-items-center">
                        <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            size={"sm"}
                            onChange={handleSort}
                        >
                            {tableColumns.map((item, index) => <option key={index} value={item.selector} selected={paginationConfig.sortBy === item.selector}>
                                {item.name}
                            </option>)}


                        </Input>
                        <div className="px-1">By</div>
                        <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            size={"sm"}
                            onChange={handleSortBy}
                        >
                            <option value="ASC" selected={paginationConfig.sortDirection === "ASC"}>
                                Ascending
                            </option>
                            <option value="DESC" selected={paginationConfig.sortDirection === "DESC"}>
                                Descending
                            </option>

                        </Input>
                    </div>
                </FormGroup>

            </Col>
            <Col md="2">
                <FormGroup>
                    <Label for="exampleSelect">
                        Show Records
                    </Label>
                    <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        size={"sm"}
                        onChange={handleRecords}
                    >
                        {PagesArray.map((value, key) => <option value={value} selected={paginationConfig.pageNumber === value}>
                            {value}
                        </option>)}


                    </Input>
                </FormGroup>
            </Col>


        </Row>
    </Fragment>
}