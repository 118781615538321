import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Form, Row, FormGroup, Input, Label, Col, Button } from "reactstrap"
import { RequiredField } from "../../../utils/fieldRequired";
import { Submit } from "../../../../constant";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../redux/apiCall"
import { create_role, edit_role } from "../../../../constant/url";
import { toast } from "react-toastify";
import { useEffect } from "react";
export function CreateRoleModal({ open, setOpen, selected, callback }) {
    const [role_name, setRoleName] = React.useState("")
    const saloon = JSON.parse(localStorage.getItem("saloon"))


    const dispatch = useDispatch()
    useEffect(() => {
        if (selected) {
            setRoleName(selected.name)
        }
    }, [selected])

    const onSubmit = (e) => {
        e.preventDefault()
        let data = selected ? { record_id: selected.id, name: role_name } : { saloon_id: saloon.id, name: role_name }
        let url = selected ? edit_role : create_role
        dispatch(apiCall(url, 'POST', data, (resp) => {
            if (resp.status) {
                toast.dark(`Role ${selected ? "edited" : "created"} successfully!`)
                setOpen(!open)
                callback()
            }
            else {
                toast.error(resp.message)
            }

        }))

    };
    return <Modal isOpen={open} toggle={setOpen} size="md">
        <ModalHeader toggle={setOpen}>{selected ? "Edit" : "Add"} Role</ModalHeader>
        <ModalBody>
            <Form className="p-3" onSubmit={onSubmit}>
                <Row>
                    <Col xs='12'>
                        <FormGroup >
                            <Label for={"client_name"}>
                                Name <RequiredField />
                            </Label>
                            <Input

                                onChange={e => setRoleName(e.target.value)}
                                type={"text"}
                                size={"sm"}
                                value={role_name}
                            />

                        </FormGroup>
                    </Col>
                </Row>
                <div className="text-right">
                    <Button color="primary" className="mr-1" type='submit' >{Submit}</Button>
                </div>
            </Form>
        </ModalBody>
    </Modal>
}