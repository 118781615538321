import React, { Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { CreateSaloon } from "./Create Saloon/CreateSaloon";
import { useDispatch } from "react-redux";
import { get_saloon_qna, get_saloon_by_id } from "../../../constant/url";
import { apiCall } from "../../../redux/apiCall";
import { toast } from "react-toastify";
export function Saloon() {
  const dispatch = useDispatch();
  const [saloonData, setSaloonData] = React.useState(null);
  const [qna, setQNA] = React.useState([]);
  React.useEffect(() => {
    fetchQNA();
  }, []);
  const fetchQNA = () =>
    dispatch(
      apiCall(get_saloon_qna, "GET", {}, (resp) => {
        setQNA(resp.data);
      })
    );
  //   const fetchSaloonDetails = (id) =>
  //     dispatch(
  //       apiCall(get_saloon_by_id, "POST", { id }, (resp) =>
  //         setSaloonData(resp.data.saloon)
  //       )
  //     );
  return (
    <Fragment>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div
          class="collapse navbar-collapse d-flex justify-content-end w-100"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a
                class="nav-link"
                href="#"
                onClick={() => {
                  localStorage.clear();
                  toast.dark("Logging out...");

                  setTimeout(() => {
                    window.location = "/";
                  }, 1500);
                }}
              >
                Log out
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <Container fluid={true}>
        <Row className="align-items-center" style={{ height: "100vh" }}>
          <Col sm="10" className="m-auto">
            <Card>
              <CardBody className="pt-0">
                <h3 className="text-center text-dark my-4">
                  Add a salon to get Started!
                </h3>
                <CreateSaloon qna={qna} setSaloonData={setSaloonData} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
