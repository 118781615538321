import React, { useContext } from "react";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { RequiredField } from "../../../../../utils/fieldRequired";
import { STUDENT_DETAIL } from "../../../../../../contexts";
import { Formik } from "formik";
import { addresValidation } from "./validationSchemas";
import ErrorMessage from "../../../../../utils/errorMessage";
import {
  student_address_add,
  student_address_update,
} from "../../../../../../redux/endpoints";
import { apiCall } from "../../../../../../redux/apiCall";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
export function AddressInformation() {
  const dispatch = useDispatch();
  const { studentDetail, setStudentDetail } = useContext(STUDENT_DETAIL);
  const { id } = useParams();
  const [initialValues, setInitialValues] = React.useState({
    country: "",
    state: "",
    city: "",
    zip: "",
  });
  const onSubmit = (values, formActions) => {
    dispatch(
      apiCall(
        student_address_add,
        "POST",
        { ...values, studentId: id },
        (resp) => {
          try {
            if (resp.status) {
              formActions.setSubmitting(false);
              formActions.resetForm();
              toast.success(
                `Student address ${
                  initialValues?.id ? "updated" : "added"
                } successfully!`
              );
              let _studentDetail = { ...studentDetail };
              _studentDetail.studentAddress = values;
              setStudentDetail(_studentDetail);
            } else {
              formActions.setSubmitting(false);

              toast.error(resp.message);
            }
          } catch (e) {}
        }
      )
    );
  };
  React.useEffect(() => {
    if (studentDetail?.studentAddress) {
      setInitialValues(studentDetail.studentAddress);
    }
  }, [studentDetail?.studentAddress]);
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={addresValidation}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"Grade"}>
                    City <RequiredField />
                  </Label>
                  <Input
                    name={"city"}
                    type={"text"}
                    size={"sm"}
                    defaultValue={initialValues.city}
                    onChange={handleChange}
                  ></Input>
                  {errors["city"] && touched["city"] && (
                    <ErrorMessage message={errors["city"]} />
                  )}
                </FormGroup>
              </Col>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"Grade"}>
                    State <RequiredField />
                  </Label>
                  <Input
                    name={"state"}
                    type={"text"}
                    size={"sm"}
                    defaultValue={initialValues.state}
                    onChange={handleChange}
                  ></Input>
                  {errors["state"] && touched["state"] && (
                    <ErrorMessage message={errors["state"]} />
                  )}
                </FormGroup>
              </Col>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"Grade"}>
                    Country <RequiredField />
                  </Label>
                  <Input
                    name={"country"}
                    type={"text"}
                    size={"sm"}
                    defaultValue={initialValues.country}
                    onChange={handleChange}
                  ></Input>
                  {errors["country"] && touched["country"] && (
                    <ErrorMessage message={errors["country"]} />
                  )}
                </FormGroup>
              </Col>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"Grade"}>
                    Zip Code <RequiredField />
                  </Label>
                  <Input
                    name={"zip"}
                    type={"text"}
                    size={"sm"}
                    defaultValue={initialValues.zip}
                    onChange={handleChange}
                  ></Input>
                  {errors["zip"] && touched["zip"] && (
                    <ErrorMessage message={errors["zip"]} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="text-right">
              <Button
                color="primary"
                className="mr-1"
                type="submit"
                disabled={isSubmitting}
              >
                {initialValues?.id ? "Update" : "Save"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
