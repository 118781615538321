import React, { Fragment } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
// import { fields } from "./fields"
import { RequiredField } from "../../../../utils/fieldRequired";
import ErrorMessage from "../../../../utils/errorMessage";
import { Submit } from "../../../../../constant";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../../redux/apiCall";
// import { createTeacher } from "../../../../../redux/branch-admin/teacherActions"
import { toast } from "react-toastify";
import {
  createClass,
  getGrades,
  getGradesLevelSections,
} from "../../../../../redux/branch-admin/class/classActions";
import { Link } from "react-router-dom";
import { getTeachers } from "../../../../../redux/branch-admin/teacherActions";
import {
  create_student,
  get_classes_paginated,
  get_students_metadata,
} from "../../../../../redux/endpoints";
// import { Wizard, useWizard } from 'react-use-wizard';
// import { PersonalInformation } from "./PersonalInformation"
// import { BankDetails } from "./BankDetails"
// import { AccountsDetails } from "./AccountsDetails"
// const Step = ({ number }) => {
//     const { handleStep } = useWizard();

//     handleStep(() => {
//         alert(`Going to step ${number}`);
//     });

//     return <p>Step {number}</p>;
// };

// const Footer = () => {
//     const {
//         nextStep,
//         previousStep,
//         isLoading,
//         activeStep,
//         stepCount,
//         isLastStep,
//         isFirstStep,
//     } = useWizard();

//     return (
//         <code>
//             <div style={{ display: 'flex', gap: '1rem' }}>
//                 <p>Has previous step: {!isFirstStep ? '✅' : '⛔'}</p>
//                 <br />
//                 <p>Has next step: {!isLastStep ? '✅' : '⛔'} </p>
//                 <br />
//                 <p>
//                     Active step: {activeStep + 1} <br />
//                 </p>
//                 <br />
//                 <p>
//                     Total steps: {stepCount} <br />
//                 </p>
//             </div>
//             <div>
//                 <button
//                     onClick={() => previousStep()}
//                     disabled={isLoading || isFirstStep}
//                 >
//                     Previous
//                 </button>
//                 <button onClick={() => nextStep()} disabled={isLoading || isLastStep}>
//                     Next
//                 </button>
//             </div>
//         </code>
//     );
// };

export function CreateStudent() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [meta_data, setMetaData] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [classes, setClasses] = React.useState([]);
  const [paginationConfig, setPaginationConfig] = React.useState({
    pageNumber: 1,
    pageSize: 5,
    sortBy: "id",
    sortDirection: "DESC",
  });
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    genderId: "",
    classId: "",
  };
  React.useEffect(() => {
    fetchMetaData();
  }, []);
  React.useEffect(() => {
    fetchClasses();
  }, [paginationConfig]);
  const fetchClasses = () => {
    dispatch(
      apiCall(get_classes_paginated, "POST", paginationConfig, (resp) => {
        let _classes = [...classes, ...resp.data.records];
        setHasMore(paginationConfig.pageNumber < resp.data.pagination.numPages);
        setClasses(_classes);
      })
    );
  };
  const fetchMetaData = () => {
    dispatch(
      apiCall(get_students_metadata, "GET", {}, (resp) => {
        setMetaData(resp.data);
      })
    );
  };

  const onSubmit = (values, formActions) => {
    dispatch(
      apiCall(create_student, "POST", values, (resp) => {
        try {
          if (resp.status) {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            formActions.resetForm();
            toast.success("Student created successfully!");
          } else {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            toast.error(resp.message);
          }
        } catch (e) {}
      })
    );
  };
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && hasMore) {
      let _paginationConfig = { ...paginationConfig };
      _paginationConfig["pageNumber"] = _paginationConfig["pageNumber"] + 1;
      setPaginationConfig(_paginationConfig);
    }
  };
  return (
    <>
      <Fragment>
        <Breadcrumb parent="Dashboard" title={"Create Student"} />

        <Container fluid={true}>
          <Card>
            <CardBody>
              {/* <Wizard footer={<Footer />}> */}
              {/* <PersonalInformation /> */}
              {/* <BankDetails />
                            <AccountsDetails />
                        </Wizard> */}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form className="p-3" onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6" sm="6" xs="12">
                        <FormGroup>
                          <Label for={"firstName"}>
                            First Name <RequiredField />
                          </Label>
                          <Input
                            name={"firstName"}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            type={"text"}
                            size={"sm"}
                          />
                        </FormGroup>
                        {errors["firstName"] && touched["firstName"] && (
                          <ErrorMessage message={errors["firstName"]} />
                        )}
                      </Col>
                      <Col md="6" sm="6" xs="12">
                        <FormGroup>
                          <Label for={"lastName"}>
                            Last Name <RequiredField />
                          </Label>
                          <Input
                            name={"lastName"}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            type={"text"}
                            size={"sm"}
                          />
                          {errors["lastName"] && touched["lastName"] && (
                            <ErrorMessage message={errors["lastName"]} />
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="6" xs="12">
                        <FormGroup>
                          <Label for={"email"}>
                            Email <RequiredField />
                          </Label>
                          <Input
                            name={"email"}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            type={"text"}
                            size={"sm"}
                          />
                        </FormGroup>
                        {errors["email"] && touched["email"] && (
                          <ErrorMessage message={errors["email"]} />
                        )}
                      </Col>
                      <Col md="6" sm="6" xs="12">
                        <FormGroup>
                          <Label for={"classId"}>
                            Class <RequiredField />
                          </Label>
                          <Input
                            name={"classId"}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onScroll={handleScroll}
                            type={"select"}
                            size={"sm"}
                          >
                            <option value={false}>Select class</option>
                            {classes.map(({ id, title }, index) => (
                              <option key={index} value={id}>
                                {title}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                        {errors["classId"] && touched["classId"] && (
                          <ErrorMessage message={errors["classId"]} />
                        )}
                      </Col>
                      <Col md="6" sm="6" xs="12">
                        <FormGroup>
                          <Label for={"genderId"}>
                            Gender <RequiredField />
                          </Label>
                          <Input
                            name={"genderId"}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            type={"select"}
                            size={"sm"}
                          >
                            <option value={false}>Select gender</option>
                            {meta_data?.genders?.map(({ id, title }, index) => (
                              <option key={index} value={id}>
                                {title}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                        {errors["genderId"] && touched["genderId"] && (
                          <ErrorMessage message={errors["genderId"]} />
                        )}
                      </Col>
                    </Row>
                    <div className="text-right">
                      <Button
                        color="primary"
                        className="mr-1"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {Submit}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    </>
  );
}
