import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, CardBody, CardFooter, Spinner, Card, CardHeader } from 'reactstrap';
import { FormInput } from '../forms/formBuilder';
import { useHistory } from 'react-router-dom';
import CellField from '../forms/cellField';
import { useEffect } from 'react';

const ClientEditModal = (props) => {
    const detail= props.clientDetails
    const gender = [
        { id: 'Male', title: "Male" },
        { id: 'Female', title: "Female" },
        { id: 'Other', title: "Other" },
    ]
    const [buyingIntents, setBuyingIntents] = useState([]);
    const [clientRatings, setClientRating] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [country, setCountry] = useState([]);
    const [cities, setCity] = useState([]);
    const [localities, setLocatlity] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [clientTypes, setClientTypes] = useState([]);
    const [load, setLoad] = useState(false);
    useEffect(() => {
        const endpoint = localStorage.getItem('api-endpoint');
        const token = localStorage.getItem('token')
        fetch(endpoint + "clients/get-settings/client-add-form-data", { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => res.json())
            .then(
                (result) => {
                    setClientTypes(result.package.clientTypes);
                    // setTypes(result.package.types);
                    setBuyingIntents(result.package.buyingIntent);
                    setClientRating(result.package.rating);
                    setIndustries(result.package.industries);
                    setCountry(result.package.country);
                    setCity(result.package.cities);
                    setTeamMembers(result.package.teamMembers);
                    setLoad(true);

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    //   setIsLoaded(true);
                    //   setError(error);
                    toast.error(error)

                }
            )

            const requestOptions = {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "parentId":detail.locationIdData.parentLocationData.id })
            };
            fetch(endpoint + "get-location", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        setLocatlity(result.package.data);
                    },
                    (error) => {
                        toast.error(error)
                    }
                )   
    }, [])


    const handleCityChange = (location) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "parentId": location.value })
        };
        fetch(endpoint + "get-location", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    setLocatlity(result.package.data);
                },
                (error) => {
                    toast.error(error)
                }
            )
    }
  
    const endpoint = localStorage.getItem('api-endpoint');
    const token = localStorage.getItem('token');
    const history = useHistory();
  

    const onUpdate = data => {
      
        const name = document.querySelector('#name').value
        const email = document.querySelector('#email').value
        const gender = document.querySelector('#gender').value
        const phone = document.querySelector('#phone').value
        const cells = document.querySelector('#cells').value
        const address = document.querySelector('#address').value
        const zip = document.querySelector('#zip').value
        const budget = document.querySelector('#budget').value
        const cnic = document.querySelector('#cnic').value
        const occupation = document.querySelector('#clientOccupation').value
        const maxIncome = document.querySelector('#maxIncome').value
        const minIncome = document.querySelector('#minIncome').value
        const passport = document.querySelector('#passport').value
        const locationId = document.querySelector('#locality').value
        const clientRatingId = document.querySelector('#clientRatingId').value
        const clientTypeId = document.querySelector('#clientTypeId').value
        const industryId = document.querySelector('#clientIndustryId').value
        const buyingIntentId = document.querySelector('#buyingIntentId').value
        const assignedTo = document.querySelector('#assignedTo').value
        const reason = document.querySelector('#reason').value
        const clientId = document.querySelector('#clientId').value


        const formData = {
            clientId,name,email,gender,phone,cells,address,zip,budget,cnic,occupation,maxIncome,minIncome,passport,locationId,clientRatingId,clientTypeId,industryId,buyingIntentId,assignedTo,reason
        }
 

        const requestOptions = {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        };
        fetch(endpoint + "clients", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.status===true){
                        toast.success(result.message)
                        props.clientEditModalToggle()
                        history.push("/clients/details/"+clientId);

                    }else{
                        let erros=result.payload
                        erros.map((element)=>(
                            toast.error(element.msg)
                        ))

                    }
                },
                (error) => {
                    toast.error(error)
                }
            )



    };

 

    const onToggle = data => {
        props.clientEditModalToggle()
    };

    return (
        <Fragment>

            <Modal isOpen={props.clientEditModal} toggle={onToggle} centered>
                {
                    (load)?
                    <Form className="needs-validation" noValidate="" onSubmit={onUpdate}>
                        <FormInput type="hidden"  value={detail.id}  name="clientId" />

                        <CardHeader>
                            Update Client
                        </CardHeader>

                    <CardBody>
                        <div className="form-row">
                            <Col md="6 mb-3">
                                <FormInput label="Name *" name="name" placeholder="Name" required={0} value={detail.name}/>
                            </Col>
                            <Col md="6 mb-3">
                                <FormInput label="Email *" type="email" name="email" placeholder="Email" required={0} value={detail.email} />
                            </Col>
                            <Col md="6 mb-3">
                                <FormInput label="Gender *" type="select" name="gender" required={0} options={gender} value={detail.gender} />
                            </Col>
                            <Col md="6 mb-3">
                                <FormInput value={detail.address} label="Address" name="address" placeholder="Address" />
                            </Col>
                            <Col md="6 mb-3">
                                <FormInput value={detail.assignedToData.id} label="Assigned To *" type="select" name="assignedTo" required={0} options={teamMembers} />
                            </Col>
                            <Col md="6 mb-3">
                                <FormInput value={detail.cnic} label="CNIC *" type="number" name="cnic" required={0} placeholder="Cnic" />
                            </Col>
                            <Col md="6 mb-3">
                                <FormInput value={detail.passport}  label="Passport" type="number" name="passport" required={0} placeholder="Passport" />
                            </Col>
                            <Col md="6 mb-3">
                                <FormInput value={detail.phone} label="Landline Number:" name="phone" type="number" placeholder="Landline Number" />
                            </Col>

                            <CellField cells={detail.clientCellsData}/>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <div className='form-row'>

                            <Col md="4 mb-3">
                                <FormInput label="Country *" type="select" name="country" options={country} selectedId={1} />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={detail.locationIdData.parentLocationData.id} label="City *" type="select" name="city" options={cities} onchange={value => handleCityChange(value)} />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={detail.locationIdData.id}  required={0} label="Locality" type="select" name="locality" options={localities} />
                            </Col>
                        </div>
                    </CardFooter>
                    <CardFooter>
                        <div className='form-row'>
                            <Col md="4 mb-3">
                                <FormInput value={detail.clientTypeData.id} label="Client Type" type="select" name="clientTypeId" required={0} options={clientTypes} />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={detail.clientRatingData.id} label="Client Rating" type="select" name="clientRatingId" required={0} options={clientRatings} />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={detail.clientTypeData.id} label="Industry" type="select" name="clientIndustryId" options={industries} />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={(detail.clientBuyingIntentData) ? detail.clientBuyingIntentData.id : '' } label="Buying Intent" type="select" name="buyingIntentId" options={buyingIntents} />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={detail.budget} label="Budget" type="number" name="budget" required={0} placeholder="Budget" />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={detail.zip} label="ZIP" type="number" name="zip" required={0} placeholder="Zip" />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={detail.occupation} label="Client Occupation" name="clientOccupation" placeholder="Client Occupation" />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={detail.maxIncome} label="Max Income" name="maxIncome" placeholder="Max Income" />
                            </Col>
                            <Col md="4 mb-3">
                                <FormInput value={detail.minIncome} label="Min Income" name="minIncome" placeholder="Min Income" />
                            </Col>
                            <Col md="12 mb-3">
                                <FormInput type='textarea'  label="Reason" name="reason" placeholder="Change Reason" />
                            </Col>
                        </div>

                    </CardFooter>
                    
                    <CardFooter>
                        <Button color="primary" onClick={onUpdate}>{"Update Client"}</Button>
                    </CardFooter>

                </Form>
                :
                <Card>
                   <CardBody className='text-center'>
                   <Spinner/> 
                   </CardBody>
                </Card>
                }
            </Modal>

        </Fragment>
    );
};

export default ClientEditModal;