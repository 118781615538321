import React from 'react'
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button, Table, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"

export function EditTariffOptionModal({ open, setOpen, setTariffTypeToEdit, tariffTypeToEdit, proceedEditingTariff }) {
    return <Modal isOpen={open} toggle={setOpen} size="md">
        <ModalHeader toggle={setOpen}>{"Edit Tariff"}</ModalHeader>
        <ModalBody>
            <FormGroup tag="fieldset">
                <h5 className='ftw-500'>
                    Select a tariff to continue
                </h5>
                <FormGroup check>
                    <Input
                        name="registration"
                        type="radio"
                        checked={tariffTypeToEdit === "registration"}
                        onChange={() => setTariffTypeToEdit('registration')}
                    />
                    {' '}
                    <Label check>
                        Registration
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Input
                        name="promotion"
                        type="radio"
                        checked={tariffTypeToEdit === "promotion"}
                        onChange={() => setTariffTypeToEdit('promotion')}

                    />
                    {' '}
                    <Label check>
                        Promotion
                    </Label>
                </FormGroup>

            </FormGroup>

        </ModalBody>
        <ModalFooter className="justify-content-between">
            <Button color='primary' size='sm' onClick={() => {
                setTariffTypeToEdit('')
                setOpen()
            }}>Close</Button>
            <Button color='secondary' size='sm' disabled={!tariffTypeToEdit.length} onClick={() => proceedEditingTariff()}>Proceed</Button>
        </ModalFooter>
    </Modal>
}