import React from "react";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { RequiredField } from "../../../../utils/fieldRequired";
import ErrorMessage from "../../../../utils/errorMessage";
import { Formik } from "formik";
import { TariffItem as validationSchema } from "../create-tariff/validationSchema";
import { apiCall } from "../../../../../redux/apiCall";
import { create_tariff_item, get_item } from "../../../../../redux/endpoints";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
export function CreateTariffItemModal({
  open,
  setOpen,
  selectedTariffs,
  tariffTypeToEdit,
  createTariffCallback,
}) {
  const { i } = useParams();
  const dispatch = useDispatch();
  const [items, setItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [initialValues, setInitialValues] = React.useState({
    itemId: "",
    amount: "",
    scheduleType: "",
    scheduleDate: "",
  });
  React.useEffect(() => {
    fetchItems();
  }, []);
  const fetchItems = () => {
    dispatch(
      apiCall(get_item, "GET", {}, (resp) => {
        setItems(resp.data);
      })
    );
  };
  const handleItemSelect = (e) => {
    const value = e.target.value;
    const selectedItem = items.find((x) => parseInt(x.id) === parseInt(value));
    if (selectedItem.amountType === "fixed") {
      let _initialValues = { ...initialValues };
      _initialValues["amount"] = selectedItem.amount;
      setInitialValues(_initialValues);
    }
    setSelectedItem(selectedItem);
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    let _initialValues = { ...initialValues };
    let _errors = { ...errors };
    _errors[name] = null;
    _initialValues[name] = value;
    setErrors(_errors);
    setInitialValues(_initialValues);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let _initialValues = { ...initialValues };
    _initialValues.itemId = document.getElementById("itemId").value;

    let error = checkErrors(_initialValues);
    if (Object.keys(error).length) {
      return;
    }
    let tariff_id = selectedTariffs[tariffTypeToEdit]?.details?.id;
    dispatch(
      apiCall(
        create_tariff_item,
        "POST",
        { ..._initialValues, tariffId: tariff_id },
        (resp) => {
          if (resp.status) {
            setOpen();
            toast.success("Tariff added successfully");
            createTariffCallback(resp.data);
          } else {
            toast.error(resp.message);
          }
        }
      )
    );
  };
  const checkErrors = (_initialValues) => {
    const { itemId, amount, scheduleType, scheduleDate } = _initialValues;

    const _errors = {};
    if (!itemId || !itemId.length) {
      errors["itemId"] = "Please select an item";
    }
    if (!amount || !amount.toString().length) {
      errors["amount"] = "Please enter an amount";
    }
    if (!scheduleType || !scheduleType.length) {
      errors["scheduleType"] = "Please select a schedule type";
    }
    if (!scheduleDate || !scheduleDate.length) {
      errors["scheduleDate"] = "Please select a schedule date";
    }
    return _errors;
  };
  return (
    <Modal isOpen={open} toggle={setOpen} size="lg">
      <ModalHeader toggle={setOpen}>{"Add Item"}</ModalHeader>
      <ModalBody>
        {/* <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            > */}
        {/* {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => ( */}
        <Form className="p-3" onSubmit={onSubmit}>
          <Row>
            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"itemId"}>
                  Item <RequiredField />
                </Label>
                <Input
                  name={"itemId"}
                  onChange={(e) => {
                    handleChange(e);
                    handleItemSelect(e);
                  }}
                  type={"select"}
                  id="itemId"
                  size={"sm"}
                >
                  <option value="">Select value</option>
                  {items.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </Input>
                {errors["itemId"] && (
                  <ErrorMessage message={errors["itemId"]} />
                )}
              </FormGroup>
            </Col>
            {selectedItem?.amountType === "fixed" ? (
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"amount"}>
                    Amount <RequiredField />
                  </Label>
                  <Input
                    name={"amount"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type={"number"}
                    disabled={selectedItem?.amountType === "fixed"}
                    value={initialValues?.amount}
                    size={"sm"}
                  />
                  {errors["amount"] && (
                    <ErrorMessage message={errors["amount"]} />
                  )}
                </FormGroup>
              </Col>
            ) : (
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"amount"}>
                    Amount <RequiredField />
                  </Label>
                  <Input
                    name={"amount"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type={"number"}
                    disabled={selectedItem?.amountType === "fixed"}
                    value={initialValues?.amount}
                    size={"sm"}
                  />
                  {errors["amount"] && (
                    <ErrorMessage message={errors["amount"]} />
                  )}
                </FormGroup>
              </Col>
            )}
            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"scheduleType"}>
                  Item Type <RequiredField />
                </Label>
                <Input
                  name={"scheduleType"}
                  type={"select"}
                  size={"sm"}
                  onChange={handleChange}
                >
                  <option value={false}>Select Item Type</option>
                  <option value={"monthly"}>Monthly</option>
                  <option value={"schedule"}>Schedule</option>
                </Input>
                {errors["scheduleType"] && (
                  <ErrorMessage message={errors["scheduleType"]} />
                )}
              </FormGroup>
            </Col>

            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"scheduleDate"}>
                  Date <RequiredField />
                </Label>
                <Input
                  name={"scheduleDate"}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  type={"date"}
                  size={"sm"}
                />
                {errors["scheduleDate"] && (
                  <ErrorMessage message={errors["scheduleDate"]} />
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="text-right">
            <Button color="primary" className="mr-1" type="submit">
              {"Add Item"}
            </Button>
          </div>
        </Form>
        {/* )}
            </Formik> */}
      </ModalBody>
    </Modal>
  );
}
