import React, { Fragment, useState } from 'react';
import { Card, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Input, FormGroup, Label, Button, Form, Container } from 'reactstrap';
import { RequiredField } from '../../../utils/fieldRequired';

const Tabset = () => {
    const [activeTab, setActiveTab] = useState('1');
    return (
        <Fragment>
            <Card>
                <Row className="product-page-main m-0">
                    <Col sm="12">
                        <Nav tabs className="border-tab">
                            {/* <NavItem id="myTab" role="tablist">
                                <NavLink href="#" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                    {"Booking"}
                                </NavLink>
                                <div className="material-border"></div>
                            </NavItem> */}
                            <NavItem id="myTab" role="tablist">
                                <NavLink href="#" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                    {"Reviews"}
                                </NavLink>
                                <div className="material-border"></div>
                            </NavItem>

                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {/* <TabPane tabId="1">
                                <Container>
                                    <Row>
                                        <Col md="8" className="m-auto">
                                            <Form className="p-3" >
                                                <Row>
                                                    <Col md="6" sm="6" xs='12'>
                                                        <FormGroup >
                                                            <Label for={"client_name"}>
                                                                Name <RequiredField />
                                                            </Label>
                                                            <Input
                                                                name={"client_name"}
                                                                type={"text"}
                                                                size={"sm"}

                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6" sm="6" xs='12'>
                                                        <FormGroup >
                                                            <Label for={"client_email"}>
                                                                Email <RequiredField />
                                                            </Label>
                                                            <Input
                                                                name={"client_email"}
                                                                type={"text"}
                                                                size={"sm"}

                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6" sm="6" xs='12'>
                                                        <FormGroup >
                                                            <Label for={"appointment_type_id"}>
                                                                Appointment type <RequiredField />
                                                            </Label>
                                                            <Input
                                                                name={"appointment_type_id"}
                                                                type={"text"}
                                                                size={"sm"}
                                                            >

                                                            </Input>

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6" sm="6" xs='12'>
                                                        <FormGroup >
                                                            <Label for={"name"}>
                                                                Branch <RequiredField />
                                                            </Label>
                                                            <Input
                                                                name={"branch_id"}
                                                                type={"text"}
                                                                size={"sm"}
                                                            >
                                                            </Input>

                                                        </FormGroup>
                                                    </Col>



                                                    <Col md="6" sm="6" xs='12'>
                                                        <FormGroup >
                                                            <Label for={"name"}>
                                                                Required Services <RequiredField />
                                                            </Label>
                                                            <Input
                                                                name={"appointment_time"}
                                                                type={"text"}
                                                                size={"sm"}



                                                            />

                                                        </FormGroup>
                                                    </Col>


                                                    <Col md="6" sm="6" xs='12'>
                                                        <FormGroup >
                                                            <Label for={"appointment_time"}>
                                                                Date <RequiredField />
                                                            </Label>
                                                            <Input
                                                                name={"appointment_time"}
                                                                type={"datetime-local"}
                                                                size={"sm"}

                                                            />

                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <div className="text-right">
                                                    <Button color="primary" className="mr-1" type='submit'>{"Book"}</Button>
                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane> */}
                            <TabPane tabId="1">
                                <p className="mb-0 m-t-20">{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"}</p>
                            </TabPane>

                        </TabContent>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
}

export default Tabset;