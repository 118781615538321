import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import {
  Password,
  SignIn,
  EmailAddress,
  CreateAccount,
  YourName,
  PrivacyPolicy,
  RecordAdded,
  RequestError,
  APIUrl,
  SuperAdminAPIUrl,
  PositiveNumberRegex,
} from "../../../constant";
import { toast } from "react-toastify";
import { Twitter, Facebook, GitHub } from "react-feather";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerSaloon } from "../../../redux/auth/authActions";
import { useHistory } from "react-router-dom";
const SchoolRegister = (props) => {
  const history = useHistory();
  const [togglePassword, setTogglePassword] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };
  const initialState = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  };
  const [formData, setFormData] = useState(initialState);

  const onSubmit = async (e) => {
    dispatch(
      registerSaloon(formData, (resp) => {
        if (resp.status == 200) {
          localStorage.setItem("resendEmail", formData.email);
          toast.success(RecordAdded);
          setFormData(initialState);
          setTimeout(() => {
            history.push("/resend-email");
          }, 3000);
        } else {
          if (resp.errors) {
            let errors = Object.keys(resp.errors);
            for (let error of errors) {
              resp.errors[error].map((err) => toast.error(err));
            }
          } else {
            toast.error(resp.message);
          }
        }
      })
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // validate={(val) => new validator(val).isRequired().isEmail().error}
  };

  const validator = (val) => {
    this.error = [];
    this.val = val;
    this.isRequired = function () {
      if (!this.val) {
        this.error.push("This field is required");
      }
      return this;
    };
    this.isEmail = function () {
      const filter =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.val && !filter.test(this.val)) {
        this.error.push("Invalid Email");
      }
      return this;
    };
    return this;
  };

  const redirectLogin = () => {
    // alert()
    return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="#javascript">
                  <img
                    style={{ height: "65px" }}
                    className="img-fluid for-light"
                    src={require("../../../assets/images/logo/login.png")}
                    alt="looginpage"
                  />
                  <img
                    className="img-fluid for-dark"
                    src={require("../../../assets/images/logo/logo_dark.png")}
                    alt="looginpage"
                  />
                </a>
              </div>
              <div className="login-main">
                {" "}
                {/* style={{width: '700px'}} */}
                <Form
                  className="theme-form needs-validation"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h4>{"Create your salon account"}</h4>
                  <p>{"Enter your details to create account"}</p>

                  <FormGroup>
                    <Label className="col-form-label pt-0">{"Name"}</Label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      innerRef={register({ required: true })}
                      value={formData.schoolName}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors.schoolName && "This Field is required"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Test@gmail.com"
                      innerRef={register({
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors.email && "Please enter proper email address"}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={(e) => handleChange(e)}
                      placeholder="*********"
                    />
                    <div
                      className="show-hide"
                      onClick={() => HideShowPassword(togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                    <small>
                      The password must include at least one uppercase and one
                      lowercase letter, as well as one symbol.
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      {"Confirm Password"}
                    </Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      name="password_confirmation"
                      value={formData.password_confirmation}
                      onChange={(e) => handleChange(e)}
                      placeholder="*********"
                    />
                    <div
                      className="show-hide"
                      onClick={() => HideShowPassword(togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>

                  <br></br>
                  <div className="form-group mb-0">
                    {/* innerRef={register({ required: true  })} <span style={{ color: "red" }}>{errors.TermsCheckbox && 'Please enter correct no.'}</span> */}
                    <Button color="primary" className="btn-block" type="submit">
                      {CreateAccount}
                    </Button>
                  </div>

                  <p className="mt-4 mb-0">
                    {"Already have an account?"}

                    <a className="ml-2" href="/" onClick={redirectLogin}>
                      {SignIn}
                    </a>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SchoolRegister;
