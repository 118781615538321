export const fields = [{
    name: "name",
    type: "text",
    label: "Name",
    placeholder: "Branch name",
    required: true,
}, {
    name: "address",
    type: 'text',
    label: "Address",
    placeholder: "Branch address",
    required: true,
}, {
    name: 'employee_count',
    type: "number",
    label: "Employee Count",
    placeholder: "eg; 15",
    required: true,
},]