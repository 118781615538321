import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
// import BranchData from './branch-data/BranchData';
import { getBranches } from "../../../../redux/branches/branchActions";
import { TABLE_COMPONENT_CONTEXT } from "../../../../contexts/schoolRequests";
// import { BranchFilters } from './branch-data/BranchFilters';
import { apiCall } from "../../../../redux/apiCall";
import { get_branches, get_categories } from "../../../../constant/url";
import CategoryData from "./CategoryData";

export function Categories() {
  const type = "Categories";
  const [requestId, setRequestId] = useState(0);
  const [branches, setBranch] = useState({
    total_records: 0,
    data: [],
  });
  const [Categories, setCategories] = useState([]);
  const saloon = localStorage.getItem("saloon")
    ? JSON.parse(localStorage.getItem("saloon"))
    : null;
  const dispatch = useDispatch();
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    pageSize: 5,
    sortBy: "id",
    sortDirection: "DESC",
    status: "pending",
    hideOnNoData: 0,
  });

  const fetchCategories = () => {
    dispatch(
      apiCall(get_categories, "GET", {}, (resp) => {
        setCategories(resp.data);
      })
    );
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const handlePagination = (paginationData) => {
    let temp = { ...paginationConfig };
    temp["pageNumber"] = paginationData.current;
    setPaginationConfig(temp);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    // setSearchReq(searchReq)
  };
  const handleActionId = (id) => {
    // dispatch(schoolRequestDetails({ "requestId": id }, (resp) => {
    //     setRequestId(resp.data.details.id);
    //     addToggle();
    // }))
  };

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title={"Categories"} />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="pt-0">
                <Row className="p-3">
                  <Col sm="12">
                    <TABLE_COMPONENT_CONTEXT.Provider
                      value={{
                        paginationConfig,
                        handlePagination,
                        setPaginationConfig,
                      }}
                    >
                      {/* <BranchFilters /> */}
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <div>
                          <h6>Total Records : {Categories.length}</h6>
                        </div>
                        <Link to="/category/create" className="btn btn-dark">
                          Add Category
                        </Link>
                      </div>
                      <CategoryData
                        loading={false}
                        type={type}
                        paginationConfig={paginationConfig}
                        data={branches}
                        onClickSort={(value) => handleSorting(value)}
                        onClickPagination={(value) => handlePagination(value)}
                        onClickAction={(e) => handleActionId(e)}
                        setPaginationConfig={setPaginationConfig}
                        categories={Categories}
                      />
                    </TABLE_COMPONENT_CONTEXT.Provider>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
