import React, { Fragment } from "react"
import Breadcrumb from "../../../../../../layout/breadcrumb"
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button } from "reactstrap"
import { fields } from "./fields"
import { RequiredField } from "../../../../../utils/fieldRequired"
import ErrorMessage from "../../../../../utils/errorMessage"
import { Submit } from "../../../../../../constant"
import { Formik } from 'formik';
import { validationSchema } from "./validationSchema"
import { useDispatch } from "react-redux"
import { createGradeRequest, updateGradeRequest } from '../../../../../../redux/branches/gradeActions';
import { toast } from "react-toastify"
import { useLocation, useHistory } from 'react-router-dom';
export function CreateSubject() {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const history = useHistory()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('i');
    const title = queryParams.get('t');
    const dispatch = useDispatch()
    const initialValues = { title: title ? title : "" }
    React.useEffect(() => {
        if (id && title) {

        }
    }, [id, title])
    const onSubmit = (values, formActions) => {
        if (id && title) {

            dispatch(updateGradeRequest({ ...values, gradeId: id }, (resp) => {
                if (resp.status) {
                    formActions.setSubmitting(false);
                    setIsSubmitting(false)
                    toast.success("Grade updated successfully!")
                    history.push(`/grade/create?i=1&t=${values.title}`)
                }
                else {
                    formActions.setSubmitting(false);
                    setIsSubmitting(false)
                    toast.error(resp.message)
                }

            }))

        }
        else {
            dispatch(createGradeRequest(values, (resp) => {
                if (resp.status) {
                    formActions.setSubmitting(false);
                    setIsSubmitting(false)
                    formActions.resetForm()
                    toast.success("Grade created successfully!")
                }
                else {
                    formActions.setSubmitting(false);
                    setIsSubmitting(false)
                    toast.error(resp.message)
                }

            }))
        }


    };
    return <Fragment>

        <Breadcrumb parent="dashboard" title={`${id && title ? 'Update ' : "Create "} Subject`} />

        <Container fluid={true}>
            <Card>
                <CardBody>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                            <Form className="p-3" onSubmit={handleSubmit}>
                                <Row>
                                    {fields.map(({ name, label, type, placeholder, required }, key) => <Col md="6" sm="6" xs='12'>
                                        <FormGroup key={key}>
                                            <Label for={name}>
                                                {label} {required ? <RequiredField /> : ""}
                                            </Label>
                                            <Input
                                                name={name}
                                                onChange={handleChange}
                                                type={type}
                                                placeholder={placeholder
                                                }
                                                value={values[name]}
                                                bsSize="sm"
                                            />
                                            {errors[name] && touched[name] && (
                                                <ErrorMessage message={errors[name]} />
                                            )}
                                        </FormGroup>
                                    </Col>)}
                                    <Col md="6" sm="6" xs='12'>
                                        <FormGroup>
                                            <Label>Status:</Label>
                                            <div className="m-checkbox-inline">
                                                <Label for="edo-ani">
                                                <Input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" defaultChecked />{"Active"}
                                                    </Label>
                                                <Label for="edo-ani1">
                                                <Input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />{"InActive"}
                                                    </Label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="text-right">
                                    <Button color="primary" className="mr-1" type='submit' disabled={isSubmitting}>{Submit}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </CardBody>

            </Card>
        </Container>
    </Fragment>
}