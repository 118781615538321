import React, { Fragment } from 'react';
import { Card, CardBody, Button, Modal, Form, ModalHeader, ModalBody, ModalFooter, Col } from 'reactstrap'
import { clientsTableColumns, sortByConfig } from './clientDataConfig';
import { useState } from 'react';
import { useEffect } from 'react';
import SortBy from '../common/sortBy';
import TableComponent from '../common/TableComponent';
import Spinner from '../common/spinner'
import { clientsDocuemntsColumns } from './clientDataConfig';
import { FormInput } from '../forms/formBuilder';


const ClientLeads = (props) => {
    const [documents, setDocuments] = useState([]);
    const [paginationConfig, setPaginationConfig] = useState({
        pageNumber: 1,
        pageSize: 10,
        sortBy: 'id',
        order: 'ASC',
        hideOnNoData: 1
    });

    const documentConfig = {
        tableColumns: clientsDocuemntsColumns,
        data: documents,
        onClickPagination: "s",
        paginationConfig: paginationConfig
    }
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);

    const onSubmit = data => {
        // const title = document.querySelector('#title').value
        // const message = document.querySelector('#message').value
        // const clientId = document.querySelector('#clientId').value
        // const formData = {
        //     title, message, clientId
        // }

        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         "Authorization": `Bearer ${token}`,
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(formData)
        // };
        // fetch(endpoint + "clients/notes", requestOptions)
        //     .then(res => res.json())
        //     .then(
        //         (result) => {
        //             if (result.status === true) {
        //                 toast.success(result.message)
        //                 history.push("/clients/details/" + result.package.data.id);
        //             } else {
        //                 let erros = result.payload
        //                 erros.map((element) => (
        //                     toast.error(element.msg)
        //                 ))

        //             }
        //         },
        //         (error) => {
        //             toast.error(error)
        //         }
        //     )



    };
    return (
        <Fragment>
            <TableComponent tableConfig={documentConfig} />
        </Fragment>
    );
};

export default ClientLeads;