import axios from "axios";
import { toast } from "react-toastify";
import { RequestError } from "../../constant";

const token = localStorage.getItem("accessToken");
const headers = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default function axios_request(
  method,
  url,
  data = null,
  callback = null
) {
  axios({
    method: method,
    url: url,
    data: data,
    headers: headers,
  })
    .then((response) => {
      if (callback) {
        callback(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(RequestError);
      }

      // throw error;
    });
}
