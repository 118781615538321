import { INSTANCE } from "../../../config/axiosInstance";

export default class ClassApi {
    getPaginatedClasses = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/branch-admin/get-class-paginated",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };

    getGrades = async () => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "GET",
                url: "/branch-admin/grade-levels",
            })
                .then(resolve)
                .catch(reject);
        });
    };

    getGradesLevelSections = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/branch-admin/grade-levels-sections",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    createGradeSection = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/branch-admin/grade-section",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    createClass = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/branch-admin/class",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
}
