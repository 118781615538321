import React, { Fragment } from "react";
import Breadcrumb from "../../../../../../layout/breadcrumb";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { fields } from "./fields";
import { RequiredField } from "../../../../../utils/fieldRequired";
import ErrorMessage from "../../../../../utils/errorMessage";
import { Submit } from "../../../../../../constant";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { useDispatch } from "react-redux";
import {
  createGradeRequest,
  updateGradeRequest,
} from "../../../../../../redux/branches/gradeActions";
import {
  create_item,
  get_single_item,
  update_item,
} from "../../../../../../redux/endpoints";
import { apiCall } from "../../../../../../redux/apiCall";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";

export function CreateItem() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const { id } = useParams();
  const history = useHistory();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const id = queryParams.get('i');
  // const title = queryParams.get('t');
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState({
    code: "",
    name: "",
    amount: "",
    amountType: "",
    status: true,
    description: "",
  });
  React.useEffect(() => {
    if (id != undefined) {
      fetchDetails();
    }
  }, [id]);

  const fetchDetails = () => {
    dispatch(
      apiCall(get_single_item, "POST", { recordId: id }, (resp) => {
        if (resp.status) {
          let { code, name, amount, amountType, status, description } =
            resp.data;

          let data = {
            code,
            name,
            amount,
            amountType,
            status,
            description,
          };

          setInitialValues(data);
          setIsLoading(false);
        } else {
          toast.error(resp.message);
        }
      })
    );
  };

  const onSubmit = (values, formActions) => {
    if (id != undefined) {
      dispatch(
        apiCall(update_item, "POST", { ...values, recordId: id }, (resp) => {
          if (resp.status) {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            formActions.resetForm();
            toast.success("Item updated successfully!");
            history.goBack();
          } else {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            toast.error(resp.message);
          }
        })
      );
    } else {
      dispatch(
        apiCall(create_item, "POST", values, (resp) => {
          if (resp.status) {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            formActions.resetForm();
            toast.success("Item created successfully!");
          } else {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            toast.error(resp.message);
          }
        })
      );
    }
  };
  return (
    <Fragment>
      <Breadcrumb
        parent="dashboard"
        title={`${id ? "Update " : "Create "} Item`}
      />

      <Container fluid={true}>
        <Card>
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
              }) => (
                <Form className="p-3" onSubmit={handleSubmit}>
                  <Row>
                    {fields.map(
                      ({ name, label, type, placeholder, required }, key) => (
                        <Col md="6" sm="6" xs="12">
                          <FormGroup key={key}>
                            <Label for={name}>
                              {label} {required ? <RequiredField /> : ""}
                            </Label>
                            <Input
                              name={name}
                              onChange={handleChange}
                              type={type}
                              placeholder={placeholder}
                              value={values[name]}
                              bsSize="sm"
                            />
                            {errors[name] && touched[name] && (
                              <ErrorMessage message={errors[name]} />
                            )}
                          </FormGroup>
                        </Col>
                      )
                    )}

                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"amountType"}>
                          Amount Type <RequiredField />
                        </Label>
                        <Input
                          name={"amountType"}
                          onChange={handleChange}
                          type={"select"}
                          size={"sm"}
                        >
                          <option value={false}>Select Amount Type</option>
                          <option
                            value={"fixed"}
                            selected={values["amountType"] == "fixed"}
                          >
                            Fixed
                          </option>
                          <option
                            value={"customized"}
                            selected={values["amountType"] == "customized"}
                          >
                            Customized
                          </option>
                        </Input>

                        {errors["amountType"] && touched["amountType"] && (
                          <ErrorMessage message={errors["amountType"]} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="12" sm="12" xs="12">
                      <FormGroup>
                        <Label for={"description"}>Description:</Label>
                        <textarea
                          className="form-control-sm form-control"
                          rows="2"
                          onChange={handleChange}
                          value={values["description"]}
                          placeholder="Description"
                          name="description"
                          id="description"
                        ></textarea>
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label>Status:</Label>
                        <div className="m-checkbox-inline">
                          <Label for="edo-ani">
                            <Input
                              className="radio_animated"
                              id="edo-ani"
                              type="radio"
                              name="status"
                              value={true}
                              onChange={handleChange}
                              defaultChecked
                              checked={values["status"] == true}
                            />
                            {"Active"}
                          </Label>
                          <Label for="edo-ani1">
                            <Input
                              className="radio_animated"
                              id="edo-ani1"
                              type="radio"
                              name="status"
                              value={false}
                              onChange={handleChange}
                              checked={values["status"] == false}
                            />
                            {"InActive"}
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <Button
                      color="danger"
                      className="mr-1"
                      type="button"
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                    <Button
                      color="primary"
                      className="mr-1"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {Submit}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}
