import React, { Fragment } from "react"
import Breadcrumb from "../../../../../layout/breadcrumb"
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button } from "reactstrap"
import { fields } from "./fields"
import { RequiredField } from "../../../../utils/fieldRequired"
import ErrorMessage from "../../../../utils/errorMessage"
import { Submit } from "../../../../../constant"
import { Formik } from 'formik';
import { validationSchema } from "./validationSchema"
import { useDispatch } from "react-redux"
import { createTeacher } from "../../../../../redux/branch-admin/teacherActions"
import { toast } from "react-toastify"
export function CreateTeacher() {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const dispatch = useDispatch()
    const initialValues = {
        email: '',
        firstName: '',
        lastName: ''
    }
    const onSubmit = (values, formActions) => {
        dispatch(createTeacher(values, (resp) => {
            if (resp.status) {
                formActions.setSubmitting(false);
                setIsSubmitting(false)
                formActions.resetForm()
                toast.success("Teacher created successfully!")
            }
            else {
                formActions.setSubmitting(false);
                setIsSubmitting(false)
                toast.error(resp.message)
            }

        }))

    };
    return <Fragment>

        <Breadcrumb parent="dashboard" title={"Create Branch"
        } />

        <Container fluid={true}>
            <Card>
                <CardBody>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                            <Form className="p-3" onSubmit={handleSubmit}>
                                <Row>
                                    {fields.map(({ name, label, type, placeholder, required }, key) => <Col md="6" sm="6" xs='12'>
                                        <FormGroup key={key}>
                                            <Label for={name}>
                                                {label} {required ? <RequiredField /> : ""}
                                            </Label>
                                            <Input
                                                name={name}
                                                onChange={handleChange}
                                                type={type}
                                                placeholder={placeholder
                                                }
                                                value={values[name]}
                                            />
                                            {errors[name] && touched[name] && (
                                                <ErrorMessage message={errors[name]} />
                                            )}
                                        </FormGroup>
                                    </Col>)}
                                </Row>
                                <div className="text-right">
                                    <Button color="primary" className="mr-1" type='submit' disabled={isSubmitting}>{Submit}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </CardBody>

            </Card>
        </Container>
    </Fragment>
}