module.exports={
    isRequired( value ){
        if(value)
        {
            return 0;
        }else{
            return 1;
        }
    },

    isEmail (value ){
        if(value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))
        {return 0}
        else {return 1}
    }
}