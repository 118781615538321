export const fields = [
    {
        name: "firstName",
        type: 'text',
        label: "First name",
        placeholder: "First name",
        required: true,
    }, 
    {
        name: 'lastName',
        type: "text",
        label: "Last name",
        placeholder: "Last name",
        required: true,
    },
    {
        name: "email",
        type: "email",
        label: "Email",
        placeholder: "Enter email",
        required: true,
    },
]