import React from "react";
import { Card, Row, Col, CardBody } from "reactstrap";
import ApexCharts from "react-apexcharts";
import ConfigDB from "../../../data/customizer/config";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../redux/apiCall";
import { appointment_data, top_categories } from "../../../constant/url";
import { Loader } from "../../utils/Loader";
import ReactApexChart from "react-apexcharts";

const primary =
  localStorage.getItem("default_color") || ConfigDB.data.color.primary_color;
const secondary =
  localStorage.getItem("secondary_color") ||
  ConfigDB.data.color.secondary_color;
export function TopCategories() {
  const [loading, setLoading] = React.useState(false);
  const [dataSpan, setDataSpan] = React.useState("m");
  const [graphData, setGraphData] = React.useState({
    series: [],

    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            "<div> Revenue : " +
            w.config.responsive[0].revenue[seriesIndex] +
            " Rs </div>"
          );
        },
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const dispatch = useDispatch();
  const topCategoriesData = () => {
    setLoading(true);
    dispatch(
      apiCall(top_categories, "POST", { data_span: dataSpan }, (resp) => {
        let data = { ...graphData };
        let _uniqueLabels = [...new Set(resp.data.labels)];

        data.options.labels = _uniqueLabels;

        let { dataSet } = resp.data;
        let series = [];
        let revenue = [];
        for (let label of _uniqueLabels) {
          series.push(dataSet[label].appointmentCount);
          revenue.push(dataSet[label].revenue);
        }
        data.series = series;
        data.options.responsive[0].revenue = revenue;
        setGraphData(data);
        setLoading(false);
      })
    );
  };
  React.useEffect(() => {
    topCategoriesData();
  }, [dataSpan]);
  return (
    <div>
      <h5>{"Top Categories"}</h5>
      <Card className="earning-card">
        <CardBody className="p-0">
          <Row className="m-0">
            <Col xl="12" lg="12" md="12" sm="12" className="p-0">
              <div className="chart-right">
                <Row className="m-0 p-tb w-100">
                  <Col xl="4" md="4" sm="4"></Col>
                  <Col xl="8" md="8" sm="8" className="col-12 p-0">
                    <div className="inner-top-left">
                      <ul className="d-flex list-unstyled justify-content-end">
                        <li
                          style={{ cursor: "pointer" }}
                          className={dataSpan === "d" ? "active" : ""}
                          onClick={() => setDataSpan("d")}
                        >
                          {"Daily"}
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          className={dataSpan === "w" ? "active" : ""}
                          onClick={() => setDataSpan("w")}
                        >
                          {"Weekly"}
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          className={dataSpan === "m" ? "active" : ""}
                          onClick={() => setDataSpan("m")}
                        >
                          {"Monthly"}
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          className={dataSpan === "y" ? "active" : ""}
                          onClick={() => setDataSpan("y")}
                        >
                          {"Yearly"}
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    {loading ? (
                      <Loader />
                    ) : (
                      <CardBody className="p-0">
                        {graphData?.series.length ? (
                          <div className="chart-container">
                            <div id="circlechart" style={{ marginTop: "25px" }}>
                              <ApexCharts
                                options={graphData?.options}
                                series={graphData?.series}
                                type="pie"
                                height={240}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ height: "240px" }}
                          >
                            <h5>No Data To Display</h5>
                          </div>
                        )}
                      </CardBody>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
