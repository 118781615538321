export const fields = [{
    name: "code",
    type: "text",
    label: "Code",
    placeholder: "Enter Code",
    required: true,
},{
    name: "title",
    type: "text",
    label: "Title",
    placeholder: "Enter Title",
    required: true,
}, ]