import React, { useState, useEffect } from "react";
import man from "../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import {
  Password,
  EmailAddress,
  ForgotPassword,
  JWT,
  LoginWithJWT,
} from "../constant";
import axios from "axios";
import validator from "../helper/validation";

const Logins = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState("jwt");
  const [togglePassword, setTogglePassword] = useState(false);
  const endpoint = localStorage.getItem("api-endpoint");

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  function validateForm(email, password) {
    let error = 0;
    if (validator.isRequired(email)) {
      toast.error("Email is required");
      error = 1;
    } else {
      if (validator.isEmail(email)) {
        toast.error("Email is invalid");
        error = 1;
      }
    }
    if (validator.isRequired(password)) {
      toast.error("Password is required");
      error = 1;
    }

    return error;
  }

  const loginWithJwt = async (email, password) => {
    // validate form inputs
    const error = validateForm(email, password);
    if (!error) {
      try {
        const requestOptions = {
          email,
          password,
        };
        // const response = await axios.post(endpoint + "login", requestOptions)
        // toast.success(response.data.message)

        // var user= response.data.package.user
        // var token = response.data.package.accessToken
        setValue(man);
        setName("user.firstName" + " " + "user.lastName");
        // localStorage.setItem('token', token);
        window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
      } catch (e) {
        const messgage = e.response.data.message;
        toast.error(messgage);
      }
    }
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img
                    className="img-fluid for-light"
                    src={require("../assets/images/logo/login.png")}
                    alt=""
                  />
                  <img
                    className="img-fluid for-dark"
                    src={require("../assets/images/logo/logo_dark.png")}
                    alt=""
                  />
                </a>
              </div>
              <div className="login-main login-tab">
                <Nav className="border-tab flex-column" tabs>
                  <NavItem>
                    <NavLink
                      className={selected === "jwt" ? "active" : ""}
                      onClick={() => setSelected("jwt")}
                    >
                      <img src={require("../assets/images/jwt.svg")} alt="" />
                      <span>{JWT}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={selected} className="content-login">
                  <TabPane className="fade show" tabId={"jwt"}>
                    <Form className="theme-form">
                      <h4>Sign In</h4>
                      <p>{"Enter your email & password to login"}</p>
                      <FormGroup>
                        <Label className="col-form-label">{EmailAddress}</Label>
                        <Input
                          className="form-control"
                          type="email"
                          required=""
                          onChange={(e) => setEmail(e.target.value)}
                          defaultValue={email}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{Password}</Label>
                        <Input
                          className="form-control"
                          type={togglePassword ? "text" : "password"}
                          onChange={(e) => setPassword(e.target.value)}
                          defaultValue={password}
                          required=""
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword(!togglePassword)}
                        >
                          <span className={togglePassword ? "" : "show"}></span>
                        </div>
                      </FormGroup>
                      <div className="form-group mb-0">
                        <div className="checkbox ml-3">
                          <a className="link" href="#javascript">
                            {ForgotPassword}
                          </a>
                          <br></br>
                          <br></br>
                        </div>
                        <Button
                          color="primary"
                          className="btn-block"
                          onClick={() => loginWithJwt(email, password)}
                        >
                          {LoginWithJWT}
                        </Button>
                      </div>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Logins);
