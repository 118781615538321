import React, { Fragment }  from "react";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { RequiredField } from "../../../../utils/fieldRequired";
import Breadcrumb from "../../../../../layout/breadcrumb";
import ErrorMessage from "../../../../utils/errorMessage";
import { Submit } from "../../../../../constant";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { useLocation, useHistory } from "react-router-dom";
export function InvoiceSetings() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("i");
  const title = queryParams.get("t");
  const initialValues = { title: title ? title : "" };

  return (
    <>
    <Fragment>
      <Breadcrumb
        parent="dashboard"
        title="Invoice Settings"
      />

      <Container fluid={true}>
        <Card>
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              // onSubmit={onSubmit}
            >
              <Row>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                    Branch Name <RequiredField />
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"text"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      Branch Logo <RequiredField />
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"file"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      Registration # <RequiredField />
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"text"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      Phone # <RequiredField />
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"text"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      Email <RequiredField />
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"email"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      Date of generate due date  <RequiredField />
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"date"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="12" sm="12" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      Address 
                    </Label>
                    <textarea className="form-control-sm form-control" rows="1"  name='desc' size="sm"></textarea>
                  </FormGroup>
                </Col>
                <Col md="12" sm="12" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      Remarks 
                    </Label>
                    <textarea className="form-control-sm form-control" rows="1"  name='desc'></textarea>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      1st Notification 
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"number"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      2nd Notification 
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"number"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      3rd Notification 
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"number"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"Grade"}>
                      4th Notification 
                    </Label>
                    <Input
                      name={"gradeLevelId"}
                      type={"number"}
                      size={"sm"}
                    ></Input>
                  </FormGroup>
                </Col>
                <div className="text-right">
                  <Button
                    color="primary"
                    className="mr-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {Submit}
                  </Button>
                </div>
              </Row>
            </Formik>
          </CardBody>
        </Card>
      </Container>
        </Fragment>
    </>
  );
}
