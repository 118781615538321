import BranchApi from "./branchApi";
const branchApi = new BranchApi();


export const getBranches = (state, cb) => async () => {
    try {
        const { data } = await branchApi.getBranches(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};

export const createBranch = (values, cb) => async () => {
    try {
        const { data } = await branchApi.createBranch(values);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};
export const branchById = (values, cb) => async () => {
    try {
        const { data } = await branchApi.branchById(values);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};

export const updateBranch = (values, cb) => async () => {
    try {
        const { data } = await branchApi.updateBranch(values);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};