import React, { Fragment } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import SortBy from "../../../../../common/sortBy";
import TableComponent from "../../../../../common/TableComponent";
import Spinner from "../../../../../common/spinner";
import { TABLE_COMPONENT_CONTEXT } from "../../../../../../contexts/schoolRequests";

const TableData = (props) => {
  const [data, setData] = useState([]);
  const { handleTeacherId, tableColumns } = React.useContext(
    TABLE_COMPONENT_CONTEXT
  );
  const handleAction = (id) => {
    // props.onClickAction(id)
  };

  useEffect(() => {
    var TeacherData = [];
    const data = props.data.data;
    data.forEach((element) => {
      const mappedData = {
        firstName: element.firstName,
        lastName: element.lastName,
        email: element.email,
        actions: [
          <i
            className="fa fa-pencil font-primary"
            style={{ cursor: "pointer" }}
            onClick={() => handleTeacherId(element.id)}
          ></i>,
        ],
      };
      TeacherData.push(mappedData);
    });

    setData(TeacherData);
  }, [props]);

  const tableConfig = {
    tableColumns: tableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };

  return (
    <Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <TableComponent
          loading={props.loading}
          tableConfig={tableConfig}
          numPages={props.data?.numPages}
          setPaginationConfig={props.setPaginationConfig}
          totalRecords={props.data.total_records}
        />
      )}
    </Fragment>
  );
};

export default TableData;
