import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import TableData from "./item-data/ItemData";
import {
  getTeachers,
  getTeacherDetail,
} from "../../../../../redux/branch-admin/teacherActions";
import { TABLE_COMPONENT_CONTEXT } from "../../../../../contexts/schoolRequests";
import { Filters } from "./item-data/ItemFilters";
import { CreateTeacher } from "../../../../../constant";
import { Link } from "react-router-dom";
import { get_item } from "../../../../../redux/endpoints";
import { apiCall } from "../../../../../redux/apiCall";

const ItemGridData = () => {
  const [tableData, setTableData] = useState({
    total_records: 0,
    data: [],
  });
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [teacherId, setTeacherId] = useState(null);
  const dispatch = useDispatch();
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    pageSize: 5,
    sortBy: "id",
    sortDirection: "DESC",
  });

  const getItemRequest = async () => {
    dispatch(
      apiCall(get_item, "GET", {}, (resp) => {
        if (resp.status) {
          setTableData({
            total_records: resp.data.length,
            // numPages: resp.data.pagination.numPages,
            data: resp.data,
          });
        }
      })
    );
  };

  useEffect(() => {
    getItemRequest();
  }, [paginationConfig]);

  const handlePagination = (paginationData) => {
    let temp = { ...paginationConfig };
    temp["pageNumber"] = paginationData.current;
    setPaginationConfig(temp);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    // setSearchReq(searchReq)
  };
  const handleActionId = (id) => {
    // dispatch(getTeacherDetail({ "teacherId": id }, (resp) => {
    // }))
  };
  const tableColumns = [
    {
      name: "Item Code",
      selector: "code",
      sortable: true,
      center: true,
    },
    {
      name: "Item Name",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
      center: true,
    },
    {
      name: "Amount Type",
      selector: "amountType",
      center: true,
    },
    {
      name: "Status",
      selector: "status",
      center: true,
    },
    {
      name: "Actions",
      selector: "actions",
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="Project" title="Item" />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="pt-0">
                <Row className="p-3">
                  <Col sm="12">
                    <TABLE_COMPONENT_CONTEXT.Provider
                      value={{
                        paginationConfig,
                        handlePagination,
                        setPaginationConfig,
                        tableColumns,
                        staticPagination: true,
                      }}
                    >
                      <div className="d-flex justify-content-end">
                        <Link
                          className="mt-4 btn btn-primary"
                          to="/item/create"
                        >
                          Create Item
                        </Link>
                      </div>
                      {/* <Filters /> */}
                      <TableData
                        loading={false}
                        paginationConfig={paginationConfig}
                        data={tableData}
                        onClickSort={(value) => handleSorting(value)}
                        onClickPagination={(value) => handlePagination(value)}
                        onClickAction={(e) => handleActionId(e)}
                        setPaginationConfig={setPaginationConfig}
                      />
                    </TABLE_COMPONENT_CONTEXT.Provider>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ItemGridData;
