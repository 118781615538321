import React, { Fragment, useState } from 'react';
import { Row, Col, FormGroup, Label, Input} from 'reactstrap'


const SortBy = (props) => {
    const config= props.config;
    var recordStartIndex=1;
    var recordsOnScreen = config.recordsOnScreen
    const sortByConfig = config.sortByConfig
    const button = props.button
    const paginationConfig = config.paginationConfig

  

    const handleSortClick = (event) => {
        const name=event.target.name
        const value=event.target.value
        var pageSize = paginationConfig.pageSize
        var order = paginationConfig.order
        var sortBy = paginationConfig.sortBy
        if(name==='pageSize'){
            pageSize=parseInt(value)
        }
        if(name==='sortBy'){
            sortBy=value
        }
        if(name==='order'){
            order=value
        }


        const paginationNew = {
            pageNumber: paginationConfig.pageNumber,
            pageSize: pageSize,
            sortBy: sortBy,
            order: order,
        }
        config.onClickSort(paginationNew)
    }

    recordStartIndex=((paginationConfig.pageNumber)*(paginationConfig.pageSize))-(paginationConfig.pageSize-1);
    if(recordsOnScreen !== paginationConfig.pageSize){
        recordsOnScreen = (paginationConfig.pageSize+recordsOnScreen)
    }

    return (


        <Fragment>
            <Row>
                <Col sm="4">
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Show</Label>
                        <Input type="select" name="pageSize" className="form-control digits" defaultValue="1" onChange={handleSortClick}>
                            <option value={10}>{"10"}</option>
                            <option value={25}>{"25"}</option>
                            <option value={50}>{"50"}</option>
                            <option value={75}>{"75"}</option>
                            <option value={100}>{"100"}</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Sort By</Label>
                        <Input type="select" name="sortBy" className="form-control digits" defaultValue="1" onChange={handleSortClick}>
                            {sortByConfig.options.map((items) =>
                                <option value={items.name} key={items.name}>{items.title}</option>
                            )}
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Order By</Label>
                        <Input type="select" name="order" className="form-control digits" defaultValue="1" onChange={handleSortClick}>
                            <option value={"ASC"}>{"Ascending"}</option>
                            <option value={'DESC'}>{"Descending"}</option>
                        </Input>
                    </FormGroup>
                </Col>


            </Row>
            <Row>
                <Col sm="4">
                    <p><strong>Total {sortByConfig.title} Found : </strong>{config.totalRecords}</p>
                    {/* <p><strong>Showing : </strong>{recordStartIndex} to {recordsOnScreen} of {config.totalRecords}</p> */}

                </Col>
                <Col sm="4">
                {button}
                </Col>
            </Row>

            </Fragment>

    );
};

export default SortBy;