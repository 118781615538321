import React from "react";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { RequiredField } from "../../../../utils/fieldRequired";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../../redux/apiCall";
import {
  item_list,
  student_account_add,
  student_account_remove,
  student_account_update,
  student_accounts,
} from "../../../../../redux/endpoints";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../utils/Loader";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../utils/errorMessage";
export function AccountsDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [accountInfo, setAccountInfo] = React.useState([
    { itemId: "", amount: "", isDiscount: false },
  ]);
  const [errors, setErrors] = React.useState([{}]);
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const removeAccountInfo = (index) => {
    let originalRecords = [...accountInfo];
    let records = originalRecords.splice(index, 1);
    setAccountInfo(records);
  };
  React.useEffect(() => {
    fetchAccounts();
    fetchItems();
  }, []);
  const fetchAccounts = () => {
    dispatch(
      apiCall(student_accounts, "POST", { studentId: id }, (resp) => {
        if (resp.data.length) {
          setAccountInfo(resp.data);
        }
        setLoading(false);
      })
    );
  };
  const handleChange = ({ currentTarget: input }, index) => {
    let _accountInfo = [...accountInfo];
    let _errors = [...errors];
    _errors[index][`${input.name}`] = null;
    _accountInfo[index][input.name] =
      input.type === "checkbox" ? input.checked : input.value;
    setAccountInfo(_accountInfo);
    setErrors(_errors);
  };
  const fetchItems = () => {
    dispatch(
      apiCall(item_list, "GET", {}, (resp) => {
        setItems(resp.data);
      })
    );
  };
  const onSubmit = (values, index) => {
    let e = checkErrors(values, index);
    if (Object.keys(e).length) {
      let _errors = [...errors];
      _errors[index] = e;
      setErrors(_errors);
      return;
    }

    if (values.id) {
      dispatch(
        apiCall(
          student_account_update,
          "POST",
          { ...values, studentId: id, recordId: values.id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Account details updated successfully!");
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    } else {
      dispatch(
        apiCall(
          student_account_add,
          "POST",
          { ...values, studentId: id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Account details added successfully!");
                let _accountInfo = [...accountInfo];
                _accountInfo[index] = resp.data;
                setAccountInfo(_accountInfo);
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    }
  };
  const checkErrors = (values) => {
    let _errors = {};
    if (!values.itemId || !toString(values.itemId).length) {
      _errors.itemId = "Select an item";
    }
    if (!values.amount) {
      _errors.amount = "Amount is invalid";
    }

    return _errors;
  };
  const removebankInfo = (index) => {
    let originalRecords = [...accountInfo];
    let values = { ...originalRecords[index] };
    if (values && values.id) {
      dispatch(
        apiCall(
          student_account_remove,
          "POST",
          { recordId: values.id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("account record removed successfully!");
                let records = originalRecords.filter(
                  (item, key) => key !== index
                );
                setAccountInfo(records);
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    } else {
      let records = originalRecords.filter((item, key) => key !== index);
      setAccountInfo(records);
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      {accountInfo.map((record, index) => (
        <>
          <div className="d-flex justify-content-between w-100">
            <h6 className="text-primary">{index + 1} .</h6>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => removebankInfo(index)}
            >
              <i className="fa fa-times text-danger"></i>
            </div>{" "}
          </div>
          <Row className="align-items-center">
            <Col md="5" sm="6" xs="12">
              <FormGroup>
                <Label for={"itemId"}>
                  Item <RequiredField />
                </Label>
                <Input
                  name={"itemId"}
                  type={"select"}
                  onChange={(e) => handleChange(e, index)}
                  size={"sm"}
                >
                  <option value="">Select item</option>
                  {items.map(({ id, name }) => (
                    <option value={id} selected={id === record.itemId}>
                      {name}
                    </option>
                  ))}
                </Input>
                {errors[index]?.itemId && (
                  <ErrorMessage message={errors[index]["itemId"]} />
                )}
              </FormGroup>
            </Col>
            {/* <Col md="4" sm="6" xs='12'>
                    <FormGroup >
                        <Label for={"Grade"}>
                            Type <RequiredField />
                        </Label>
                        <Input
                            name={"gradeLevelId"}
                            type={"select"}
                            size={"sm"}
                        >
                            <option>Select type</option>
                        </Input>

                    </FormGroup>
                </Col> */}
            <Col md="3" sm="6" xs="12">
              <FormGroup>
                <Label for={"amount"}>
                  Amount <RequiredField />
                </Label>
                <Input
                  name={"amount"}
                  type={"number"}
                  size={"sm"}
                  value={record.amount}
                  onChange={(e) => handleChange(e, index)}
                ></Input>
                {errors[index]?.amount && (
                  <ErrorMessage message={errors[index]["amount"]} />
                )}
              </FormGroup>
            </Col>
            <Col md="3" sm="6" xs="12">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="isDiscount"
                    onChange={(e) => handleChange(e, index)}
                    checked={record.isDiscount}
                  />{" "}
                  Discounted
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex w-100 justify-content-end">
            <Button
              color="primary"
              className="mr-1"
              type="button"
              onClick={(e) => onSubmit(record, index)}
            >
              {record.id ? "Update" : "Save"}
            </Button>
          </div>
        </>
      ))}
      <div className="d-flex w-100 justify-content-start">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setErrors([
              ...errors,
              {
                itemId: "",
                amount: "",
              },
            ]);

            setAccountInfo([
              ...accountInfo,
              {
                studentId: id,
                itemId: "",
                amount: "",
                isDiscount: false,
              },
            ]);
          }}
        >
          Add More
        </button>
      </div>
    </>
  );
}
