import React from "react";
import { Card, CardBody, Button } from "reactstrap";
import { clientsTableColumns, sortByConfig } from "./clientDataConfig";
import { useState } from "react";
import { useEffect } from "react";
import SortBy from "../common/sortBy";
import TableComponent from "../common/TableComponent";
import Spinner from "../common/spinner";

const ClientData = (props) => {
  const [data, setData] = useState([]);

  const handleEmailClient = (id) => {};
  const handleSmsClient = (id) => {};
  const FavButton = (
    <Button outline color="primary" size="sm">
      Favorites
    </Button>
  );

  useEffect(() => {
    var clientData = [];
    const data = props.data.data;
    data.forEach((element) => {
      const mappedData = {
        id: element.id,
        fav: (
          <i
            className="fa fa-star-o font-primary"
            onClick={() => props.onClickFav(element.id)}
            value={element.id}
            id="favClient"
          ></i>
        ),
        clientId: <a href={"/clients/details/" + element.id}>{element.id}</a>,
        name: element.name,
        contact: element.cell,
        city: element.city,
        added_on: element.createdAt,
        allocation: "mueed",
        actions: [
          <i
            className="fa fa-envelope-o font-primary"
            onClick={() => handleEmailClient(element.id)}
            key={"email" + element.id}
          ></i>,
          " ",
          <i
            className="fa fa-mobile font-primary"
            onClick={() => handleSmsClient(element.id)}
            key={"sms" + element.id}
          ></i>,
        ],
      };
      clientData.push(mappedData);
    });

    setData(clientData);
  }, [props]);

  const tableConfig = {
    tableColumns: clientsTableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };
  var recordsOnScreen = data.length;

  const sortByConfiguration = {
    sortByConfig,
    paginationConfig: props.paginationConfig,
    onClickSort: props.onClickSort,
    totalRecords: props.data.total_records,
    recordsOnScreen,
  };

  return (
    <Card>
      <CardBody>
        <SortBy config={sortByConfiguration} button={FavButton} />
        {props.loading ? (
          <Spinner />
        ) : (
          <TableComponent loading={props.loading} tableConfig={tableConfig} />
        )}
      </CardBody>
    </Card>
  );
};

export default ClientData;
