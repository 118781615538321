import React, { useContext } from "react";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { RequiredField } from "../../../../../utils/fieldRequired";
import { STUDENT_DETAIL } from "../../../../../../contexts";
import { Formik } from "formik";
import { guardianValidation } from "./validationSchemas";
import ErrorMessage from "../../../../../utils/errorMessage";
import {
  student_guardian_add,
  student_guardian_remove,
  student_guardian_update,
} from "../../../../../../redux/endpoints";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../../../redux/apiCall";
export function GuardianInformation() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [guardianInfo, setGuardianInfo] = React.useState([{}]);
  const [errors, setErrors] = React.useState([{}]);
  const { studentDetail, setStudentDetail } = useContext(STUDENT_DETAIL);
  React.useEffect(() => {
    if (studentDetail?.studentGuardians.length) {
      setGuardianInfo(studentDetail.studentGuardians);
    }
  }, [studentDetail?.studentGuardians]);
  const removeAccountInfo = (index) => {
    let originalRecords = [...guardianInfo];
    let values = { ...originalRecords[index] };
    if (values && values.id) {
      dispatch(
        apiCall(
          student_guardian_remove,
          "POST",
          { studentId: id, guardianId: values.id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Student guardian removed successfully!");
                let records = originalRecords.filter(
                  (item, key) => key !== index
                );
                setGuardianInfo(records);
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    } else {
      let records = originalRecords.filter((item, key) => key !== index);
      setGuardianInfo(records);
    }
  };
  const handleChange = ({ currentTarget: input }, index) => {
    let _studentDetail = [...guardianInfo];
    let _errors = [...errors];
    _errors[index][input.name] = null;
    _studentDetail[index][input.name] = input.value;
    setGuardianInfo(_studentDetail);
    setErrors(_errors);
  };
  const onSubmit = (values, index) => {
    let e = checkErrors(values, index);
    if (Object.keys(e).length) {
      let _errors = [...errors];
      _errors[index] = e;
      setErrors(_errors);
      return;
    }

    if (values.id) {
      dispatch(
        apiCall(
          student_guardian_update,
          "POST",
          { ...values, studentId: id, guardianId: values.id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Student guardian record updated successfully!");
                let _studentDetail = { ...studentDetail };
                _studentDetail.studentGuardians.push(resp.data);
                setStudentDetail(_studentDetail);
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    } else {
      dispatch(
        apiCall(
          student_guardian_add,
          "POST",
          { ...values, studentId: id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Student guardian record added successfully!");
                let _studentDetail = { ...studentDetail };
                _studentDetail.studentGuardians.push(resp.data);
                setStudentDetail(_studentDetail);
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    }
  };

  const checkErrors = (values) => {
    let _errors = {};
    if (!values.relation || !values.relation.length) {
      _errors.relation = "Select a relation";
    }
    if (!values.firstName || !values.firstName.length) {
      _errors.firstName = "Provide firstname";
    }
    if (!values.lastName || !values.lastName.length) {
      _errors.lastName = "Provide lastname";
    }
    if (!values.IdNumber || !values.IdNumber.length) {
      _errors.IdNumber = "Provide Id Number";
    }
    if (!values.qualification || !values.qualification.length) {
      _errors.qualification = "Provide qualification";
    }
    if (!values.occupation || !values.occupation.length) {
      _errors.occupation = "Provide occupation";
    }
    if (!values.organization || !values.organization.length) {
      _errors.organization = "Provide organization";
    }
    if (!values.salary || !values.salary.length) {
      _errors.salary = "Provide salary";
    }
    if (!values.contactNumber || !values.contactNumber.length) {
      _errors.contactNumber = "Provide contact number";
    }
    if (
      !values.emergencyContactNumber ||
      !values.emergencyContactNumber.length
    ) {
      _errors.emergencyContactNumber = "Provide emergency contact number";
    }
    return _errors;
  };
  return (
    <>
      <>
        <Form>
          {/* <h6 className="fw-500" style={{ fontSize: "15px" }}> <i className="fa fa-circle-o"></i> Mother Info</h6> */}
          {guardianInfo.map((guardian, index) => (
            <div className="py-2">
              {/* {index !== 0 && */}
              <div className="d-flex justify-content-between w-100 py-1">
                <h6 className="text-primary m-0 p-0">{index + 1} .</h6>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => removeAccountInfo(index)}
                >
                  <i className="fa fa-times text-danger"></i>
                </div>
              </div>
              {/* } */}

              <Row key={index}>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"relation"}>
                      Relation <RequiredField />
                    </Label>
                    <Input
                      name={"relation"}
                      type={"select"}
                      size={"sm"}
                      onChange={(e) => handleChange(e, index)}
                    >
                      <option value="">Select</option>
                      <option
                        value="Father"
                        selected={guardian?.relation === "Father"}
                      >
                        Father
                      </option>
                      <option
                        value="Mother"
                        selected={guardian?.relation === "Mother"}
                      >
                        Mother
                      </option>
                      <option
                        value="Others"
                        selected={guardian?.relation === "Others"}
                      >
                        Other
                      </option>
                    </Input>
                    {errors[index]?.relation && (
                      <ErrorMessage message={errors[index]["relation"]} />
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"firstName"}>
                      First Name <RequiredField />
                    </Label>
                    <Input
                      name={"firstName"}
                      type={"text"}
                      size={"sm"}
                      onChange={(e) => handleChange(e, index)}
                      defaultValue={guardian.firstName}
                    ></Input>
                    {errors[index]?.firstName && (
                      <ErrorMessage message={errors[index]["firstName"]} />
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"lastName"}>
                      Last Name <RequiredField />
                    </Label>
                    <Input
                      name={"lastName"}
                      type={"text"}
                      size={"sm"}
                      onChange={(e) => handleChange(e, index)}
                      defaultValue={guardian.lastName}
                    ></Input>
                    {errors[index]?.lastName && (
                      <ErrorMessage message={errors[index]["lastName"]} />
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"IdNumber"}>
                      ID Number <RequiredField />
                    </Label>
                    <Input
                      name={"IdNumber"}
                      type={"text"}
                      size={"sm"}
                      defaultValue={guardian.IdNumber}
                      onChange={(e) => handleChange(e, index)}
                    ></Input>
                    {errors[index]?.IdNumber && (
                      <ErrorMessage message={errors[index]["IdNumber"]} />
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"qualification"}>
                      Qualification <RequiredField />
                    </Label>
                    <Input
                      name={"qualification"}
                      type={"text"}
                      size={"sm"}
                      defaultValue={guardian.qualification}
                      onChange={(e) => handleChange(e, index)}
                    ></Input>
                    {errors[index]?.qualification && (
                      <ErrorMessage message={errors[index]["qualification"]} />
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"occupation"}>
                      Occupation <RequiredField />
                    </Label>
                    <Input
                      name={"occupation"}
                      type={"text"}
                      defaultValue={guardian.occupation}
                      size={"sm"}
                      onChange={(e) => handleChange(e, index)}
                    ></Input>
                    {errors[index]?.occupation && (
                      <ErrorMessage message={errors[index]["occupation"]} />
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"organization"}>
                      Organization <RequiredField />
                    </Label>
                    <Input
                      name={"organization"}
                      type={"text"}
                      defaultValue={guardian.organization}
                      size={"sm"}
                      onChange={(e) => handleChange(e, index)}
                    ></Input>
                    {errors[index]?.organization && (
                      <ErrorMessage message={errors[index]["organization"]} />
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"salary"}>
                      Salary <RequiredField />
                    </Label>
                    <Input
                      name={"salary"}
                      type={"text"}
                      defaultValue={guardian.salary}
                      size={"sm"}
                      onChange={(e) => handleChange(e, index)}
                    ></Input>
                    {errors[index]?.salary && (
                      <ErrorMessage message={errors[index]["salary"]} />
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"contactNumber"}>
                      Contact No <RequiredField />
                    </Label>
                    <Input
                      name={"contactNumber"}
                      type={"text"}
                      defaultValue={guardian.contactNumber}
                      size={"sm"}
                      onChange={(e) => handleChange(e, index)}
                    ></Input>
                    {errors[index]?.contactNumber && (
                      <ErrorMessage message={errors[index]["contactNumber"]} />
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <FormGroup>
                    <Label for={"emergencyContactNumber"}>
                      Emergency Contact No <RequiredField />
                    </Label>
                    <Input
                      name={"emergencyContactNumber"}
                      type={"text"}
                      size={"sm"}
                      defaultValue={guardian.emergencyContactNumber}
                      onChange={(e) => handleChange(e, index)}
                    ></Input>
                    {errors[index]?.emergencyContactNumber && (
                      <ErrorMessage
                        message={errors[index]["emergencyContactNumber"]}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex w-100 justify-content-end">
                <Button
                  color="primary"
                  className="mr-1"
                  type="button"
                  onClick={(e) => onSubmit(guardian, index)}
                >
                  {guardian.id ? "Update" : "Save"}
                </Button>
              </div>
            </div>
          ))}
        </Form>

        <div className="d-flex w-100 justify-content-start">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              setGuardianInfo([
                ...guardianInfo,
                {
                  relation: "",
                  firstName: "",
                  lastName: "",
                  IdNumber: "",
                  qualification: "",
                  occupation: "",
                  organization: "",
                  salary: "",
                  contactNumber: "",
                  emergencyContactNumber: "",
                },
              ]);
              setErrors([
                ...errors,
                {
                  relation: "",
                  firstName: "",
                  lastName: "",
                  IdNumber: "",
                  qualification: "",
                  occupation: "",
                  organization: "",
                  salary: "",
                  contactNumber: "",
                  emergencyContactNumber: "",
                },
              ]);
            }}
          >
            Add More
          </button>
        </div>
      </>
    </>
  );
}
