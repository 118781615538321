import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
// import BranchData from './branch-data/BranchData';
import { getBranches } from "../../../../redux/branches/branchActions";
import { TABLE_COMPONENT_CONTEXT } from "../../../../contexts/schoolRequests";
// import { BranchFilters } from './branch-data/BranchFilters';
import { apiCall } from "../../../../redux/apiCall";
import { get_staff } from "../../../../constant/url";
import { StaffFilters } from "./staff-list-data/StaffFilters";
import StaffData from "./staff-list-data/StaffData";
import { useHistory } from "react-router-dom";

export function Staff() {
  const history = useHistory();

  const type = "Staff";
  const [requestId, setRequestId] = useState(0);
  const [staff, setStaff] = useState({
    total_records: 0,
    data: [],
  });
  const saloon = localStorage.getItem("saloon")
    ? JSON.parse(localStorage.getItem("saloon"))
    : null;
  const dispatch = useDispatch();
  const [paginationConfig, setPaginationConfig] = useState({
    page_number: 1,
    page_size: 5,
    sort_by: "id",
    order: "DESC",
  });

  const getSchoolRequest = async () => {
    if (saloon) {
      dispatch(
        apiCall(get_staff, "POST", paginationConfig, (resp) => {
          if (resp.status) {
            setStaff({
              total_records: resp.data.pagination.total_records,
              numPages: 0,
              data: resp.data.records,
            });
          }
        })
      );
    }
  };

  useEffect(() => {
    getSchoolRequest();
  }, [paginationConfig]);

  const handlePagination = (paginationData) => {
    let temp = { ...paginationConfig };
    temp["pageNumber"] = paginationData.current;
    setPaginationConfig(temp);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    // setSearchReq(searchReq)
  };
  const handleActionId = (id) => {
    // dispatch(schoolRequestDetails({ "requestId": id }, (resp) => {
    //     setRequestId(resp.data.details.id);
    //     addToggle();
    // }))
  };

  return (
    <Fragment>
      <Breadcrumb parent="dashboard" title={"Staff"} />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="pt-0">
                <Row className="p-3">
                  <Col sm="12">
                    <TABLE_COMPONENT_CONTEXT.Provider
                      value={{
                        paginationConfig,
                        handlePagination,
                        setPaginationConfig,
                      }}
                    >
                      <div className="d-flex w-100 justify-content-end">
                        <Button
                          color="primary"
                          onClick={() => history.push("/staff/create")}
                        >
                          Add Staff
                        </Button>
                      </div>
                      <StaffFilters />
                      <div>
                        <h6>Total Records : {staff.total_records}</h6>
                      </div>
                      <StaffData
                        loading={false}
                        type={type}
                        paginationConfig={paginationConfig}
                        data={staff}
                        onClickSort={(value) => handleSorting(value)}
                        onClickPagination={(value) => handlePagination(value)}
                        onClickAction={(e) => handleActionId(e)}
                        setPaginationConfig={setPaginationConfig}
                        refreshTable={getSchoolRequest}
                      />
                    </TABLE_COMPONENT_CONTEXT.Provider>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
