import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import { FormInput } from "./formBuilder.jsx";

const CellField = (props) => {
  const [currentCell, setCurrentCell] = useState("");
  const [validCell, setValidCell] = useState({ class: "", message: "" });
  let currentClientCells = [];
  if (props.cells) {
    props.cells.forEach((element) => {
      currentClientCells.push(element.cell);
    });
  }
  const [cells, setCells] = useState(currentClientCells);

  const handleCurrentCell = (event) => {
    let value = event.target.value;
    setCurrentCell(value);

    if (value.length === 10) {
      const exists = cells.includes("+880-" + value);
      if (exists) {
        setValidCell({ class: "danger", message: "Duplicate Cell" });
      } else {
        setValidCell({ class: "success", message: "Valid Cell" });
      }
    } else {
      setValidCell({ class: "danger", message: "Invalid Cell" });
    }
  };
  const handleAddCell = () => {
    if (currentCell.length === 10) {
      setCells([...cells, "+880-" + currentCell]);
      setCurrentCell("");
      setValidCell({ class: "", message: "" });
    }
  };
  const handleRemoveCell = (value) => {
    var cellArray = [...cells]; // make a separate copy of the array
    var index = cellArray.indexOf(value);
    if (index !== -1) {
      cellArray.splice(index, 1);
      setCells(cellArray);
    }
  };

  // setCells(String(cells))

  const apendIcon = (
    <InputGroupAddon addonType="prepend">
      <InputGroupText>
        <i className="flag-icon flag-icon-bd"></i> <small> +880</small>
      </InputGroupText>
    </InputGroupAddon>
  );

  return (
    <Fragment>
      <Col md="4 mb-3">
        <FormGroup>
          <Label>{"Cell No"}</Label>
          <InputGroup>
            {apendIcon}
            <Input
              type="number"
              value={currentCell}
              onChange={handleCurrentCell}
              required
              placeholder={"Cell No"}
            />
          </InputGroup>
        </FormGroup>

        <Input type="hidden" value={cells} name="cells" id="cells" required />
      </Col>
      <Col md="2 mb-3">
        <br></br>
        <span className="badge badge-primary mt-4" onClick={handleAddCell}>
          +
        </span>
      </Col>
      <Col md="6 mb-3 text-center">
        {cells.map((element) => (
          <ButtonGroup key={element} className="m-2">
            <Button color="primary">{element}</Button>
            <Button color="secondary" onClick={() => handleRemoveCell(element)}>
              <i className="ion ion-close-round"></i>
            </Button>
          </ButtonGroup>
        ))}
      </Col>
      <Col md="4 mb-3">
        <p className={`badge badge-${validCell.class}`}>{validCell.message}</p>
      </Col>
    </Fragment>
  );
};

export default CellField;
