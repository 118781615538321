import Service from "./service";
const service = new Service();
export const apiCall = (url, method, values, cb) => async () => {
  try {
    const { data } = await service.call(url, method, values);
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
