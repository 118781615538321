import { INSTANCE } from "../../config/axiosInstance";

export default class AuthApi {
  login = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/login",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  registerSaloon = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/register/saloon-admin",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  registerUser = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/register",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  resendEmail = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/resend-email-verification",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  forgotPassword = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/forgot-password",
        params: data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
