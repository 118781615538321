import React, { Fragment } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import SortBy from "../../../../common/sortBy";
import TableComponent from "../../../../common/TableComponent";
import Spinner from "../../../../common/spinner";
import { useHistory } from "react-router-dom";
const GradeData = (props) => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const handleAction = (id, code, title) => {
    if (code === null) {
      code = "";
    }

    history.push(`/grade/create?i=${id}&c=${code}&t=${title}`);
    // props.onClickAction(id)
  };
  const schoolTableColumns = [
    {
      name: "Code",
      selector: "code",
      sortable: true,
      center: true,
    },

    {
      name: "Name",
      selector: "title",
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      selector: "actions",
      center: true,
    },
  ];

  useEffect(() => {
    var GradeData = [];
    const data = props.data.data;
    data.forEach((element) => {
      const mappedData = {
        code: element.code,
        title: element.title,
        actions: [
          <i
            className="fa fa-pencil font-primary"
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleAction(element.id, element.code, element.title)
            }
          ></i>,
        ],
      };
      GradeData.push(mappedData);
    });

    setData(GradeData);
  }, [props]);

  const tableConfig = {
    tableColumns: schoolTableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };

  return (
    <Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <TableComponent
          loading={props.loading}
          tableConfig={tableConfig}
          numPages={props.data?.numPages}
          setPaginationConfig={props.setPaginationConfig}
          totalRecords={props.data?.total_records}
        />
      )}
    </Fragment>
  );
};

export default GradeData;
