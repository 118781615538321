import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { toast } from "react-toastify";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  CardFooter,
} from "reactstrap";
import { FormInput } from "../forms/formBuilder.jsx";
// import InterestField from '../forms/InterestField';
import { useHistory } from "react-router-dom";
import CellField from "../forms/cellField";
const FormValidation = () => {
  const endpoint = localStorage.getItem("api-endpoint");
  const token = localStorage.getItem("token");
  const [clientTypes, setClientTypes] = useState([]);
  // const [types, setTypes] = useState([]);
  const [buyingIntents, setBuyingIntents] = useState([]);
  const [clientRatings, setClientRating] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [country, setCountry] = useState([]);
  const [cities, setCity] = useState([]);
  const [localities, setLocatlity] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  // const [interestList, setInterestList] = useState([
  //     { interestTypeId: "" }
  // ]);
  const history = useHistory();

  const gender = [
    { value: "male", title: "Male" },
    { value: "female", title: "Female" },
    { value: "other", title: "Other" },
  ];

  useEffect(() => {
    const endpoint = localStorage.getItem("api-endpoint");
    const token = localStorage.getItem("token");
    fetch(endpoint + "clients/get-settings/client-add-form-data", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setClientTypes(result.package.clientTypes);
          // setTypes(result.package.types);
          setBuyingIntents(result.package.buyingIntent);
          setClientRating(result.package.rating);
          setIndustries(result.package.industries);
          setCountry(result.package.country);
          setCity(result.package.cities);
          setTeamMembers(result.package.teamMembers);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //   setIsLoaded(true);
          //   setError(error);
          toast.error(error);
        }
      );
  }, []);

  const handleCityChange = (location) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ parentId: location.value }),
    };
    fetch(endpoint + "get-location", requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setLocatlity(result.package.data);
        },
        (error) => {
          toast.error(error);
        }
      );
  };

  // const handleInterrestAdd = () => {
  //     setInterestList([...interestList, { interestTypeId: "" }])
  // }
  // const handleInterrestRemove = (index) => {
  //     const list = [...interestList];
  //     list.splice(index, 1)
  //     setInterestList(list)
  // }

  const onSubmit = (data) => {
    const name = document.querySelector("#name").value;
    const email = document.querySelector("#email").value;
    const gender = document.querySelector("#gender").value;
    const phone = document.querySelector("#phone").value;
    const cells = document.querySelector("#cells").value;
    const address = document.querySelector("#address").value;
    const zip = document.querySelector("#zip").value;
    const budget = document.querySelector("#budget").value;
    const cnic = document.querySelector("#cnic").value;
    const occupation = document.querySelector("#clientOccupation").value;
    const maxIncome = document.querySelector("#maxIncome").value;
    const minIncome = document.querySelector("#minIncome").value;
    const passport = document.querySelector("#passport").value;
    const locationId = document.querySelector("#locality").value;
    const clientRatingId = document.querySelector("#clientRatingId").value;
    const clientTypeId = document.querySelector("#clientTypeId").value;
    const industryId = document.querySelector("#clientIndustryId").value
      ? document.querySelector("#clientIndustryId").value
      : null;
    const buyingIntentId = document.querySelector("#buyingIntentId").value
      ? document.querySelector("#buyingIntentId").value
      : null;
    const assignedTo = document.querySelector("#assignedTo").value;

    const formData = {
      name,
      email,
      gender,
      phone,
      cells,
      address,
      zip,
      budget,
      cnic,
      occupation,
      maxIncome,
      minIncome,
      passport,
      locationId,
      clientRatingId,
      clientTypeId,
      industryId,
      buyingIntentId,
      assignedTo,
    };

    // const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         "Authorization": `Bearer ${token}`,
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(formData)
    // };
    // fetch(endpoint + "clients", requestOptions)
    //     .then(res => res.json())
    //     .then(
    //         (result) => {
    //             if(result.status===true){
    //                 if(result.package.data.id){
    //                     toast.success(result.message)
    //                     history.push("/clients/details/"+result.package.data.id);
    //                 }else{
    //                     toast.error("Something Went Wrong")
    //                 }

    //                 // window.location.href = `${process.env.PUBLIC_URL}/client/${result.package.data.id}`
    //             }else{
    //                 let erros=result.payload
    //                 erros.map((element)=>(
    //                     toast.error(element.msg)
    //                 ))

    //             }
    //         },
    //         (error) => {
    //             toast.error(error)
    //         }
    //     )
  };

  return (
    <Fragment>
      <Breadcrumb parent="Form" title="Add Client" />
      <Container fluid={true}>
        <Col md="8">
          <Card>
            <Form className="needs-validation" noValidate="">
              <CardBody>
                <div className="form-row">
                  <Col md="6 mb-3">
                    <FormInput
                      label="Name *"
                      name="name"
                      placeholder="Name"
                      required={1}
                    />
                  </Col>
                  <Col md="6 mb-3">
                    <FormInput
                      label="Email *"
                      type="email"
                      name="email"
                      placeholder="Email"
                      required={0}
                    />
                  </Col>
                  <Col md="6 mb-3">
                    <FormInput
                      label="Gender *"
                      type="select"
                      name="gender"
                      required={0}
                      options={gender}
                    />
                  </Col>
                  <Col md="6 mb-3">
                    <FormInput
                      label="Address"
                      name="address"
                      placeholder="Address"
                    />
                  </Col>
                  <Col md="6 mb-3">
                    <FormInput
                      label="Assigned To *"
                      type="select"
                      name="assignedTo"
                      required={0}
                      options={teamMembers}
                    />
                  </Col>
                  <Col md="6 mb-3">
                    <FormInput
                      label="CNIC *"
                      type="number"
                      name="cnic"
                      required={0}
                      placeholder="Cnic"
                    />
                  </Col>
                  <Col md="6 mb-3">
                    <FormInput
                      label="Passport"
                      type="number"
                      name="passport"
                      required={0}
                      placeholder="Passport"
                    />
                  </Col>
                  <Col md="6 mb-3">
                    <FormInput
                      label="Landline Number:"
                      name="phone"
                      type="number"
                      placeholder="Landline Number"
                    />
                  </Col>

                  <CellField />
                </div>
              </CardBody>
              <CardFooter>
                <div className="form-row">
                  <Col md="4 mb-3">
                    <FormInput
                      label="Country *"
                      type="select"
                      name="country"
                      options={country}
                      selectedId={1}
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      label="City *"
                      type="select"
                      name="city"
                      options={cities}
                      onchange={(value) => handleCityChange(value)}
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      required={0}
                      label="Locality"
                      type="select"
                      name="locality"
                      options={localities}
                    />
                  </Col>
                </div>
              </CardFooter>
              <CardFooter>
                <div className="form-row">
                  <Col md="4 mb-3">
                    <FormInput
                      label="Client Type"
                      type="select"
                      name="clientTypeId"
                      required={0}
                      options={clientTypes}
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      label="Client Rating"
                      type="select"
                      name="clientRatingId"
                      required={0}
                      options={clientRatings}
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      label="Industry"
                      type="select"
                      name="clientIndustryId"
                      options={industries}
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      label="Buying Intent"
                      type="select"
                      name="buyingIntentId"
                      options={buyingIntents}
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      label="Budger"
                      type="number"
                      name="budget"
                      required={0}
                      placeholder="Budget"
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      label="ZIP"
                      type="number"
                      name="zip"
                      required={0}
                      placeholder="Zip"
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      label="Client Occupation"
                      name="clientOccupation"
                      placeholder="Client Occupation"
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      label="Max Income"
                      name="maxIncome"
                      placeholder="Max Income"
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FormInput
                      label="Min Income"
                      name="minIncome"
                      placeholder="Min Income"
                    />
                  </Col>
                </div>
              </CardFooter>
              {/* <CardFooter>
                                <InterestField types={types} onclick={() => handleInterrestAdd()} />

                            </CardFooter> */}
              <CardFooter>
                <Button color="primary" onClick={onSubmit}>
                  {"Add Client"}
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default FormValidation;
