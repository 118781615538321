import React from 'react';
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button } from "reactstrap"
import { RequiredField } from '../../../../../utils/fieldRequired';
export function StudentInformation() {
    return <Row >

        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Grade"}>
                    Name <RequiredField />
                </Label>
                <Input
                    name={"gradeLevelId"}
                    type={"text"}
                    size={"sm"}
                >

                </Input>

            </FormGroup>
        </Col>


        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Section"}>
                    Gender <RequiredField />
                </Label>
                <Input
                    name={"classTeacherId"}

                    type={"select"}
                    size={"sm"}

                >
                    <option value={false}>Select gender</option>

                </Input>

            </FormGroup>
        </Col>
        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Section"}>
                    Class <RequiredField />
                </Label>
                <Input
                    name={"classTeacherId"}

                    type={"select"}
                    size={"sm"}

                >
                    <option value={false}>Select class</option>

                </Input>

            </FormGroup>
        </Col>
        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Section"}>
                    Section <RequiredField />
                </Label>
                <Input
                    name={"classTeacherId"}

                    type={"select"}
                    size={"sm"}

                >
                    <option value={false}>Select section</option>

                </Input>

            </FormGroup>
        </Col>
        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Section"}>
                    Enrollment <RequiredField />
                </Label>
                <Input
                    name={"title"}

                    placeholder="Add title here"
                    type={"text"}
                    size={"sm"}
                /
                >


            </FormGroup>
        </Col>
        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Section"}>
                    Fiscal Year <RequiredField />
                </Label>
                <Input
                    name={"title"}

                    placeholder="Add title here"
                    type={"text"}
                    size={"sm"}
                /
                >


            </FormGroup>
        </Col>
        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Section"}>
                    Email <RequiredField />
                </Label>
                <Input
                    name={"title"}

                    placeholder="Add title here"
                    type={"text"}
                    size={"sm"}
                /
                >


            </FormGroup>
        </Col>
        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Section"}>
                    Contact <RequiredField />
                </Label>
                <Input
                    name={"title"}

                    placeholder="Add title here"
                    type={"text"}
                    size={"sm"}
                /
                >


            </FormGroup>
        </Col>
        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Section"}>
                    Religion <RequiredField />
                </Label>
                <Input
                    name={"classTeacherId"}

                    type={"select"}
                    size={"sm"}

                >
                    <option value={false}>Select religion</option>

                </Input>

            </FormGroup>
        </Col>
        <Col md="6" sm="6" xs='12'>
            <FormGroup >
                <Label for={"Section"}>
                    Languages <RequiredField />
                </Label>
                <Input
                    name={"classTeacherId"}

                    type={"select"}
                    size={"sm"}

                >
                    <option value={false}>Select languages</option>

                </Input>

            </FormGroup>
        </Col>
    </Row>
}