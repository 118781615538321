import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
    amountType: Yup.string().required('Amount Type is required'),
    amount: Yup.number().required('Amount is required').test(
        'Is positive?', 
        'ERROR: The number must be greater than 0!', 
        (value) => value > 0
    ),
});