import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
  client_name: Yup.string().required("Name is required"),
  client_phone: Yup.string()
    .matches(
      /^\d{11,15}$/,
      "Client number must be between 11 and 15 digits and contain only numbers"
    )
    .required("Client number is required"),
  // client_email: Yup.string()
  //   .email("Please give proper email")
  //   .required("Email is required"),
  branch_id: Yup.string().required("Please select branch"),
  appointment_type_id: Yup.string().required("Please select appointment type"),
  appointment_time: Yup.string().required("Please provide appointment time"),
  arrival_time: Yup.string().required("Please provide arrival time"),
  // preferred_staff_id :  Yup.string().required('Preffered staff is not selected'),
});
