import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import TableData from "./invoice-payment-data/InvoicePaymentData";
import {
  getTeachers,
  getTeacherDetail,
} from "../../../../../redux/branch-admin/teacherActions";
import { TABLE_COMPONENT_CONTEXT } from "../../../../../contexts/schoolRequests";
import { Filters } from "./invoice-payment-data/InvoicePaymentFilters";
import { CreateTeacher } from "../../../../../constant";
import { Link } from "react-router-dom";

export function InvoicePaymentGrid() {
  const [tableData, setTableData] = useState({
    total_records: 0,
    data: [],
  });
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [teacherId, setTeacherId] = useState(null);
  const dispatch = useDispatch();
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    pageSize: 5,
    sortBy: "id",
    sortDirection: "DESC",
  });

  const getTeachersRequest = async () => {
    dispatch(
      getTeachers(paginationConfig, (resp) => {
        if (resp.status) {
          setTableData({
            total_records: resp.data.pagination.totalCount,
            numPages: resp.data.pagination.numPages,
            data: resp.data.records,
          });
        }
      })
    );
  };

  useEffect(() => {
    getTeachersRequest();
  }, [paginationConfig]);

  const handlePagination = (paginationData) => {
    let temp = { ...paginationConfig };
    temp["pageNumber"] = paginationData.current;
    setPaginationConfig(temp);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    // setSearchReq(searchReq)
  };
  const handleActionId = (id) => {
    // dispatch(getTeacherDetail({ "teacherId": id }, (resp) => {
    // }))
  };

  const tableColumns = [
    {
      name: "Inoice #",
      selector: "inv_date",
      sortable: true,
      center: true,
    },
    {
      name: "Payment Date",
      selector: "inv_date",
      sortable: true,
      center: true,
    },
    {
      name: "Payment Method",
      selector: "class",
      sortable: true,
      center: true,
    },
    {
      name: "Amount",
      selector: "class",
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      selector: "actions",
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="Project" title="Invoice Payment" />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="pt-0">
                <Row className="p-3">
                  <Col sm="12">
                    <TABLE_COMPONENT_CONTEXT.Provider
                      value={{
                        paginationConfig,
                        handlePagination,
                        setPaginationConfig,
                        tableColumns,
                      }}
                    >
                      <div className="d-flex justify-content-end">
                        <Link
                          className="mt-4 btn btn-primary"
                          to="/invoice-payment/create"
                        >
                          {" "}
                          Create Invoice Payment
                        </Link>
                      </div>
                      <Filters />
                      <TableData
                        loading={false}
                        paginationConfig={paginationConfig}
                        data={tableData}
                        onClickSort={(value) => handleSorting(value)}
                        onClickPagination={(value) => handlePagination(value)}
                        onClickAction={(e) => handleActionId(e)}
                        setPaginationConfig={setPaginationConfig}
                      />
                    </TABLE_COMPONENT_CONTEXT.Provider>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
