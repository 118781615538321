import React, { Fragment, useContext } from "react";
import { TABLE_COMPONENT_CONTEXT } from "../../../../../contexts/schoolRequests";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

export function StaffFilters() {
    const { paginationConfig, setPaginationConfig } = useContext(TABLE_COMPONENT_CONTEXT)
    let handleSortBy = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["page_number"] = 1
        temp["order"] = input.value
        setPaginationConfig(temp)
    }
    let handleRecords = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["page_size"] = input.value
        setPaginationConfig(temp)
    }
    let handleStatus = ({ currentTarget: input }) => {
        let temp = { ...paginationConfig }
        temp["page_number"] = 1
        temp["status"] = input.value
        setPaginationConfig(temp)
    }
    return <Fragment>
        <Row>
            <Col md="3">
                <FormGroup>
                    <Label for="exampleSelect">
                        Sort By
                    </Label>
                    <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={handleSortBy}
                        size={"sm"}
                    >
                        <option value="ASC" selected={paginationConfig.order === "ASC"}>
                            Ascending
                        </option>
                        <option value="DESC" selected={paginationConfig.order === "DESC"}>
                            Descending
                        </option>

                    </Input>
                </FormGroup>

            </Col>
            <Col md="3">
                <FormGroup>
                    <Label for="exampleSelect">
                        Show Records
                    </Label>
                    <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={handleRecords}
                        size={"sm"}
                    >
                        {[5, 10, 15, 25, 50].map((value, key) => <option value={value} selected={paginationConfig.page_number === value}>
                            {value}
                        </option>)}


                    </Input>
                </FormGroup>
            </Col>

        </Row>
    </Fragment>
}