import React, { Fragment } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
// import { fields } from "./fields"
import { RequiredField } from "../../../../utils/fieldRequired";
import ErrorMessage from "../../../../utils/errorMessage";
import { Submit } from "../../../../../constant";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { useDispatch } from "react-redux";
// import { createTeacher } from "../../../../../redux/branch-admin/teacherActions"
import { toast } from "react-toastify";
import {
  createClass,
  getGrades,
  getGradesLevelSections,
} from "../../../../../redux/branch-admin/class/classActions";
import { Link } from "react-router-dom";
import { CreateGradeModal } from "../create-section/CreateSectionModal";
import { getTeachers } from "../../../../../redux/branch-admin/teacherActions";
import { useHistory } from "react-router-dom";
export function CreateClass() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [createSection, setCreateSection] = React.useState(false);
  const [grades, setGrades] = React.useState([]);
  const [gradeSection, setGradeSection] = React.useState([]);
  const [teachers, setTeachers] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const history = useHistory();
  const [paginationConfig, setPaginationConfig] = React.useState({
    pageNumber: 1,
    pageSize: 5,
    sortBy: "id",
    sortDirection: "DESC",
  });
  const dispatch = useDispatch();
  const initialValues = {
    gradeLevelId: "",
    // gradeLevelSectionId: '',
    classTeacherId: "",
    title: "",
  };
  React.useEffect(() => {
    fetchGrades();
  }, []);
  React.useEffect(() => {
    fetchTeachers(paginationConfig);
  }, [paginationConfig]);
  const fetchTeachers = (config) =>
    dispatch(
      getTeachers(config, (resp) => {
        let _teachers = [...teachers, ...resp.data.records];
        setHasMore(paginationConfig.pageNumber < resp.data.pagination.numPages);
        setTeachers(_teachers);
      })
    );
  const fetchGrades = () =>
    dispatch(
      getGrades((resp) => {
        setGrades(resp.data.gradeLevel);
      })
    );
  const getSectionGrades = async (id) => {
    setGradeSection([]);
    dispatch(
      getGradesLevelSections({ gradeLevelId: id }, (resp) => {
        setGradeSection(resp.data.gradeLevelSections);
      })
    );
  };
  const createGradeSection = async (_formValues) => {
    setCreateSection(!createSection);
  };
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && hasMore) {
      let _paginationConfig = { ...paginationConfig };
      _paginationConfig["pageNumber"] = _paginationConfig["pageNumber"] + 1;
      setPaginationConfig(_paginationConfig);
    }
  };
  const onSubmit = (values, formActions) => {
    dispatch(
      createClass(values, (resp) => {
        try {
          if (resp.status) {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            formActions.resetForm();
            history.goBack();
            toast.success("Class created successfully!");
          } else {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            toast.error(resp.message);
          }
        } catch (e) {}
      })
    );
  };
  return (
    <>
      <Fragment>
        <Breadcrumb parent="dashboard" title={"Create Section"} />

        <Container fluid={true}>
          <Card>
            <CardBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form className="p-3" onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6" sm="6" xs="12">
                        <FormGroup>
                          <Label for={"Class"}>
                            Class <RequiredField />
                          </Label>
                          <Input
                            name={"gradeLevelId"}
                            onChange={(e) => {
                              handleChange(e);
                              getSectionGrades(e.target.value);
                            }}
                            type={"select"}
                            value={values["gradeLevelId"]}
                            size={"sm"}
                          >
                            <option>Select Class</option>
                            {grades.map(({ title, id }, index) => (
                              <option
                                key={index}
                                value={id}
                                selected={values["gradeLevelId"]}
                              >
                                {title}
                              </option>
                            ))}
                          </Input>
                          {errors["gradeLevelId"] &&
                            touched["gradeLevelId"] && (
                              <ErrorMessage message={errors["gradeLevelId"]} />
                            )}
                        </FormGroup>
                      </Col>
                      {/* <Col md="6" sm="6" xs='12'>
                                            <FormGroup >
                                                <Label for={"Section"}>
                                                    Section <RequiredField />
                                                </Label>
                                                <Input
                                                    name={"gradeLevelSectionId"}
                                                    onChange={e => {
                                                        handleChange(e)

                                                    }}
                                                    type={"select"}
                                                    value={values["gradeLevelSectionId"]}

                                                >
                                                    <option value={false}>Select section</option>
                                                    {gradeSection.map(({ title, id }, index) => <option key={index} value={id} selected={
                                                        values["gradeLevelSectionId"]
                                                    }>
                                                        {title}
                                                    </option>)}
                                                </Input>
                                                <Link to="#" onClick={e => createGradeSection(values)}><small>Create Section</small></Link>

                                                {errors["gradeLevelSectionId"] && touched["gradeLevelSectionId"] && (
                                                    <ErrorMessage message={errors["gradeLevelSectionId"]} />
                                                )}
                                            </FormGroup>
                                        </Col> */}
                      <Col md="6" sm="6" xs="12">
                        <FormGroup>
                          <Label for={"Section"}>
                            Teacher Incharge <RequiredField />
                          </Label>
                          <Input
                            name={"classTeacherId"}
                            onScroll={handleScroll}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            type={"select"}
                            value={values["classTeacherId"]}
                            size={"sm"}
                          >
                            <option value={false}>Select teacher</option>
                            {teachers.map(
                              ({ firstName, lastName, id }, index) => (
                                <option
                                  key={index}
                                  value={id}
                                  selected={values["classTeacherId"]}
                                >
                                  {`${firstName} ${lastName}`}
                                </option>
                              )
                            )}
                          </Input>
                          {errors["classTeacherId"] &&
                            touched["classTeacherId"] && (
                              <ErrorMessage
                                message={errors["classTeacherId"]}
                              />
                            )}
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="6" xs="12">
                        <FormGroup>
                          <Label for={"Section"}>
                            Title <RequiredField />
                          </Label>
                          <Input
                            name={"title"}
                            onScroll={handleScroll}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Add title here"
                            type={"text"}
                            value={values["title"]}
                            size={"sm"}
                          />

                          {errors["title"] && touched["title"] && (
                            <ErrorMessage message={errors["title"]} />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-right">
                      <Button
                        color="danger"
                        className="mr-1"
                        type="button"
                        onClick={() => history.goBack()}
                      >
                        Back
                      </Button>
                      <Button
                        color="primary"
                        className="mr-1"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {Submit}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Container>
        <CreateGradeModal
          open={createSection}
          setOpen={setCreateSection}
          grades={grades}
          fetchGrades={fetchGrades}
        />
      </Fragment>
    </>
  );
}
