import React, { useContext } from "react";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { RequiredField } from "../../../../../utils/fieldRequired";
import { STUDENT_DETAIL } from "../../../../../../contexts";
import { Formik } from "formik";
import { medicalValidation } from "./validationSchemas";
import ErrorMessage from "../../../../../utils/errorMessage";
import { student_medical_add } from "../../../../../../redux/endpoints";
import { apiCall } from "../../../../../../redux/apiCall";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
export function MedicalInformation() {
  const dispatch = useDispatch();
  const { studentDetail, setStudentDetail } = useContext(STUDENT_DETAIL);
  const { id } = useParams();
  const [initialValues, setInitialValues] = React.useState({
    height: "",
    weight: "",
    massIndex: "",
    bloodGroup: "",
  });
  const onSubmit = (values, formActions) => {
    dispatch(
      apiCall(
        student_medical_add,
        "POST",
        { ...values, studentId: id },
        (resp) => {
          try {
            if (resp.status) {
              formActions.setSubmitting(false);
              formActions.resetForm();
              toast.success(
                `Student medical record ${
                  initialValues?.id ? "updated" : "added"
                } successfully!`
              );
              let _studentDetail = { ...studentDetail };
              _studentDetail.studentMedicalInfo = values;
              setStudentDetail(_studentDetail);
            } else {
              formActions.setSubmitting(false);

              toast.error(resp.message);
            }
          } catch (e) {}
        }
      )
    );
  };
  React.useEffect(() => {
    if (studentDetail?.studentMedicalInfo) {
      setInitialValues(studentDetail.studentMedicalInfo);
    }
  }, [studentDetail?.studentMedicalInfo]);
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={medicalValidation}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"Grade"}>
                    Height <RequiredField />
                  </Label>
                  <Input
                    name={"height"}
                    type={"text"}
                    size={"sm"}
                    defaultValue={initialValues.height}
                    onChange={handleChange}
                  ></Input>
                  {errors["height"] && touched["height"] && (
                    <ErrorMessage message={errors["height"]} />
                  )}
                </FormGroup>
              </Col>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"weight"}>
                    Weight <RequiredField />
                  </Label>
                  <Input
                    name={"weight"}
                    type={"text"}
                    size={"sm"}
                    defaultValue={initialValues.weight}
                    onChange={handleChange}
                  ></Input>
                  {errors["weight"] && touched["weight"] && (
                    <ErrorMessage message={errors["weight"]} />
                  )}
                </FormGroup>
              </Col>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"massIndex"}>
                    Mass Index <RequiredField />
                  </Label>
                  <Input
                    name={"massIndex"}
                    type={"text"}
                    size={"sm"}
                    defaultValue={initialValues.massIndex}
                    onChange={handleChange}
                  ></Input>
                  {errors["massIndex"] && touched["massIndex"] && (
                    <ErrorMessage message={errors["massIndex"]} />
                  )}
                </FormGroup>
              </Col>
              <Col md="6" sm="6" xs="12">
                <FormGroup>
                  <Label for={"bloodGroup"}>
                    Blood Group <RequiredField />
                  </Label>
                  <Input
                    name={"bloodGroup"}
                    type={"text"}
                    size={"sm"}
                    defaultValue={initialValues.bloodGroup}
                    onChange={handleChange}
                  ></Input>
                  {errors["bloodGroup"] && touched["bloodGroup"] && (
                    <ErrorMessage message={errors["bloodGroup"]} />
                  )}
                </FormGroup>
              </Col>
              {/* <Col md="6" sm="6" xs='12'>
                            <FormGroup >
                                <Label for={"Grade"}>
                                    Vaccination <RequiredField />
                                </Label>
                                <Input
                                    name={"gradeLevelId"}
                                    type={"file"}
                                    size={"sm"}
                                >

                                </Input>
                                {errors["city"] && touched["city"] && (
                                    <ErrorMessage message={errors["city"]} />
                                )}
                            </FormGroup>
                        </Col> */}
            </Row>
            <div className="text-right">
              <Button
                color="primary"
                className="mr-1"
                type="submit"
                disabled={isSubmitting}
              >
                {initialValues?.id ? "Update" : "Save"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
