import { INSTANCE } from "../../config/axiosInstance";

export default class GradeApi {
    createGrade = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/school-admin/grade/create",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    updateGrade = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/school-admin/grade/update",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    getGrade = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "GET",
                url: "/school-admin/grade",
            })
                .then(resolve)
                .catch(reject);
        });
    };
    branchById = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/school-admin/branch-details",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    updateBranch = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/school-admin/branch/update",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };

}
