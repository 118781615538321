export const fields = [{
    name: "branchName",
    type: 'text',
    label: "Branch name",
    placeholder: "Branch name",
    required: true,

}, {
    name: "branchEmail",
    type: "email",
    label: "Branch email",
    placeholder: "Branch email",
    required: true,

}, {
    name: "branchPhone",
    type: "text",
    label: "Branch phone",
    placeholder: "Branch phone",
    required: true,

},
{
    name: "address",
    type: "text",
    label: "Address",
    placeholder: "Address",
    required: true,

},
{
    name: "studentsCount",
    type: "number",
    label: "Students count",
    placeholder: "",
    required: true,

}, {
    name: "teachersCount",
    type: "number",
    label: "Teachers count",
    placeholder: "",
    required: true,

}]