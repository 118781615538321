import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import ClientNotes from "./ClientNotes";
import ClientEditModal from "./ClientEditModal";
import ClientEditLogModal from "./ClientEditLogModal";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Media,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import axios from "axios";
import { MonthArray } from "../../constant";
import { toast } from "react-toastify";
import ChangeAllocationDialog from "./changeAllocationDialog";

const ClientDetails = (props) => {
  const [detailsArray, setDetailsArray] = useState({});
  const [details, setDetails] = useState({});
  const clientId = props.clientId;
  const [clientEditModal, setClientEditModal] = useState(false);
  const clientEditModalToggle = () => setClientEditModal(!clientEditModal);
  const [clientEditLogModal, setClientEditLogModal] = useState(false);
  const clientEditLogModalToggle = () =>
    setClientEditLogModal(!clientEditLogModal);

  const [lastEditedOn, setLastEditedOn] = useState("");

  const MapClientObject = (resultObject) => {
    let clientObject = {
      ID: resultObject.id,
      Name: resultObject.name,
      "National ID": resultObject.cnic,
      Email: resultObject.email,
      Cell: resultObject.clientCellsData[0].cell,
      Landline: resultObject.phone,
      Address: resultObject.address,
      "Zip Code": resultObject.zip,
      Occupation: resultObject.occupation,
      Country:
        resultObject.locationIdData.parentLocationData.parentLocationData.title,
      City: resultObject.locationIdData.parentLocationData.title,
      Locality: resultObject.locationIdData.title,
      Type: resultObject.clientTypeData.title,
      "Minimum Income": resultObject.minIncome,
      "Maximum Income": resultObject.maxIncome,
      "Client Rating": resultObject.clientRatingData.title,
      "Buying Intent": resultObject.clientBuyingIntentData
        ? resultObject.clientBuyingIntentData.displayName
        : null,
      Gender: resultObject.gender,
      "Passport Number": resultObject.passport,
      "Edit Reason": resultObject.ClientEditLogData.lenght
        ? resultObject.ClientEditLogData[0].reason
        : "",
      Interest: "N/A",
    };
    return clientObject;
  };
  useEffect(() => {
    const endpoint = localStorage.getItem("api-endpoint");
    const token = localStorage.getItem("token");
    fetch(endpoint + "clients/" + clientId, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setDetailsArray(MapClientObject(result.package.data));
          setDetails(result.package.data);
          let editLog = result.package.data.ClientEditLogData;
          if (editLog) {
            let date = new Date(editLog[0].createdAt);
            let dateString =
              date.getDate() +
              " " +
              MonthArray[date.getMonth()] +
              " " +
              date.getFullYear();
            setLastEditedOn(dateString);
          }
        },
        (error) => {
          toast.error(error);
        }
      );
  }, []);

  return (
    <Fragment>
      <ChangeAllocationDialog
        clientId={clientId}
        assignedTo={details.assignedToData}
      />
      <Row>
        <Col xl="5">
          <Card>
            <CardHeader>
              <Media
                className="img-70 rounded-circle"
                alt=""
                src={require("../../assets/images/user/7.jpeg")}
              />
              <h4 className="card-title mb-0">{detailsArray.Name}</h4>
              <div className="card-options">
                <a className="card-options-collapse" href="#javascript">
                  <i className="fe fe-chevron-up"></i>
                </a>
                <a className="card-options-remove" href="#javascript">
                  <i className="fe fe-x"></i>
                </a>
              </div>
            </CardHeader>
            <CardBody>
              {Object.entries(detailsArray).map(([index, value]) => (
                <Row key={index}>
                  <Col lg="4">
                    <p>
                      <strong>{index}:</strong>
                    </p>
                  </Col>
                  <Col lg="6" className="text-left">
                    {value ? <p>{value}</p> : <p>N/A</p>}
                  </Col>
                </Row>
              ))}
              <Button color="primary" onClick={clientEditModalToggle}>
                {"Edit Client"}
              </Button>
              {clientEditModal ? (
                <ClientEditModal
                  clientDetails={details}
                  clientEditModal={clientEditModal}
                  clientEditModalToggle={clientEditModalToggle}
                />
              ) : (
                ""
              )}

              {lastEditedOn ? (
                <p>
                  Client was last updated on{" "}
                  <span className="text-primary">{lastEditedOn}</span>. To view
                  client change-log{" "}
                  <a
                    className="text-primary"
                    onClick={clientEditLogModalToggle}
                  >
                    {"click here"}
                  </a>
                </p>
              ) : (
                lastEditedOn
              )}

              <ClientEditLogModal
                clientId={clientId}
                clientEditLogModal={clientEditLogModal}
                clientEditLogModalToggle={clientEditLogModalToggle}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xl="7">
          <ClientNotes clientId={clientId} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default ClientDetails;
