import React, { Fragment, useContext } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useCallback } from "react";
import DataTable from "react-data-table-component";
import differenceBy from "lodash/differenceBy";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { TABLE_COMPONENT_CONTEXT } from "../../contexts/schoolRequests";

const TableComponent = (props) => {
  const [data, setData] = useState([]);
  const { paginationConfig, handlePagination, staticPagination } =
    React.useContext(TABLE_COMPONENT_CONTEXT);
  const [paginationButtons, setPaginationButtons] = useState({
    current: 1,
    previous_disabled: true,
    next_disabled: false,
  });
  const [tableColumns, setTableColumn] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  useEffect(() => {
    setData(props.tableConfig.data);
    setTableColumn(props.tableConfig.tableColumns);
  }, [props]);

  const handlePaginationClick = (pageNumber) => {
    let pagination = {};
    let current_pagination = { ...paginationButtons };
    if (pageNumber === "previous") {
      if (paginationButtons.current >= 1) {
        pagination = {
          current: current_pagination.current - 1,
          previous_disabled: false,
        };
      } else {
        pagination = {
          previous_disabled: true,
        };
      }
    } else if (pageNumber === "next") {
      pagination = {
        current:
          current_pagination.current + 1 < props.numPages
            ? current_pagination.current + 1
            : props.numPages,
        previous_disabled: false,
        next_disabled:
          current_pagination.current + 1 < props.numPages ? false : true,
      };
    } else {
      pagination = {
        current: pageNumber + 1,
        previous_disabled: pageNumber + 1 === 1 ? true : false,
        next_disabled: pageNumber + 1 === props.numPages ? true : false,
      };
    }
    setPaginationButtons(pagination);

    handlePagination(pagination);
  };
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.name
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, "name"));
        toast.success("Successfully Deleted !");
      }
    };

    return (
      <button key="delete" className="btn btn-danger" onClick={handleDelete}>
        Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  if (data.length || !paginationConfig.hideOnNoData) {
    var pagination = (
      <div className="mt-3">
        <Pagination aria-label="...">
          <ul className="pagination pagination-primary">
            <PaginationItem disabled={paginationButtons.current === 1}>
              <PaginationLink onClick={() => handlePaginationClick("previous")}>
                previous
              </PaginationLink>
            </PaginationItem>
            {[...Array(props.numPages)].map((_, index) => (
              <PaginationItem
                key={index}
                active={paginationButtons.current === index + 1}
              >
                <PaginationLink onClick={() => handlePaginationClick(index)}>
                  {index + 1}
                  {paginationButtons.current === index + 1 && (
                    <span className="sr-only">(current)</span>
                  )}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem disabled={paginationButtons.next_disabled}>
              <PaginationLink onClick={() => handlePaginationClick("next")}>
                next
              </PaginationLink>
            </PaginationItem>
          </ul>
        </Pagination>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="table-responsive">
        <DataTable
          data={data}
          columns={tableColumns}
          striped={true}
          center={false}
          // selectableRows
          persistTableHead
          contextActions={contextActions}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleCleared}
          pagination={staticPagination ? true : false}
        />
      </div>

      <div className="d-flex align-items-baseline justify-content-end">
        {/* <div>
                    <h6>Total Records : {props.totalRecords}</h6>
                </div> */}
        {staticPagination ? "" : pagination}
      </div>
    </Fragment>
  );
};

export default TableComponent;
