import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { withRouter, useHistory, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Formik } from "formik";

import * as Yup from "yup";
import { apiCall } from "../../../redux/apiCall";
import { update_password } from "../../../constant/url";
import ErrorMessage from "../../utils/errorMessage";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
      "Password must include at least one uppercase letter, one lowercase letter, and one symbol"
    ),
  password_confirmation: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const ForgotPasswordRedirect = (props) => {
  const initialValues = {
    password: "",
    password_confirmation: "",
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const onSubmit = (values, formActions) => {
    const _values = {
      ...values,
      email,
      token,
    };
    dispatch(
      apiCall(update_password, "POST", _values, (resp) => {
        if (resp.status) {
          formActions.setSubmitting(false);
          formActions.resetForm();
          toast.dark("Password Updated successfully!");
          setTimeout(() => {
            history.push("/", { replace: true });
          }, 2000);
        } else {
          formActions.setSubmitting(false);
          toast.error(resp.message);
        }
      })
    );
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img
                    className="img-fluid for-light"
                    style={{ height: "65px" }}
                    src={require("../../../assets/images/logo/login.png")}
                    alt=""
                  />
                  <img
                    className="img-fluid for-dark"
                    style={{ height: "65px" }}
                    src={require("../../../assets/images/logo/logo_dark.png")}
                    alt=""
                  />
                </a>
              </div>
              <div className="login-main login-tab">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <Form className="theme-form" onSubmit={handleSubmit}>
                      <h4>Forgot Password</h4>

                      <FormGroup>
                        <Label className="col-form-label">
                          {"New Password"}
                        </Label>
                        <div></div>
                        <Input
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                        />

                        <small>
                          The password must include at least one uppercase and
                          one lowercase letter, as well as one symbol.
                        </small>
                        {errors["password"] && touched["password"] && (
                          <ErrorMessage message={errors["password"]} />
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Confirm Password"}
                        </Label>
                        <div></div>
                        <Input
                          type="password"
                          name="password_confirmation"
                          value={values.password_confirmation}
                          onChange={handleChange}
                        />
                      </FormGroup>
                      {errors["password_confirmation"] &&
                        touched["password_confirmation"] && (
                          <ErrorMessage
                            message={errors["password_confirmation"]}
                          />
                        )}
                      <div className="form-group mb-0">
                        <Button
                          color="primary"
                          className="btn-block"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {"Reset Password"}
                        </Button>
                        <p className="mt-4 mb-0">
                          <Link to="/">Back to Login</Link>
                        </p>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(ForgotPasswordRedirect);
