import React, { useState, useEffect } from "react";
import man from "../../../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
import { withRouter, useHistory } from "react-router-dom";
import {
  Password,
  EmailAddress,
  JWT,
  LoginWithJWT,
  SignIn,
  SuperAdminAPIUrl,
  RequestError,
  APIUrl,
} from "../../../constant";
import axios from "axios";
import { useForm } from "react-hook-form";
import validator from "../../../helper/validation";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../redux/auth/authActions";
import { Link } from "react-router-dom/cjs/react-router-dom";
const ForgotPassword = (props) => {
  const { register, handleSubmit, errors } = useForm();

  const initalState = {
    email: "",
  };
  const [formData, setFormData] = useState(initalState);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    dispatch(
      forgotPassword(formData, async (data) => {
        if (data.status == 200) {
          toast.dark("Email sent to your provide email!");
        } else {
          toast.error(data.message);
        }
      })
    );
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img
                    className="img-fluid for-light"
                    style={{ height: "65px" }}
                    src={require("../../../assets/images/logo/login.png")}
                    alt=""
                  />
                  <img
                    className="img-fluid for-dark"
                    style={{ height: "65px" }}
                    src={require("../../../assets/images/logo/logo_dark.png")}
                    alt=""
                  />
                </a>
              </div>
              <div className="login-main login-tab">
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <h4>Forgot Password</h4>

                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <div>
                      <small>
                        A reset password email will be sent to your provided
                        email address
                      </small>
                    </div>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Test@gmail.com"
                      innerRef={register({
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors.email && "Please enter proper email address"}
                    </span>
                  </FormGroup>

                  <div className="form-group mb-0">
                    <Button color="primary" className="btn-block" type="submit">
                      {"Send"}
                    </Button>
                    <p className="mt-4 mb-0">
                      <Link to="/">Back to Login</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(ForgotPassword);
