import React, { Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardHeader,
} from "reactstrap";
import { CreateSaloon } from "./Create Saloon/CreateSaloon";
import { useDispatch } from "react-redux";
import { get_saloon_qna, get_saloon_by_id } from "../../../constant/url";
import { apiCall } from "../../../redux/apiCall";
import { SaloonDetail } from "./Saloon Detail/SaloonDetail";
import {
  Email,
  MarekjecnoMailId,
  BOD,
  DDMMYY,
  Designer,
  ContactUs,
  ContactUsNumber,
  LocationDetails,
  JOHANDIO,
  UserProfileDesc1,
  UserProfileDesc2,
  UserProfileDesc3,
  Comment,
  MarkJecno,
  Like,
  Follower,
  Following,
  Location,
} from "../../../constant";
import "./style.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
export function SaloonDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [saloonData, setSaloonData] = React.useState(null);
  const [qna, setQNA] = React.useState([]);
  const [url, setUrl] = React.useState();

  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result);
    };
  };
  React.useEffect(() => {
    let saloon = localStorage.getItem("saloon");
    if (saloon) {
      fetchSaloonDetails(saloon.id);
    } else {
      fetchQNA();
    }
  }, []);
  const fetchQNA = () =>
    dispatch(
      apiCall(get_saloon_qna, "GET", {}, (resp) => {
        setQNA(resp.data);
      })
    );
  const fetchSaloonDetails = (id) =>
    dispatch(
      apiCall(get_saloon_by_id, "POST", { id }, (resp) =>
        setSaloonData(resp.data.saloon)
      )
    );
  return (
    <Fragment>
      <Breadcrumb parent="Salon" title="Salon Detail" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <CardHeader
                  className="cardheader"
                  style={{
                    background: `url(${"https://mcdn.wallpapersafari.com/medium/34/9/b84Kks.jpg"})`,
                  }}
                ></CardHeader>
                <div className="user-image">
                  <div className="avatar">
                    <Media
                      body
                      alt=""
                      src={
                        url
                          ? url
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn8b3u5VzkYAdu1kEON3M2dGq_FvPA-g5onQ&usqp=CAU"
                      }
                      data-intro="This is Profile image"
                    />
                  </div>
                </div>
                <div className="info">
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-left">
                            <h6>
                              <i className="fa fa-envelope mr-2"></i> {Email}
                            </h6>
                            <span>{MarekjecnoMailId}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6>
                              <i className="fa fa-calendar"></i>   {BOD}
                            </h6>
                            <span>{DDMMYY}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title">
                          <a target="_blank" href="#javascript">
                            {saloonData?.name}{" "}
                          </a>
                        </div>
                        <div className="desc mt-2">
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              history.push(`/public/salon/${saloonData?.id}`)
                            }
                          >
                            {"View public page"}
                          </span>{" "}
                          <i
                            className="fa fa-clipboard cursor-pointer"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${window.location.origin}/public/salon/${saloonData?.id}`
                              );
                              toast.dark("Link Copied!", { autoClose: 1000 });
                            }}
                          ></i>
                        </div>
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-xs-mt">
                            <h6>
                              <i className="fa fa-phone"></i>   {ContactUs}
                            </h6>
                            <span>{ContactUsNumber}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6>
                              <i className="fa fa-location-arrow"></i>   
                              {Location}
                            </h6>
                            <span>{LocationDetails}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <div
                    className="social-media step4"
                    data-intro="This is your Social details"
                  >
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-rss"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="follow">
                    <Row>
                      <Col col="6" className="text-md-right border-right">
                        <div className="follow-num counter">
                          {saloonData?.employee_count}
                        </div>
                        <span>{"Employee Count"}</span>
                      </Col>
                      <Col col="6" className="text-md-left">
                        <div className="follow-num counter">
                          {saloonData?.branch_count}
                        </div>
                        <span>{"Branch Count"}</span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}
