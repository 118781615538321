import React, { Fragment } from "react"
import Breadcrumbs from "../../../../../layout/breadcrumb"
import { Container, Row, Col, Card, CardBody, Button, CardHeader, Collapse } from "reactstrap"
import { PersonalInformation } from "../create-student/PersonalInformation"
import { PreviousSchoolInformation } from '../create-student/PreviousSchoolInformation';
import { BankDetails } from "../create-student/BankDetails"
import { AccountsDetails } from "../create-student/AccountsDetails"
import { apiCall } from "../../../../../redux/apiCall";
import { student_by_id } from "../../../../../redux/endpoints";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { STUDENT_DETAIL } from "../../../../../contexts/index"
import { Loader } from "../../../../utils/Loader";
export function StudentDetail() {
    const dispatch = useDispatch()
    const { id } = useParams()
    const [activeButton, setActiveButton] = React.useState("1")
    const [loading, setLoading] = React.useState(true)
    const [activeCollapse, setActiveCollpase] = React.useState({
        personal_details: true,
        bank_details: true,
        prev_school: true,
        accounts: true
    })
    const [studentDetail, setStudentDetail] = React.useState(null)
    const handleActiveButton = (id) => {
        // switch (id) {
        //     case "1":
        //         setActiveCollpase({ ...activeCollapse, personal_details: true })
        //         break;
        //     case "2":
        //         setActiveCollpase({ ...activeCollapse, bank_details: true })
        //         break;

        //     case "3":
        //         setActiveCollpase({ ...activeCollapse, accounts: true })
        //         break;

        //     case "4":
        //         setActiveCollpase({ ...activeCollapse, prev_school: true })
        //         break;

        //     default:
        //         break;
        // }
        // const elem = document.getElementById(`card${id}`)
        // elem.scrollIntoView({ behavior: "smooth" })
        setActiveButton(id)
    }
    const getStudentDetail = async () => {
        dispatch(apiCall(student_by_id, 'POST', { studentId: id }, (resp) => {
            setStudentDetail(resp.data)
            setLoading(false)
        }))
    }
    React.useEffect(() => {
        getStudentDetail()
    }, [])
    return <>
        <Fragment>
            <Breadcrumbs parent="Dashboard" title="Student Detail" />
            <Container fluid={true}>
                <Row>
                    <Col sm="3">
                        <div >
                            <Button color="primary" outline={activeButton !== "1"} block={true} onClick={() => handleActiveButton("1")}>Personal Information</Button>
                            <Button color="primary" outline={activeButton !== "2"} block={true} onClick={() => handleActiveButton("2")}>Previous School</Button>
                            <Button color="primary" outline={activeButton !== "3"} block={true} onClick={() => handleActiveButton("3")}>Bank Details</Button>
                            <Button color="primary" outline={activeButton !== "4"} block={true} onClick={() => handleActiveButton("4")}>Accounts</Button>
                        </div>
                    </Col>
                    <Col sm="9">
                        {loading ? <Loader /> : <STUDENT_DETAIL.Provider value={{ studentDetail, setStudentDetail }}>
                            {activeButton === "1" && <Card id="card1">
                                <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h6>Personal Details</h6>
                                    {/* <div style={{ cursor: "pointer" }} onClick={() => setActiveCollpase({ ...activeCollapse, personal_details: !activeCollapse.personal_details })}>{activeCollapse.personal_details ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}</div> */}
                                </CardHeader>
                                <Collapse isOpen={activeCollapse.personal_details}>
                                    <CardBody className='pt-0'>
                                        <PersonalInformation />
                                    </CardBody>
                                </Collapse>

                            </Card>}
                            {activeButton === "2" && <Card id="card4">
                                <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h6>Previous School</h6>
                                    {/* <div style={{ cursor: "pointer" }} onClick={() => setActiveCollpase({ ...activeCollapse, prev_school: !activeCollapse.prev_school })}>{activeCollapse.prev_school ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}</div> */}
                                </CardHeader>
                                <Collapse isOpen={activeCollapse.prev_school}>
                                    <CardBody className='pt-0'>
                                        <PreviousSchoolInformation />
                                    </CardBody>
                                </Collapse>

                            </Card>}
                            {activeButton === "3" && <Card id="card2">
                                <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h6>Bank Details</h6>
                                    {/* <div style={{ cursor: "pointer" }} onClick={() => setActiveCollpase({ ...activeCollapse, bank_details: !activeCollapse.bank_details })}>{activeCollapse.bank_details ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}</div> */}
                                </CardHeader>
                                <Collapse isOpen={activeCollapse.bank_details}>
                                    <CardBody className='pt-0'>
                                        <BankDetails />
                                    </CardBody>
                                </Collapse>

                            </Card>}
                            {activeButton === "4" && <Card id="card3">
                                <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h6>Accounts</h6>
                                    {/* <div style={{ cursor: "pointer" }} onClick={() => setActiveCollpase({ ...activeCollapse, accounts: !activeCollapse.accounts })}>{activeCollapse.accounts ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}</div> */}
                                </CardHeader>
                                <Collapse isOpen={activeCollapse.accounts}>
                                    <CardBody className='pt-0'>

                                        <AccountsDetails />

                                    </CardBody>
                                </Collapse>

                            </Card>}
                        </STUDENT_DETAIL.Provider>}
                    </Col>
                </Row>
            </Container>

        </Fragment>
    </>
}