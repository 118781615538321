import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { EditTeacher, Update } from "../../../../../constant";
import { useDispatch } from "react-redux";
import { Loader } from "../../../../utils/Loader";
import { Form, Row, FormGroup, Input, Label, Col, Button } from "reactstrap";
import { RequiredField } from "../../../../utils/fieldRequired";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import ErrorMessage from "../../../../utils/errorMessage";
import { toast } from "react-toastify";
import { createGradeSection } from "../../../../../redux/branch-admin/class/classActions";

export function CreateGradeModal({ grades, open, setOpen, fetchGrades }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({
    gradeLevelId: "",
    title: "",
  });
  const dispatch = useDispatch();

  const onSubmit = (values, formActions) => {
    dispatch(
      createGradeSection(values, (resp) => {
        try {
          if (resp.status) {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            setOpen(!open);
            fetchGrades();
            formActions.resetForm();
            toast.success("Section created successfully!");
          } else {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            toast.error(resp.message);
          }
        } catch (e) {}
      })
    );
  };
  return (
    <Modal isOpen={open} toggle={() => setOpen(!open)} size="md">
      <ModalHeader toggle={() => setOpen(!open)}>Create Section</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
              }) => (
                <Form className="p-3" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12" sm="12" xs="12">
                      <FormGroup>
                        <Label for={"Grade"}>
                          Grade <RequiredField />
                        </Label>
                        <Input
                          name={"gradeLevelId"}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type={"select"}
                          value={values["gradeLevelId"]}
                        >
                          <option>Select grade</option>
                          {grades.map(({ title, id }, index) => (
                            <option
                              key={index}
                              value={id}
                              selected={values["gradeLevelId"]}
                            >
                              {title}
                            </option>
                          ))}
                        </Input>
                        {errors["gradeLevelId"] && touched["gradeLevelId"] && (
                          <ErrorMessage message={errors["gradeLevelId"]} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="12" sm="12" xs="12">
                      <FormGroup>
                        <Label for={"Grade"}>
                          Title <RequiredField />
                        </Label>
                        <Input
                          name={"title"}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type={"text"}
                          value={values["title"]}
                        />

                        {errors["title"] && touched["title"] && (
                          <ErrorMessage message={errors["title"]} />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between">
                    <Button
                      color="danger"
                      className="mr-1"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      className="mr-1"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
