

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";

const columns = [
  {
    name: "ID",
    selector: "id",
    sortable: true,
  },
  {
    name: "First Name",
    selector: "first_name",
    sortable: true,
  },
  {
    name: "Last Name",
    selector: "last_name",
    sortable: true,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
  },
];

const ServerSideTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const fetchUsers = async (page, perPage, sortField, sortDirection, searchText) => {
    setLoading(true);

    const response = await axios.get(`https://reqres.in/api/users`, {
      params: {
        page,
        per_page: perPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        search_text: searchText,
      },
    });

    const { data, total } = response.data;

    setData(data);
    setTotalRows(total);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1, 10, "id", "asc", "");
  }, []);
  
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage, '', '', '');
  };

  const handlePageChange = (page) => {
    fetchUsers(page, 10, '', '', '');
  };

  const handleSort = (column, sortDirection) => {
    const sortField = column.selector;
    const sortOrder = sortDirection === 'asc' ? 'asc' : 'desc';
    fetchUsers(1, 10, sortField, sortOrder, '');
  };

  const handleSearch = async (searchText) => {
    fetchUsers(1, 10, "", "", searchText);
  };

  return (
    <DataTable
      title="Users"
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      onSort={handleSort}
      onSearch={handleSearch}
    />
  );
};

export default ServerSideTable;








