import React from "react";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { RequiredField } from "../../../../utils/fieldRequired";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  student_bank_details,
  student_bank_details_add,
  student_bank_details_delete,
  student_bank_details_update,
} from "../../../../../redux/endpoints";
import { apiCall } from "../../../../../redux/apiCall";
import ErrorMessage from "../../../../utils/errorMessage";
import { Loader } from "../../../../utils/Loader";
export function BankDetails() {
  const [bankInfo, setbankInfo] = React.useState([{ type: "account-number" }]);
  const [loading, setLoading] = React.useState(true);
  const { id } = useParams();
  const [errors, setErrors] = React.useState([{}]);
  const dispatch = useDispatch();
  const removebankInfo = (index) => {
    let originalRecords = [...bankInfo];
    let values = { ...originalRecords[index] };
    if (values && values.id) {
      dispatch(
        apiCall(
          student_bank_details_delete,
          "POST",
          { studentId: id, bankDetailId: values.id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Previous school record removed successfully!");
                let records = originalRecords.filter(
                  (item, key) => key !== index
                );
                setbankInfo(records)(records);
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    } else {
      let records = originalRecords.filter((item, key) => key !== index);
      setbankInfo(records)(records);
    }
  };
  const fetchBankDetails = () => {
    dispatch(
      apiCall(student_bank_details, "POST", { studentId: id }, (resp) => {
        if (resp.data.length) {
          setbankInfo(resp.data);
        }
        setLoading(false);
      })
    );
  };
  React.useState(() => {
    fetchBankDetails();
  }, []);
  const handleChange = ({ currentTarget: input }, index) => {
    let _bankInfo = [...bankInfo];
    let _errors = [...errors];
    _errors[index][`${input.name}`] = null;
    _bankInfo[index][input.name] = input.value;
    setbankInfo(_bankInfo);
    setErrors(_errors);
  };

  const onSubmit = (values, index) => {
    let e = checkErrors(values, index);
    if (Object.keys(e).length) {
      let _errors = [...errors];
      _errors[index] = e;
      setErrors(_errors);
      return;
    }

    if (values.id) {
      dispatch(
        apiCall(
          student_bank_details_update,
          "POST",
          { ...values, studentId: id, bankDetailId: values.id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Bank details updated successfully!");
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    } else {
      dispatch(
        apiCall(
          student_bank_details_add,
          "POST",
          { ...values, studentId: id },
          (resp) => {
            try {
              if (resp.status) {
                toast.success("Bank details added successfully!");
                let _bankInfo = [...bankInfo];
                _bankInfo[index] = resp.data;
                setbankInfo(_bankInfo);
              } else {
                toast.error(resp.message);
              }
            } catch (e) {}
          }
        )
      );
    }
  };
  const checkErrors = (values) => {
    let _errors = {};
    if (!values.bankCode || !values.bankCode.length) {
      _errors.bankCode = "Provide Bank Code";
    }
    if (!values.holderName || !values.holderName.length) {
      _errors.holderName = "Provide Holder name";
    }
    if (!values.accountTitle || !values.accountTitle.length) {
      _errors.accountTitle = "Provide account title";
    }
    if (!values.accountNo || !values.accountNo.length) {
      _errors.accountNo = "Provide account no.";
    }

    return _errors;
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      {bankInfo.map((record, index) => (
        <div className="py-2">
          <div className="d-flex justify-content-between w-100">
            <h6 className="text-primary">{index + 1} .</h6>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => removebankInfo(index)}
            >
              <i className="fa fa-times text-danger"></i>
            </div>{" "}
          </div>
          <Row>
            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"bankCode"}>
                  Bank Code <RequiredField />
                </Label>
                <Input
                  name={"bankCode"}
                  value={record.bankCode}
                  onChange={(e) => handleChange(e, index)}
                  type={"text"}
                  size={"sm"}
                />
                {errors[index]?.bankCode && (
                  <ErrorMessage message={errors[index]["bankCode"]} />
                )}
              </FormGroup>
            </Col>
            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"holderName"}>
                  Holder Name <RequiredField />
                </Label>
                <Input
                  name={"holderName"}
                  value={record.holderName}
                  onChange={(e) => handleChange(e, index)}
                  type={"text"}
                  size={"sm"}
                />

                {errors[index]?.holderName && (
                  <ErrorMessage message={errors[index]["holderName"]} />
                )}
              </FormGroup>
            </Col>

            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"accountTitle"}>
                  Title of Account <RequiredField />
                </Label>
                <Input
                  name={"accountTitle"}
                  value={record.accountTitle}
                  onChange={(e) => handleChange(e, index)}
                  type={"text"}
                  size={"sm"}
                />
                {errors[index]?.accountTitle && (
                  <ErrorMessage message={errors[index]["accountTitle"]} />
                )}
              </FormGroup>
            </Col>
            <Col md="6" sm="6" xs="12">
              <FormGroup>
                <Label for={"accountNo"}>
                  Account No / IBAN <RequiredField />
                </Label>
                <Input
                  name={"accountNo"}
                  value={record.accountNo}
                  onChange={(e) => handleChange(e, index)}
                  type={"text"}
                  size={"sm"}
                />

                {errors[index]?.accountNo && (
                  <ErrorMessage message={errors[index]["accountNo"]} />
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex w-100 justify-content-end">
            <Button
              color="primary"
              className="mr-1"
              type="button"
              onClick={(e) => onSubmit(record, index)}
            >
              {record.id ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      ))}
      <div className="d-flex w-100 justify-content-start">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setErrors([
              ...errors,
              {
                bankCode: "",
                holderName: "",
                accountTitle: "",
                accountNo: "",
                type: "account-number",
              },
            ]);

            setbankInfo([
              ...bankInfo,
              {
                studentId: id,
                bankCode: "",
                holderName: "",
                accountTitle: "",
                accountNo: "",
                type: "account-number",
              },
            ]);
          }}
        >
          Add More
        </button>
      </div>
    </>
  );
}
