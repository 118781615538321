
export const clientsTableColumns = [
    {
        name: '',
        selector: 'fav',
    },
    {
        name: 'ID',
        selector: 'clientId',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
        center: true,
    },
    {
        name: 'Contact',
        selector: 'contact',
        sortable: true,
        center: true,
    },
    {
        name: 'City',
        selector: 'city',
        sortable: true,
        center: true,
    },
    {
        name: 'Added on',
        selector: 'added_on',
        sortable: true,
        center: true,
    },
    {
        name: 'Allocation',
        selector: 'allocation',
        sortable: true,
        center: true,
    },
    {
        name: 'Actions',
        selector: 'actions',
        center: true,
        
    }
]

export const clientsDocuemntsColumns = [
    {
        name: 'Title',
        selector: 'title',
        sortable: true,
    },
    {
        name: 'Actions',
        selector: 'actions',
        center: true,
        
    }
]

export const clientsAllocationColumns = [
    {
        name: 'Allocated To',
        selector: 'allocatedTo',
        sortable: true,
    },
    {
        name: 'Allocated From',
        selector: 'allocatedFrom',
        center: true,
        
    },
    {
        name: 'Allocated By',
        selector: 'allocatedBy',
        center: true,
        
    },
    {
        name: 'Date',
        selector: 'createdAt',
        center: true,
        
    }
]
export const clientsNotesColumns = [
    {
        name: 'Title',
        selector: 'title',
        sortable: true,
    },
    {
        name: 'Added Date',
        selector: 'createdAt',
        sortable: true,
        center: true,
    },
    {
        name: 'Note',
        selector: 'note',
        sortable: true,
        center: true,
    },
    {
        name: 'Actions',
        selector: 'actions',
        center: true,
        
    }
]

export const clientsEditLogColumns = [
    {
        name: 'Entry Date',
        selector: 'createdAt',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
        center: true,
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true,
    },
    {
        name: 'Landline',
        selector: 'phone',
        sortable: true,
        center: true,
    },
    {
        name: 'Cell',
        selector: 'cells',
        sortable: true,
        center: true,
    },
    {
        name: 'Updated By',
        selector: 'email',
        sortable: true,
        center: true,
    },
    {
        name: 'Edit Reason',
        selector: 'reason',
        sortable: true,
        center: true,
    },
]

export const sortByConfig = {
    title: "Clients",
    options: [
        {
            title: "ID",
            name: "id",
            type: "number",
            placeholder: "Search By ID"
        },
        {
            title: "Name",
            name: "name",
            type: "text",
            placeholder: "Search by Name"
        },
        {
            title: "Cell",
            name: "cell",
            type: "number",
            placeholder: "Search by Cell"
        },
        {
            title: "Email",
            name: "email",
            type: "email",
            placeholder: "Search by Email"
        }
    ],
}



export const SearchConfig = {
    api_url: "",
    title: "Search Clients",
    params: [
        {
            title: "ID",
            name: "id",
            type: "number",
            placeholder: "Search By ID",
        },
        {
            title: "Name",
            name: "name",
            type: "text",
            placeholder: "Search by Name"
        },
        {
            title: "Cell",
            name: "cell",
            type: "number",
            placeholder: "Search by Cell"
        },
        {
            title: "Email",
            name: "email",
            type: "email",
            placeholder: "Search by Email"
        },
        {
            title: "Occupation",
            name: "occupation",
            type: "text",
            placeholder: "Search by Occupation"
        },
        {
            title: "Allocation",
            name: "assignedTo",
            type: "select",
            placeholder: "Search by Staff",
            options:[]
        },
        {
            title: "Client Rating",
            name: "clientRatingId",
            type: "select",
            placeholder: "Search by Client Rating",
            options:[]
        },
        {
            title: "Client Type",
            name: "clientTypeId",
            type: "select",
            placeholder: "Search by Client Type",
            options:[]
        }
    ],
}




