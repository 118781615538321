import TeacherApi from "./teacherApi";
const teacherApi = new TeacherApi();


export const getTeachers = (state, cb) => async () => {
    try {
        const { data } = await teacherApi.getTeachers(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};

export const createTeacher = (values, cb) => async () => {
    try {
        const { data } = await teacherApi.createTeacher(values);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};

export const getTeacherDetail = (values, cb) => async () => {
    try {
        const { data } = await teacherApi.getTeacherDetail(values);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};