export const disableDashboard = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  let disabled = true;
  if (user?.role?.name === "Admin") {
    disabled = false;
  }
  if (user?.role?.name === "Saloon Admin") {
    disabled = false;
  }
  return disabled;
};
