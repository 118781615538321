// dashbaord
import Default from "../components/dashboard/default";
import ClientSummary from "../components/clients/summary";
import ClientClassification from "../components/clients/classification";
import ClientSearch from "../components/clients/classification";
import ClientAdd from "../components/clients/add";
import ClientDetails from "../components/clients/details";

// widgets
import GeneralWidget from "../components/widgets/general";
import ChartsWidget from "../components/widgets/charts";

// ui-kits
import StateColor from "../components/ui-kits/statecolor";
import Typography from "../components/ui-kits/typography";
import Avatars from "../components/ui-kits/avatars";
import HelperClasses from "../components/ui-kits/helperClasses";
import Grid from "../components/ui-kits/grid";
import TagAndPills from "../components/ui-kits/tagAndPills";
import Progress from "../components/ui-kits/progress";
import Modal from "../components/ui-kits/modal";
import Alert from "../components/ui-kits/alert";
import Popover from "../components/ui-kits/popover";
import Tooltip from "../components/ui-kits/tooltip";
import Spinner from "../components/ui-kits/spinner";
import Dropdown from "../components/ui-kits/dropDown";
import TabBootstrap from "../components/ui-kits/tabs/tabBootstrap";
import TabLine from "../components/ui-kits/tabs/tabLine";
import Accordian from "../components/ui-kits/accordian";
import Shadow from "../components/ui-kits/shadow";
import List from "../components/ui-kits/list";

// bonus-ui
import Scrolling from "../components/bonus_ui/scrolling";
import BootstrapNotify from "../components/bonus_ui/bootstrapNotify";
import Rating from "../components/bonus_ui/rating";
import Dropzone from "../components/bonus_ui/dropzone";
import SweetAlert from "../components/bonus_ui/sweetAlert";
import Tour from "../components/bonus_ui/tour";
import Sticky from "../components/bonus_ui/sticky";
import Breadcrumb from "../components/bonus_ui/breadcrumb";
import Imagecrop from "../components/bonus_ui/imagecrop";
import RangeSlider from "../components/bonus_ui/rangeSlider";
import Carousel from "../components/bonus_ui/carousel";
import Ribbons from "../components/bonus_ui/ribbons";
import Pagination from "../components/bonus_ui/pagination";
import DragAndDrop from "../components/bonus_ui/dragAndDrop";
import UploadImage from "../components/bonus_ui/uploadImage";

// Cards
import BasicCards from "../components/bonus_ui/cards/basicCards";
import ThemeCards from "../components/bonus_ui/cards/themeCards";
import TabCard from "../components/bonus_ui/cards/tabCard";
import DraggingCards from "../components/bonus_ui/cards/draggingCards";

// Timeline
import Timeline1 from "../components/bonus_ui/timelines/timeline";

// Buttons
import DefaultButton from "../components/buttons/default-Button";
import EdgeButton from "../components/buttons/edgeButton";
import FlatButton from "../components/buttons/flatButton";
import GroupButton from "../components/buttons/groupButton";
import RaisedButton from "../components/buttons/raisedButton";

// Blog
import BlogDetail from "../components/blog/blogDetail";
import BlogSingle from "../components/blog/blogSingle";
import BlogPost from "../components/blog/blogPost";

// Calendar
import BasicCalender from "../components/calendar/basicCalendar";
import DraggableCalendar from "../components/calendar/draggableCalendar";

// Users
import UserProfile from "../components/users/userProfile";
import UserEdit from "../components/users/userEdit";
import UserCards from "../components/users/userCards";

// Editor
import CkEditor from "../components/editor/ckEditor";
import MdeEditor from "../components/editor/mdeEditor";

// Social App
import SocialApp from "../components/social-app";

// FAQ page
import FaqComponent from "../components/faq";

// Support Ticket
import SupportTicket from "../components/support-ticket";

// Sample page
import Sample from "../components/sample";

// Search page
import Search from "../components/search";

// E-commerce-app
import Product from "../components/application/ecommerce-app/product";
import ProductDetail from "../components/application/ecommerce-app/productpage";
import Cart from "../components/application/ecommerce-app/cart";
import Wishlist from "../components/application/ecommerce-app/wishlist";
import Productlist from "../components/application/ecommerce-app/productlist";
import Paymentdetails from "../components/application/ecommerce-app/paymentdetails";
import OrderHistory from "../components/application/ecommerce-app/orderHistory";
import Checkout from "../components/application/ecommerce-app/checkout";
import Invoice from "../components/application/ecommerce-app/invoice";
import Pricing from "../components/application/ecommerce-app/pricing";

// Email
import Email from "../components/application/email-app/emailDefault";

// Chat
import Chat from "../components/application/chat-app";

// Bookmark
import Bookmark from "../components/application/bookmark";

// Project app
import ProjectList from "../components/application/project/project";
import NewProject from "../components/application/project/new-project";

// File Manager App
import FileManager from "../components/application/file-manager/file-manager";

// Kanban Board
import kanbanBoard from "../components/application/kanban-board/kanbanBoard";

// Task
import TaskApp from "../components/application/task-app";

// Contact app
import ContactApp from "../components/application/contact-app";

// todo-app
import Todo from "../components/application/todo-app";

// todo-firebase-app
import TodoFirebase from "../components/application/todo-firebase-app";

import ViewUser from "../components/final/users/view.jsx";
import Home from "../components/final/branch-admin/home/index.jsx";
import Teacher from "../components/final/branch-admin/teacher/Teachers";
import { CreateTeacher } from "../components/final/branch-admin/teacher/create-teacher/CreateTeacher";
import Class from "../components/final/branch-admin/class/index.jsx";
import Student from "../components/final/branch-admin/student/index.jsx";
import Family from "../components/final/branch-admin/family/index.jsx";
import Skills from "../components/final/branch-admin/skills/index.jsx";
import Analytics from "../components/final/branch-admin/analytics/index.jsx";

import SchoolRequests from "../components/final/superadmin/index.jsx";
import ExampleRequest from "../components/final/superadmin/example.jsx";
import ServerSideTable from "../components/final/superadmin/chatgpt";

import Register from "../pages/authentication/register";
import withRoleAccess from "./RoleProtectedComponent";
import { CreateBranch } from "../components/final/branches/create-branch/CreateBranch";
import { Branches } from "../components/final/branches/branches-list/Branches";
import { EditBranch } from "../components/final/branches/edit-branch/EditBranch";
import { Grades } from "../components/final/branches/grades/grade";
import { CreateGrade } from "../components/final/branches/grades/create-grade/CreateGrade";
import { CreateClass } from "../components/final/branch-admin/class/create-class/CreateClass";
import { CreateStudent } from "../components/final/branch-admin/student/create-student/CreateStudent";
import { StudentDetail } from "../components/final/branch-admin/student/student-detail/StudentDetail";
import { CreateSubject } from "../components/final/branch-admin/setup/subject/create/CreateSubject";
import { Subjects } from "../components/final/branch-admin/setup/subject/subject";
import ItemGridData from "../components/final/branch-admin/setup/item/index";
import { CreateItem } from "../components/final/branch-admin/setup/item/create/CreateItem";
import { InvoiceSetings } from "../components/final/branch-admin/setup/invoice-settings/InvoiceSettings";
import { InvoiceGeneratedGrid } from "../components/final/branch-admin/invoice/invoice-generate/index";
import { CreateInvoice } from "../components/final/branch-admin/invoice/invoice-generate/create/CreateInvoice";
import { InvoicePaymentGrid } from "../components/final/branch-admin/invoice/invoice-payment/index";
import { CreateInvoicePayment } from "../components/final/branch-admin/invoice/invoice-payment/create/CreateInvoicePayment";
import { TariffGrid } from "../components/final/branch-admin/tariff/index";
import { CreateFiscalYear } from "../components/final/branch-admin/tariff/create-tariff/CreateFiscalYear";
import { CreateTariff } from "../components/final/branch-admin/tariff/create-tariff/CreateTariff";
import { SectionSetup } from "../components/final/branch-admin/class/create-class/SectionSetup";
import { SaloonDetails } from "../components/final/saloon/SaloonDetails";
import SaloonAdminDashboard from "../components/final/dashboard/saloonAdmin";
import { CreateCategories } from "../components/final/categories/Create Categories/CreateCategories";
import { CreateServices } from "../components/final/services/Create Services/CreateServices";
import Appointments from "../components/final/appointments/Appointments";
import { Categories } from "../components/final/categories/CategoryList/Categories";
import { Roles } from "../components/final/roles/role-list";
import { CreateStaff } from "../components/final/staff/create-staff";
import { Staff } from "../components/final/staff/staff-list/Staff";
import { StaffSchedule } from "../components/final/staff/staff-schedule/staffSchedule";
import { Services } from "../components/final/services/Services List/ServiceList.jsx";
import Error401 from "../pages/errors/error401.jsx";

export const routes = [
  //Beudox-routes
  {
    path: "/dashboard",
    Component: withRoleAccess(Default),
  },

  { path: "/saloon/details", Component: withRoleAccess(SaloonDetails) },
  {
    path: "/saloon-admin/dashboard",
    Component: withRoleAccess(SaloonAdminDashboard),
  },
  { path: "/branch/create", Component: withRoleAccess(CreateBranch) },
  { path: "/categories", Component: withRoleAccess(Categories) },
  { path: "/category/:status", Component: withRoleAccess(CreateCategories) },
  { path: "/branch", Component: Branches },
  { path: "/service/:status", Component: CreateServices },
  { path: "/appointments", Component: withRoleAccess(Appointments) },
  { path: "/roles", Component: withRoleAccess(Roles) },
  { path: "/staff/create", Component: withRoleAccess(CreateStaff) },
  { path: "/staff", Component: withRoleAccess(Staff) },
  { path: "/staff/schedule/:id", Component: withRoleAccess(StaffSchedule) },
  { path: "/services", Component: withRoleAccess(Services) },
  //Secondary-routes

  // { path: "/user/view", Component: withRoleAccess(ViewUser) },
  // { path: "/branch/create-branch", Component: withRoleAccess(CreateBranch) },
  { path: "/branch/edit-branch/:id", Component: withRoleAccess(EditBranch) },

  { path: "/branch", Component: withRoleAccess(Branches) },

  { path: "/grade", Component: withRoleAccess(Grades) },
  { path: "/grade/create", Component: withRoleAccess(CreateGrade) },

  { path: "/register", Component: Register },

  { path: "/superadmin/schools", Component: withRoleAccess(SchoolRequests) },
  { path: "/superadmin/example", Component: withRoleAccess(ExampleRequest) },
  { path: "/superadmin/chatgpt", Component: withRoleAccess(ServerSideTable) },

  { path: "/home", Component: withRoleAccess(Home) },
  { path: "/class", Component: withRoleAccess(Class) },
  { path: "/teacher", Component: withRoleAccess(Teacher) },
  { path: "/create-class", Component: withRoleAccess(CreateClass) },
  { path: "/teacher/create", Component: withRoleAccess(CreateTeacher) },
  { path: "/students/create", Component: withRoleAccess(CreateStudent) },
  { path: "/students", Component: withRoleAccess(Student) },
  { path: "/students/detail/:id", Component: withRoleAccess(StudentDetail) },
  // { path: "/family", Component: withRoleAccess(Family) },
  // { path: "/skills", Component: withRoleAccess(Skills) },
  // { path: "/analytics", Component: withRoleAccess(Analytics) },

  { path: "/class-subject", Component: withRoleAccess(Subjects) },
  { path: "/class-subject/create", Component: withRoleAccess(CreateSubject) },
  { path: "/students/detail/:id", Component: withRoleAccess(StudentDetail) },

  { path: "/invoice-settings", Component: withRoleAccess(InvoiceSetings) },

  { path: "/item", Component: withRoleAccess(ItemGridData) },
  { path: "/item/create", Component: withRoleAccess(CreateItem) },
  { path: "/item/update/:id", Component: withRoleAccess(CreateItem) },

  {
    path: "/invoice-generate",
    Component: withRoleAccess(InvoiceGeneratedGrid),
  },
  { path: "/invoice/create", Component: withRoleAccess(CreateInvoice) },
  { path: "/invoice/detail/:id", Component: withRoleAccess(StudentDetail) },

  { path: "/invoice-payment", Component: withRoleAccess(InvoicePaymentGrid) },
  {
    path: "/invoice-payment/create",
    Component: withRoleAccess(CreateInvoicePayment),
  },
  {
    path: "/invoice-payment/detail/:id",
    Component: withRoleAccess(StudentDetail),
  },

  {
    path: "/invoice-payment/create",
    Component: withRoleAccess(CreateInvoicePayment),
  },
  {
    path: "/invoice-payment/detail/:id",
    Component: withRoleAccess(StudentDetail),
  },

  { path: "/tariff", Component: withRoleAccess(TariffGrid) },
  {
    path: "/tariff/fiscal-year/create",
    Component: withRoleAccess(CreateFiscalYear),
  },
  { path: "/tariff/setup", Component: withRoleAccess(CreateTariff) },

  { path: "/section-setup", Component: withRoleAccess(SectionSetup) },

  { path: "/clients/summary", Component: ClientSummary },
  { path: "/clients/manage/:type", Component: ClientClassification },
  { path: "/clients/search/", Component: ClientSearch },
  { path: "/clients/add", Component: ClientAdd },
  { path: "/clients/details/:id", Component: ClientDetails },

  { path: "/widgets/general", Component: GeneralWidget },
  { path: "/widgets/chart", Component: ChartsWidget },

  { path: "/ui-kits/statecolor", Component: StateColor },
  { path: "/ui-kits/typography", Component: Typography },
  { path: "/ui-kits/avatar", Component: Avatars },
  { path: "/ui-kits/helperclass", Component: HelperClasses },
  { path: "/ui-kits/grid", Component: Grid },
  { path: "/ui-kits/tagsandpills", Component: TagAndPills },
  { path: "/ui-kits/progress-bar", Component: Progress },
  { path: "/ui-kits/modal", Component: Modal },
  { path: "/ui-kits/alert", Component: Alert },
  { path: "/ui-kits/popover", Component: Popover },
  { path: "/ui-kits/tooltips", Component: Tooltip },
  { path: "/ui-kits/spinner", Component: Spinner },
  { path: "/ui-kits/dropdown", Component: Dropdown },
  { path: "/ui-kits/tab-bootstrap", Component: TabBootstrap },
  { path: "/ui-kits/tab-line", Component: TabLine },
  { path: "/ui-kits/accordion", Component: Accordian },
  { path: "/ui-kits/shadow", Component: Shadow },
  { path: "/ui-kits/list", Component: List },

  { path: "/bonus-ui/scrollable", Component: Scrolling },
  { path: "/bonus-ui/bootstrap-notify", Component: BootstrapNotify },
  { path: "/bonus-ui/rating", Component: Rating },
  { path: "/bonus-ui/dropzone", Component: Dropzone },
  { path: "/bonus-ui/sweetAlert", Component: SweetAlert },
  { path: "/bonus-ui/tourComponent", Component: Tour },
  { path: "/bonus-ui/stickyNotes", Component: Sticky },
  { path: "/bonus-ui/breadcrumb", Component: Breadcrumb },
  { path: "/bonus-ui/imageCropper", Component: Imagecrop },
  { path: "/bonus-ui/rangeSlider", Component: RangeSlider },
  { path: "/bonus-ui/carousel", Component: Carousel },
  { path: "/bonus-ui/ribbons", Component: Ribbons },
  { path: "/bonus-ui/pagination", Component: Pagination },
  { path: "/bonus-ui/dragNDropComp", Component: DragAndDrop },
  { path: "/bonus-ui/image-upload", Component: UploadImage },

  { path: "/bonus-ui/card/basicCards", Component: BasicCards },
  { path: "/bonus-ui/card/creativeCards", Component: ThemeCards },
  { path: "/bonus-ui/card/tabCard", Component: TabCard },
  { path: "/bonus-ui/card/draggingCards", Component: DraggingCards },

  { path: "/bonus-ui/timelines/timeline1", Component: Timeline1 },

  { path: "/buttons/default-btn", Component: DefaultButton },
  { path: "/buttons/flatBtn", Component: FlatButton },
  { path: "/buttons/edgeBtn", Component: EdgeButton },
  { path: "/buttons/raisedBtn", Component: RaisedButton },
  { path: "/buttons/groupBtn", Component: GroupButton },

  { path: "/app/blog/blogDetail", Component: BlogDetail },
  { path: "/app/blog/blogSingle", Component: BlogSingle },
  { path: "/app/blog/blogPost", Component: BlogPost },

  { path: "/app/users/userProfile", Component: UserProfile },
  { path: "/app/users/userEdit", Component: UserEdit },
  { path: "/app/users/userCards", Component: UserCards },

  { path: "/app/calendar/basic-calendar", Component: BasicCalender },
  { path: "/app/calendar/draggable-calendar", Component: DraggableCalendar },

  { path: "/app/editor/ckEditor", Component: CkEditor },
  { path: "/app/editor/mdeEditor", Component: MdeEditor },

  { path: "/app/social-app", Component: SocialApp },
  { path: "/app/faq", Component: FaqComponent },
  { path: "/app/support-ticket", Component: SupportTicket },

  { path: "/pages/samplepage", Component: Sample },
  { path: "/pages/searchpage", Component: Search },

  { path: "/app/ecommerce/product", Component: Product },
  { path: "/app/ecommerce/product-page/:id", Component: ProductDetail },
  { path: "/app/ecommerce/cart", Component: Cart },
  { path: "/app/ecommerce/wishlist", Component: Wishlist },
  { path: "/app/ecommerce/checkout", Component: Checkout },
  { path: "/app/ecommerce/invoice", Component: Invoice },
  { path: "/app/ecommerce/product-list", Component: Productlist },
  { path: "/app/ecommerce/payment-details", Component: Paymentdetails },
  { path: "/app/ecommerce/orderhistory", Component: OrderHistory },
  { path: "/app/ecommerce/pricing", Component: Pricing },

  { path: "/app/email-app", Component: Email },
  { path: "/app/chat-app", Component: Chat },
  { path: "/app/bookmark", Component: Bookmark },
  { path: "/app/task", Component: TaskApp },

  { path: "/app/project/project-list", Component: ProjectList },
  { path: "/app/project/new-project", Component: NewProject },

  { path: "/app/file-manager", Component: FileManager },
  { path: "/app/kanban-board", Component: kanbanBoard },

  { path: "/app/contact", Component: ContactApp },

  { path: "/app/todo-app/todo", Component: Todo },
  { path: "/app/todo-app/todo-firebase", Component: TodoFirebase },
];
