import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import GradeData from "./grade-data/GradeData";
import { getGrades } from "../../../../redux/branches/gradeActions";
import { TABLE_COMPONENT_CONTEXT } from "../../../../contexts/schoolRequests";
import { GradeFilters } from "./grade-data/GradeFilters";
import { useHistory } from "react-router-dom";

export function Grades() {
  const history = useHistory();
  const type = "grade";
  const [grades, setGrade] = useState({
    total_records: 0,
    data: [],
    numPages: 1,
  });
  const dispatch = useDispatch();
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    pageSize: 5,
    sortBy: "id",
    sortDirection: "DESC",
    status: "pending",
    hideOnNoData: 0,
  });

  const getGradesRequest = async () => {
    dispatch(
      getGrades(paginationConfig, (resp) => {
        if (resp.status) {
          setGrade({
            total_records: resp.data.grades.length,
            // numPages: resp.data.pagination.numPages,
            data: resp.data.grades,
          });
        }
      })
    );
  };

  useEffect(() => {
    getGradesRequest();
  }, [paginationConfig]);

  const handlePagination = (paginationData) => {
    let temp = { ...paginationConfig };
    temp["pageNumber"] = paginationData.current;
    setPaginationConfig(temp);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    // setSearchReq(searchReq)
  };
  const handleActionId = (id) => {
    // dispatch(schoolRequestDetails({ "requestId": id }, (resp) => {
    //     setRequestId(resp.data.details.id);
    //     addToggle();
    // }))
  };

  return (
    <Fragment>
      <Breadcrumb parent="dashboard" title={"Class"} />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="pt-0">
                <Row className="p-3">
                  <Col sm="12">
                    <TABLE_COMPONENT_CONTEXT.Provider
                      value={{
                        paginationConfig,
                        handlePagination,
                        setPaginationConfig,
                        staticPagination: true,
                      }}
                    >
                      {/* <GradeFilters /> */}
                      <Button
                        color="primary"
                        className="mr-1 float-right"
                        onClick={() => history.push("/grade/create")}
                      >
                        Create Class
                      </Button>
                      <GradeData
                        loading={false}
                        type={type}
                        paginationConfig={paginationConfig}
                        data={grades}
                        onClickSort={(value) => handleSorting(value)}
                        onClickPagination={(value) => handlePagination(value)}
                        onClickAction={(e) => handleActionId(e)}
                        setPaginationConfig={setPaginationConfig}
                      />
                    </TABLE_COMPONENT_CONTEXT.Provider>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
