import React, { Fragment, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Card,
  Container,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
  CardBody,
  CardFooter,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
// import { fields } from "./fields"
import { RequiredField } from "../../../../utils/fieldRequired";
import ErrorMessage from "../../../../utils/errorMessage";
import { Submit } from "../../../../../constant";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { useDispatch } from "react-redux";
// import { createTeacher } from "../../../../../redux/branch-admin/teacherActions"
import { toast } from "react-toastify";
import {
  createClass,
  getGrades,
  getGradesLevelSections,
} from "../../../../../redux/branch-admin/class/classActions";
import { getTeachers } from "../../../../../redux/branch-admin/teacherActions";
import { useLocation, useHistory } from "react-router-dom";
import { apiCall } from "../../../../../redux/apiCall";
import {
  create_tariff,
  fiscal_year_by_id,
  tariff_by_class_and_fiscal,
} from "../../../../../redux/endpoints";
import { EditTariffOptionModal } from "../tariff modals/EditTariffOptionModal";
import { CreateTariffItemModal } from "../tariff modals/CreateTariffItemModal";
import moment from "moment";
import { INSTANCE } from "../../../../../config/axiosInstance";

export function CreateTariff() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [addModal, setaddModal] = useState(false);
  const [selectedTariffs, setSelectedTariffs] = React.useState(null);
  const [tariffTypeToEdit, setTariffTypeToEdit] = React.useState("");
  const [tariffModal, setTariffModal] = React.useState(false);
  const [tariffItems, setTariffItems] = React.useState(null);
  const [tariffClassName, setTariffClassName] = React.useState("");
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("i");
  const title = queryParams.get("t");
  const range = queryParams.get("r");
  const dispatch = useDispatch();
  const initialValues = { title: title ? title : "" };
  React.useEffect(() => {
    if (id && title) {
      fetchFiscalDetails();
    }
  }, [id, title]);

  const fetchFiscalDetails = () => {
    dispatch(
      apiCall(fiscal_year_by_id, "POST", { recordId: id }, (resp) => {
        setData(resp.data);
      })
    );
  };
  const createTariffCallback = (data) => {
    if (data) {
      setTariffModal(false);
      let _tariffItems = [...tariffItems];
      _tariffItems.push(data);
      setTariffItems(_tariffItems);
    }
  };
  const onSubmit = (values, formActions) => {
    if (id && title) {
      dispatch(
        getGradesLevelSections({ ...values, gradeId: id }, (resp) => {
          if (resp.status) {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            toast.success("Grade updated successfully!");
            history.push(`/grade/create?i=1&t=${values.title}`);
          } else {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            toast.error(resp.message);
          }
        })
      );
    } else {
      dispatch(
        createClass(values, (resp) => {
          if (resp.status) {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            formActions.resetForm();
            toast.success("Grade created successfully!");
          } else {
            formActions.setSubmitting(false);
            setIsSubmitting(false);
            toast.error(resp.message);
          }
        })
      );
    }
  };

  const addToggle = () => {
    setaddModal(!addModal);
  };
  const handleClassTraiffs = async (values) => {
    let registration = Object.keys(values?.newRegistrationTariff).length
      ? values?.newRegistrationTariff
      : null;
    let promotion = Object.keys(values?.promotionTariff).length
      ? values?.promotionTariff
      : null;
    let newCreated = false;
    if (!registration) {
      // dispatch(apiCall(create_tariff, 'POST', { ...values, tariffType: 'registration' }, resp => { registration = resp.data }))
      await INSTANCE.post(create_tariff, {
        ...values,
        tariffType: "registration",
      });
      newCreated = true;
    }
    if (!promotion) {
      // dispatch(apiCall(create_tariff, 'POST', { ...values, tariffType: 'promotion' }, resp => { promotion = resp.data }))
      await INSTANCE.post(create_tariff, {
        ...values,
        tariffType: "promotion",
      });
      newCreated = true;
    }
    if (newCreated) {
      dispatch(
        apiCall(
          tariff_by_class_and_fiscal,
          "POST",
          { fiscalYearId: id, classId: values.classId },
          (resp) => {
            const { promotionTariff, newRegistrationTariff } = resp.data;
            setSelectedTariffs({
              registration: newRegistrationTariff,
              promotion: promotionTariff,
            });
            setTariffClassName(values.className);
            setTariffModal(true);
          }
        )
      );
    } else {
      setSelectedTariffs({ registration, promotion });
      setTariffClassName(values.className);
      setTariffModal(true);
    }
  };
  const proceedEditingTariff = () => {
    setTariffModal(!tariffModal);
    setTariffItems(selectedTariffs[tariffTypeToEdit].tariffItems);
  };
  return (
    <Fragment>
      <Breadcrumb
        parent="dashboard"
        title={`${id && title ? "Update " : "Create "} Fiscal Year`}
      />

      <Container fluid={true}>
        <Card>
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
              }) => (
                <Form className="p-3" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"title"}>
                          Fiscal Year Title <RequiredField />
                        </Label>
                        <Input
                          name={"title"}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type={"text"}
                          value={title}
                          size={"sm"}
                          disabled
                        />
                        {errors["title"] && touched["title"] && (
                          <ErrorMessage message={errors["title"]} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"range"}>
                          Fiscal Year Date Range <RequiredField />
                        </Label>
                        <Input
                          name={"range"}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type={"text"}
                          size={"sm"}
                          value={range}
                          disabled
                        />
                        {errors["range"] && touched["range"] && (
                          <ErrorMessage message={errors["range"]} />
                        )}
                      </FormGroup>
                    </Col>

                    {data?.classDetails.map((item, index) => (
                      <Col sm="4" className=" mt-4" key={index}>
                        <div className="project-box  shadow shadow-showcase ">
                          <span className={`badge badge-primary`}>
                            <i
                              className="fa fa-pencil font-white"
                              style={{ cursor: "pointer", padding: "8px" }}
                              onClick={() =>
                                handleClassTraiffs({
                                  fiscalYearId: id,
                                  classId: item?.classDetails?.id,
                                  className: item?.classDetails?.title,
                                  newRegistrationTariff:
                                    item?.newRegistrationTariff,
                                  promotionTariff: item?.promotionTariff,
                                })
                              }
                            ></i>
                          </span>
                          <h6>{item?.classDetails?.title}</h6>

                          <Table>
                            <tbody>
                              <tr style={{ backgroundColor: "white" }}>
                                <td>
                                  <strong>Managed By: </strong>
                                </td>
                                <td>
                                  {item?.classDetails?.classTeacher?.firstName +
                                    " " +
                                    item?.classDetails?.classTeacher?.lastName}
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "white" }}>
                                <td>
                                  <strong>Regisration Tariffs: </strong>
                                </td>
                                <td>
                                  {item?.newRegistrationTariff?.tariffItems
                                    ? item?.newRegistrationTariff?.tariffItems
                                        .length
                                    : 0}
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "white" }}>
                                <td>
                                  <strong>Promotion Tariffs: </strong>
                                </td>
                                <td>
                                  {item?.promotionTariff?.tariffItems
                                    ? item?.promotionTariff?.tariffItems.length
                                    : 0}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    ))}

                    {tariffItems && (
                      <Col
                        md="12"
                        sm="12"
                        xs="12"
                        className="mt-4"
                        style={{ border: "1px solid #efefef", padding: "15px" }}
                      >
                        <div className="text-center">
                          <strong>{`${tariffClassName} - ${tariffTypeToEdit} tariff items`}</strong>
                        </div>

                        <div className="text-right mb-2">
                          <Button
                            color="primary"
                            className="mr-1"
                            type="button"
                            onClick={addToggle}
                          >
                            {"+"}
                          </Button>
                        </div>

                        <Table hover borderless>
                          <thead>
                            <tr>
                              <th>Item</th>
                              <th>Amount</th>
                              <th>Item Type</th>
                              <th>Tariff Type</th>
                              <th>Date</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {tariffItems?.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.tariffItems?.name}</td>
                                <td>{item?.amount}</td>
                                <td>{item?.tariffItems?.amountType}</td>
                                <td>{item?.scheduleType}</td>
                                <td>
                                  {moment(item?.scheduleDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  <i
                                    className="fa fa-pencil font-primary"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>

      <CreateTariffItemModal
        open={addModal}
        setOpen={() => setaddModal(!addModal)}
        selectedTariffs={selectedTariffs}
        tariffTypeToEdit={tariffTypeToEdit}
        createTariffCallback={createTariffCallback}
      />
      <EditTariffOptionModal
        open={tariffModal}
        setOpen={() => setTariffModal(!tariffModal)}
        setTariffTypeToEdit={setTariffTypeToEdit}
        tariffTypeToEdit={tariffTypeToEdit}
        proceedEditingTariff={proceedEditingTariff}
      />
    </Fragment>
  );
}
