import React from 'react';
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button } from "reactstrap"
import { RequiredField } from '../../../../utils/fieldRequired';
import { StudentInformation } from './Personal Information fields/StudentInformation';
import { GuardianInformation } from './Personal Information fields/GuardianInformation';
import { AddressInformation } from './Personal Information fields/AddressInformation';
// import { PreviousSchoolInformation } from './PreviousSchoolInformation';
import { MedicalInformation } from './Personal Information fields/MedicalInformation';
export function PersonalInformation() {
    return <>

        <div className="py-1">
            <h6 style={{ fontSize: "18px", marginTop: "10px" }} className="text-primary py-1">Student Information</h6>
            <StudentInformation />
            <h6 style={{ fontSize: "18px", marginTop: "10px" }} className="text-primary py-1">Address Information</h6>
            <AddressInformation />
            <h6 style={{ fontSize: "18px", marginTop: "10px" }} className="text-primary py-1">Guardian Information</h6>
            <GuardianInformation />
            {/* <h6 style={{ fontSize: "18px" }} className="text-primary py-1">Previous School</h6>
            <PreviousSchoolInformation /> */}
            <h6 style={{ fontSize: "18px", marginTop: "10px" }} className="text-primary py-1">Medical Info</h6>
            <MedicalInformation />
        </div>
    </>
}