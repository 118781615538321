export const fields = [
  {
    name: "first_name",
    type: "text",
    label: "First Name",
    placeholder: "e.g; John",
    required: true,
  },
  {
    name: "last_name",
    type: "text",
    label: "Last Name",
    placeholder: "e.g; Doe",
    required: true,
  },
  {
    name: "email",
    type: "email",
    label: "Email",
    placeholder: "johndoe@gmail.com",
    required: true,
  },
  {
    name: "phone",
    type: "text",
    label: "Phone",
    placeholder: "",
    required: true,
  },
  {
    name: "address",
    type: "text",
    label: "Address",
    placeholder: "",
    required: true,
  },
  {
    name: "image",
    type: "file",
    label: "Image",
    placeholder: "",
    required: true,
  },
  //   {
  //     name: "documents",
  //     type: "file",
  //     label: "Document",
  //     placeholder: "",
  //     required: true,
  //   },
];
