import RequestsApi from "./requestsApi";

const requestsApi = new RequestsApi();

export const schoolRequests = (state, cb) => async () => {
    try {
        const { data } = await requestsApi.schoolRequests(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};
export const schoolRequestDetails = (state, cb) => async () => {
    try {
        const { data } = await requestsApi.schoolRequestDetails(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};
export const schoolRequestAction = (state, cb) => async () => {
    try {
        const { data } = await requestsApi.schoolRequestAction(state);
        cb(data);
    } catch (error) {
        cb(error?.response?.data);
    }
};