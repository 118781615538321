import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardHeader, CardBody } from 'reactstrap';
import { FormInput } from '../forms/formBuilder';
import { useHistory } from 'react-router-dom';
import TableComponent from '../common/TableComponentSimple';
import { useEffect } from 'react';
import { MonthArray } from '../../constant'
import { clientsAllocationColumns } from './clientDataConfig';

const ClientEditLogModal = (props) => {

    const clientId = props.clientId
    const endpoint = localStorage.getItem('api-endpoint');
    const token = localStorage.getItem('token');
    const [allocationsLog, setAllocationsLog] = useState([]);
    const history = useHistory();
    const [reload, setReload] = useState(true);
    const [paginationConfig, setPaginationConfig] = useState({
        pageNumber: 1,
        pageSize: 10,
        sortBy: 'id',
        order: 'ASC',
        hideOnNoData: 1
    });
    const onToggle = data => {
        props.clientEditLogModalToggle()
    };

    const MapClientEditLogObject = (resultObject) => {
        let clientObject = [];
        resultObject.forEach(element => {
            let date = new Date(element.createdAt);
            var hour = date.getHours()
            var suffix = hour >= 12 ? "PM":"AM";
            var hours = ((hour + 11) % 12 + 1);
            let dateString = date.getDate() + ' ' + MonthArray[date.getMonth()] + ' ' + date.getFullYear() +' '+hours+':'+date.getMinutes()+" "+ suffix
            clientObject.push({
                createdAt: dateString,
                allocatedTo: element.allocatedToData.fullName,
                allocatedFrom: element.allocatedFromData.fullName,
                allocatedBy: element.allocatedByData.fullName,
            });
        });


        return clientObject
    }

    useEffect(() => {
        fetch(endpoint + "clients/allocation-log/" + clientId, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => res.json())
            .then(
                (result) => {
                    setAllocationsLog(MapClientEditLogObject(result.package.data))
                },
                (error) => {
                    toast.error(error)
                }
            )
    }, [])


    const tableConfig = {
        tableColumns: clientsAllocationColumns,
        data: allocationsLog,
    };

    return (
        <Fragment>
            <TableComponent tableConfig={tableConfig} />
        </Fragment>
    );
};

export default ClientEditLogModal;