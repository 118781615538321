import React, { Fragment } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardHeader,
  Button,
  Spinner,
} from "reactstrap";
import { useDispatch } from "react-redux";
import {
  get_saloon_qna,
  get_saloon_by_id,
  public_saloon_details,
  get_service_types,
} from "../../../../constant/url";
import { apiCall } from "../../../../redux/apiCall";
import {
  Email,
  MarekjecnoMailId,
  BOD,
  DDMMYY,
  Designer,
  ContactUs,
  ContactUsNumber,
  LocationDetails,
  JOHANDIO,
  UserProfileDesc1,
  UserProfileDesc2,
  UserProfileDesc3,
  Comment,
  MarkJecno,
  Like,
  Follower,
  Following,
  Location,
} from "../../../../constant";
import "../style.css";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { useParams } from "react-router-dom";
import "./PublicSaloonDetail.css";
import { CreateAppointmentModal } from "../../appointments/appointment-modal/CreateAppointmentModal";
export function PublicSaloonDetails() {
  const dispatch = useDispatch();
  const { saloon_id } = useParams();
  let saloon = JSON.parse(localStorage.getItem("saloon"));
  const [saloonData, setSaloonData] = React.useState(null);
  const [branches, setBranches] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [selectedService, setSelectedService] = React.useState(null);
  const [qna, setQNA] = React.useState([]);
  const [url, setUrl] = React.useState();
  const [open, setOpen] = React.useState(false);

  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result);
    };
  };
  const fetchSaloon = () => {
    dispatch(
      apiCall(`${public_saloon_details}/${saloon.id}`, "GET", {}, (resp) => {
        setSaloonData(resp?.data?.saloon);
        setBranches(resp?.data?.branches);
      })
    );
  };
  const fetchServices = () => {
    dispatch(
      apiCall(get_service_types, "GET", {}, (resp) => {
        setServices(resp.data);
      })
    );
  };
  // get_service_types
  React.useEffect(() => {
    if (saloon) {
      fetchSaloon();
      fetchServices();
    }
  }, []);
  const handleService = (item) => {
    setSelectedService(item);
    setOpen(true);
  };
  return (
    <Fragment>
      <Container className="p-5">
        <div>
          <Fab
            // style={style}
            icon={<i className="fa fa-plus"></i>}
          >
            <Action text="Book Appointment" onClick={() => setOpen(!open)}>
              <i className="fa fa-calendar"></i>
            </Action>
          </Fab>
        </div>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <CardHeader
                  className="cardheader"
                  style={{
                    background: `url(${"https://mcdn.wallpapersafari.com/medium/34/9/b84Kks.jpg"})`,
                  }}
                ></CardHeader>
                <div className="user-image">
                  <div className="avatar">
                    <Media
                      body
                      alt=""
                      src={
                        url
                          ? url
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn8b3u5VzkYAdu1kEON3M2dGq_FvPA-g5onQ&usqp=CAU"
                      }
                      data-intro="This is Profile image"
                    />
                  </div>
                </div>
                <div className="info">
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-left">
                            <h6>
                              <i className="fa fa-envelope mr-2"></i> {Email}
                            </h6>
                            <span>{MarekjecnoMailId}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6>
                              <i className="fa fa-calendar"></i>   {BOD}
                            </h6>
                            <span>{DDMMYY}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title">
                          <a target="_blank" href="#javascript">
                            {saloonData?.name}
                          </a>
                        </div>
                        <div className="desc mt-2">{"Salon"}</div>
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-xs-mt">
                            <h6>
                              <i className="fa fa-phone"></i>   {ContactUs}
                            </h6>
                            <span>{ContactUsNumber}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6>
                              <i className="fa fa-location-arrow"></i>   
                              {Location}
                            </h6>
                            <span>{LocationDetails}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <div
                    className="social-media step4"
                    data-intro="This is your Social details"
                  >
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#javascript">
                          <i className="fa fa-rss"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="follow">
                    <Row>
                      <Col col="6" className="text-md-right border-right">
                        <div className="follow-num counter">
                          {saloonData?.employee_count}
                        </div>
                        <span>{"Employee Count"}</span>
                      </Col>
                      <Col col="6" className="text-md-left">
                        <div className="follow-num counter">
                          {saloonData?.branch_count}
                        </div>
                        <span>{"Branch Count"}</span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media">
                        <i
                          className="fa fa-location-arrow"
                          style={{ fontSize: "16px" }}
                        ></i>
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{"Branches"}</h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4 w-100">
                    {services.length ? (
                      services.map((item, index) => (
                        <Col md="3">
                          <div
                            className="card p-3 public-service"
                            key={index}
                            style={{
                              boxShadow:
                                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                            }}
                          >
                            <div
                              className="d-flex w-100 service-name"
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <Media
                                  className="img-thumbnail rounded-circle w-25 m-auto"
                                  src={
                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZj2gwkr4p5LgjCcie39Wkf32HM_R7ajBxKLrzvOoac6OrAOXn3qIIJiBXDmk7kNUqjWU&usqp=CAU"
                                  }
                                  alt="Generic placeholder image"
                                />
                              </div>
                              <h6
                                style={{ fontWeight: "400" }}
                                className="mt-2"
                              >
                                {item.name}{" "}
                                <i
                                  className="fa fa-plus-circle cursor-pointer"
                                  data-toggle="tooltip"
                                  title="Book Now"
                                  //   onClick={() => handleService(item)}
                                  onClick={() => setOpen(true)}
                                ></i>
                              </h6>
                            </div>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <div className="d-flex justify-content-center w-100">
                        <Spinner color="black" />
                      </div>
                    )}
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <CreateAppointmentModal
          open={open}
          setOpen={() => {
            if (open) {
              setSelectedService(null);
            }
            setOpen(!open);
          }}
          getAppointments={() => setSelectedService(null)}
          service={selectedService}
        />
      </Container>
    </Fragment>
  );
}
