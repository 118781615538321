import React, { Fragment } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import SortBy from "../../../../common/sortBy";
import TableComponent from "../../../../common/TableComponent";
import Spinner from "../../../../common/spinner";
import { useHistory } from "react-router-dom";
const BranchData = (props) => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const handleAction = (id) => {
    history.push(`/branch/edit-branch/${id}`);
    // props.onClickAction(id)
  };
  const schoolTableColumns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: true,
    },

    {
      name: "Employee Count",
      selector: "employee_count",
      sortable: true,
      center: true,
    },
    {
      name: "Address",
      selector: "address",
      sortable: true,
      center: true,
    },
    // {
    //     name: 'No. of Student',
    //     selector: 'studentsCount',
    //     sortable: true,
    //     center: true,
    // },
    // {
    //     name: 'No. of Teacher',
    //     selector: 'teachersCount',
    //     sortable: true,
    //     center: true,
    // },
    // {
    //     name: 'Actions',
    //     selector: 'actions',
    //     center: true,

    // }
  ];

  useEffect(() => {
    var BranchData = [];
    const data = props.data.data;
    data.forEach((element) => {
      const mappedData = {
        name: element.name,
        employee_count: element.employee_count,
        address: element.address,
        // studentsCount: element.studentsCount,
        // teachersCount: element.teachersCount,
        // actions: [
        //     <i className="fa fa-eye font-primary" style={{ cursor: "pointer" }} onClick={() => handleAction(element.id)} ></i>,
        // ]
      };
      BranchData.push(mappedData);
    });

    setData(BranchData);
  }, [props]);

  const tableConfig = {
    tableColumns: schoolTableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };

  return (
    <Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <TableComponent
          loading={props.loading}
          tableConfig={tableConfig}
          numPages={props.data?.numPages}
          setPaginationConfig={props.setPaginationConfig}
        />
      )}
    </Fragment>
  );
};

export default BranchData;
