import { INSTANCE } from "../../config/axiosInstance";

export default class TeacherApi {
    createTeacher = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/branch-admin/get-teachers-paginated",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };

    
    getTeachers = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/branch-admin/get-teachers-paginated",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };

    
    getTeacherDetail = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/branch-admin/get-teacher-details",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };

}
