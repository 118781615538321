import React, { Fragment } from "react"
import Breadcrumb from "../../../../../layout/breadcrumb"
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button } from "reactstrap"
// import { fields } from "./fields"
import { RequiredField } from "../../../../utils/fieldRequired"
import ErrorMessage from "../../../../utils/errorMessage"
import { Submit } from "../../../../../constant"
import { Formik, setIn } from 'formik';
import { validationSchema } from "./validationSchema"
import { useDispatch } from "react-redux"
// import { createTeacher } from "../../../../../redux/branch-admin/teacherActions"
import { toast } from "react-toastify"
import { getGradesLevelSections } from "../../../../../redux/branch-admin/class/classActions"
import { useLocation, useHistory } from 'react-router-dom';
import { apiCall } from "../../../../../redux/apiCall"
import moment from "moment"
import { create_fiscal_year, fiscal_year_by_id } from "../../../../../redux/endpoints"
export function CreateFiscalYear() {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const history = useHistory()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('i');
    const title = queryParams.get('t');
    const dispatch = useDispatch()
    const [initialValues, setInitialValues] = React.useState({ title: "", startDate: "", endDate: "" })
    React.useEffect(() => {
        if (id && title) {
            fetchFiscalYear()
        }
    }, [id, title])
    const fetchFiscalYear = () => {
        dispatch(apiCall(fiscal_year_by_id, 'POST', { recordId: id }, resp => {
            setInitialValues(resp?.data?.details)
        }))
    }
    const onSubmit = (values, formActions) => {
        if (id && title) {

            dispatch(apiCall(create_fiscal_year, 'POST', { ...values, recordId: id }, (resp) => {
                if (resp.status) {
                    formActions.setSubmitting(false)
                    toast.success("Fiscal record updated successfully!")
                }
                else {
                    formActions.setSubmitting(false);
                    toast.error(resp.message)
                }

            }))

        }
        else {
            dispatch(apiCall(create_fiscal_year, 'POST', values, (resp) => {
                if (resp.status) {
                    formActions.setSubmitting(false)
                    formActions.resetForm()
                    toast.success("Fiscal record created successfully!")
                }
                else {
                    formActions.setSubmitting(false);
                    setIsSubmitting(false)
                    toast.error(resp.message)
                }

            }))
        }


    };
    return <Fragment>

        <Breadcrumb parent="dashboard" title={`${id && title ? 'Update ' : "Create "} Fiscal Year`} />

        <Container fluid={true}>
            <Card>
                <CardBody>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                            <Form className="p-3" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6" sm="6" xs='12'>
                                        <FormGroup >
                                            <Label for={"title"}>
                                                Fiscal Year Title <RequiredField />
                                            </Label>
                                            <Input
                                                name={"title"}
                                                onChange={handleChange}
                                                type={"text"}
                                                size={"sm"}
                                                value={values['title']}
                                            />
                                            {errors["title"] && touched["title"] && (
                                                <ErrorMessage message={errors["title"]} />
                                            )}
                                        </FormGroup>

                                    </Col>
                                    <Col md="6" sm="6" xs='12'>
                                        <FormGroup >
                                            <Label for={"startDate"}>
                                                Fiscal Year Start <RequiredField />
                                            </Label>
                                            <Input
                                                name={"startDate"}
                                                onChange={handleChange}
                                                type={"date"}
                                                size={"sm"}
                                                value={moment(values['startDate']).format("YYYY-MM-DD")}
                                            />
                                            {errors["startDate"] && touched["startDate"] && (
                                                <ErrorMessage message={errors["startDate"]} />
                                            )}
                                        </FormGroup>

                                    </Col>
                                    <Col md="6" sm="6" xs='12'>
                                        <FormGroup >
                                            <Label for={"endDate"}>
                                                Fiscal Year End <RequiredField />
                                            </Label>
                                            <Input
                                                name={"endDate"}
                                                onChange={handleChange}
                                                min={values["startDate"] ? moment(values["startDate"]).add("years", 1).format("YYYY-MM-DD") : ""}
                                                type={"date"}
                                                size={"sm"}
                                                value={moment(values['endDate']).format("YYYY-MM-DD")}
                                            />
                                            {errors["endDate"] && touched["endDate"] && (
                                                <ErrorMessage message={errors["endDate"]} />
                                            )}
                                        </FormGroup>

                                    </Col>
                                </Row>
                                <div className="text-right">
                                    <Button color="primary" className="mr-1" type='submit' disabled={isSubmitting}>{id && title ? 'Update' : "Submit"}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </CardBody>

            </Card>
        </Container>
    </Fragment>
}