import { INSTANCE } from "../../config/axiosInstance";

export default class RequestApi {
    schoolRequests = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/super-admin/get-school-requests",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    schoolRequestDetails = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/super-admin/get-school-request-details",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
    schoolRequestAction = async (data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method: "POST",
                url: "/super-admin/get-school-request-action",
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };

}
