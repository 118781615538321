import React, { useState, useEffect } from "react";
import man from "../../../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
import { withRouter, useHistory } from "react-router-dom";
import {
  Password,
  EmailAddress,
  ForgotPassword,
  JWT,
  LoginWithJWT,
  SignIn,
  SuperAdminAPIUrl,
  RequestError,
  APIUrl,
} from "../../../constant";
import axios from "axios";
import { useForm } from "react-hook-form";
import validator from "../../../helper/validation";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/auth/authActions";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { apiCall } from "../../../redux/apiCall";
const SchoolLogin = (props) => {
  const { register, handleSubmit, errors } = useForm();

  const initalState = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState(initalState);

  const [togglePassword, setTogglePassword] = useState(false);

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    dispatch(
      login(formData, async (data) => {
        if (data?.status == 200) {
          localStorage.setItem("accessToken", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.user));
          if (data.data.saloon) {
            const trialToString = JSON.stringify(
              data.data.saloon?.subscription
            );
            const encodedTrial = btoa(trialToString);
            await localStorage.setItem("TET", encodedTrial);
            await localStorage.setItem(
              "saloon",
              JSON.stringify(data.data.saloon)
            );

            if (
              data.data.user.role.name === "Admin" ||
              data.data.user.role.name === "Saloon Admin"
            ) {
              history.push(`${process.env.PUBLIC_URL}/dashboard`, {
                replace: true,
              });
            } else {
              history.push(`${process.env.PUBLIC_URL}/appointments`, {
                replace: true,
              });
            }
          } else {
            history.push(`${process.env.PUBLIC_URL}/salon`, { replace: true });
          }
        } else {
          if (data.message === "User not Verified yet") {
            dispatch(
              apiCall(
                "/resend-email-verification",
                "POST",
                { email: formData?.email },
                (resp) => {
                  console.log(resp);
                  toast.dark(
                    "A verification email has been sent to your registered email address."
                  );
                }
              )
            );
          }
          toast.error(data.message);
        }
      })
    );
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img
                    className="img-fluid for-light"
                    style={{ height: "65px" }}
                    src={require("../../../assets/images/logo/login.png")}
                    alt=""
                  />
                  <img
                    className="img-fluid for-dark"
                    style={{ height: "65px" }}
                    src={require("../../../assets/images/logo/logo_dark.png")}
                    alt=""
                  />
                </a>
              </div>
              <div className="login-main login-tab">
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <h4>Sign In</h4>
                  <p>{"Enter your email & password to login"}</p>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Test@gmail.com"
                      innerRef={register({
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors.email && "Please enter proper email address"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      name="password"
                      innerRef={register({ required: true })}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors.email && "Please enter password"}
                    </span>

                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ml-3">
                      <Link className="link" to="/forgot-password">
                        {ForgotPassword}
                      </Link>
                      <br></br>
                      <br></br>
                    </div>
                    <Button color="primary" className="btn-block" type="submit">
                      {SignIn}
                    </Button>
                    <p className="mt-4 mb-0">
                      {"New to Beudox ? "}
                      <Link to="/register">Create Account</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(SchoolLogin);
