import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Please give proper email")
    .required("Email is required"),
  address: Yup.string().required("Address is required"),
  phone: Yup.string().required("Phone count is required"),
  image: Yup.mixed().required("Image is required"),
  //   documents: Yup.mixed().required("Documents is required"),
  branch_id: Yup.string().required("Please select branch"),
  role_ids: Yup.array().min(1, "Atleast one roles is required"),
});
