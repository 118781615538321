
export const ProjectBreakdownArray = [
    {
        id:"1",
        name: "Ace Avenue",
        clients:"6"
    },
    {
        id:"2",
        name: "The Springs",
        clients:"10"
    },
    {
        id:"3",
        name: "Abdullah Gardens",
        clients:"3"
    },
    {
        id:"4",
        name: "",
        clients:""
    },
    {
        id:"5",
        name: "",
        clients:""
    },
    {
        id:"6",
        name: "",
        clients:""
    },
    {
        id:"7",
        name: "",
        clients:""
    },
    {
        id:"8",
        name: "",
        clients:""
    },
    {
        id:"9",
        name: "",
        clients:""
    },
    {
        id:"10",
        name: "",
        clients:""
    },
]

export const StaffBreakdownArray = [
    {
        id:"1",
        name: "Test Bprop CEO",
        clients:"11"
    },
    {
        id:"2",
        name: "Test BDA BProperty",
        clients:"7"
    },
    {
        id:"3",
        name: "Umar Sales",
        clients:"3"
    },
    {
        id:"4",
        name: "Rabee Product Admin",
        clients:"3"
    },
    {
        id:"5",
        name: "",
        clients:""
    },
    {
        id:"6",
        name: "",
        clients:""
    },
    {
        id:"7",
        name: "",
        clients:""
    },
    {
        id:"8",
        name: "",
        clients:""
    },
    {
        id:"9",
        name: "",
        clients:""
    },
    {
        id:"10",
        name: "",
        clients:""
    },
]

export const RatingBreakdownArray = [
    {
        id:"1",
        name: "All",
        clients:"11"
    },
    {
        id:"2",
        name: "Very Rich",
        clients:"7"
    },
    {
        id:"3",
        name: "Middle Class",
        clients:"3"
    },
    {
        id:"4",
        name: "Rich",
        clients:"3"
    },
    {
        id:"5",
        name: "Lower Middle",
        clients:"2"
    },
    {
        id:"6",
        name: "",
        clients:""
    },
    {
        id:"7",
        name: "",
        clients:""
    },
    {
        id:"8",
        name: "",
        clients:""
    },
    {
        id:"9",
        name: "",
        clients:""
    },
    {
        id:"10",
        name: "",
        clients:""
    },
]

export const ClientTypeBreakdownArray = [
    {
        id:"1",
        name: "Direct Client",
        clients:"11"
    },
    {
        id:"2",
        name: "Agent",
        clients:"7"
    },
    {
        id:"3",
        name: "",
        clients:""
    },
    {
        id:"4",
        name: "",
        clients:""
    },
    {
        id:"5",
        name: "",
        clients:""
    },
    {
        id:"6",
        name: "",
        clients:""
    },
    {
        id:"7",
        name: "",
        clients:""
    },
    {
        id:"8",
        name: "",
        clients:""
    },
    {
        id:"9",
        name: "",
        clients:""
    },
    {
        id:"10",
        name: "",
        clients:""
    },
]

