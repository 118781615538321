import React, { Fragment, useState } from "react"
import Breadcrumb from "../../../../../layout/breadcrumb"
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button, Table, Modal, ModalBody, ModalHeader,
    CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, } from "reactstrap"
// import { fields } from "./fields"
import { RequiredField } from "../../../../utils/fieldRequired"
import ErrorMessage from "../../../../utils/errorMessage"
import { Submit } from "../../../../../constant"
import { Formik } from 'formik';
import { validationSchema } from "./validationSchema"
import { useDispatch } from "react-redux"
// import { createTeacher } from "../../../../../redux/branch-admin/teacherActions"
import { toast } from "react-toastify"
import { createClass, getGrades, getGradesLevelSections } from "../../../../../redux/branch-admin/class/classActions"
import { getTeachers } from "../../../../../redux/branch-admin/teacherActions"
import { useLocation, useHistory } from 'react-router-dom';

export function SectionSetup() {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [addModal, setaddModal] = useState(false)
    const history = useHistory()
    const location = useLocation();
    const [pillTab, setpillTab] = useState('1');
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('i');
    const title = queryParams.get('t');
    const dispatch = useDispatch()
    const initialValues = { title: title ? title : "" }
    React.useEffect(() => {
        if (id && title) {

        }
    }, [id, title])
    const onSubmit = (values, formActions) => {
        if (id && title) {

            dispatch(getGradesLevelSections({ ...values, gradeId: id }, (resp) => {
                if (resp.status) {
                    formActions.setSubmitting(false);
                    setIsSubmitting(false)
                    toast.success("Grade updated successfully!")
                    history.push(`/grade/create?i=1&t=${values.title}`)
                }
                else {
                    formActions.setSubmitting(false);
                    setIsSubmitting(false)
                    toast.error(resp.message)
                }

            }))

        }
        else {
            dispatch(createClass(values, (resp) => {
                if (resp.status) {
                    formActions.setSubmitting(false);
                    setIsSubmitting(false)
                    formActions.resetForm()
                    toast.success("Grade created successfully!")
                }
                else {
                    formActions.setSubmitting(false);
                    setIsSubmitting(false)
                    toast.error(resp.message)
                }

            }))
        }


    };


    const addToggle = () => { setaddModal(!addModal) };

    return <Fragment>

        <Breadcrumb parent="dashboard" title={`Section Setup`} />

        <Container fluid={true}>
            <Card>
                <CardBody>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                            <Form className="p-3" onSubmit={handleSubmit}>
                                <Row>
                                    
                                    
                                    
                                    <Col md="12" sm="12" xs='12' className="mt-4">
                                        
                                        <div className="text-right mb-2">
                                            <Button color="primary" className="mr-1" type='button'  onClick={addToggle}>{"+"}</Button>
                                        </div>
                                        

                                        <Table hover borderless>
                                            <thead>
                                                <tr>
                                                <th>Subject</th>
                                                <th>Teacher</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Subject Type</th>
                                                <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{"btn btn-primary btn-sm btn btn-primary btn-sm btn btn-primary btn-sm"}</td>
                                                    <td>{"btn btn-primary "}</td>
                                                    <td>{"10:50 "}</td>
                                                    <td>{"11:30 "}</td>
                                                    <td>{"Optional "}</td>
                                                    <td><i className="fa fa-pencil font-primary" style={{ cursor: "pointer" }} ></i>
                                                    <i className="fa fa-trash font-danger mx-2" style={{ cursor: "pointer" }} ></i></td>
                                                </tr>
                                                <tr>
                                                    <td>{"btn btn-primary btn-sm btn btn-primary btn-sm btn btn-primary btn-sm"}</td>
                                                    <td>{"btn btn-primary "}</td>
                                                    <td>{"10:50 "}</td>
                                                    <td>{"11:30 "}</td>
                                                    <td>{"Optional "}</td>
                                                    <td><i className="fa fa-pencil font-primary" style={{ cursor: "pointer" }} ></i>
                                                    <i className="fa fa-trash font-danger mx-2" style={{ cursor: "pointer" }} ></i></td>
                                                </tr>
                                                <tr>
                                                    <td>{"btn btn-primary btn-sm btn btn-primary btn-sm btn btn-primary btn-sm"}</td>
                                                    <td>{"btn btn-primary "}</td>
                                                    <td>{"10:50 "}</td>
                                                    <td>{"11:30 "}</td>
                                                    <td>{"Optional "}</td>
                                                    <td><i className="fa fa-pencil font-primary" style={{ cursor: "pointer" }} ></i>
                                                    <i className="fa fa-trash font-danger mx-2" style={{ cursor: "pointer" }} ></i></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        
                                    </Col>

                                    <Col className="mt-5" sm="12" xl="6 xl-100 box-col-12">
                                            <Nav className="nav-pills">
                                            <NavItem>
                                                <NavLink href="#javascript" className={pillTab === '1' ? 'active' : ''} onClick={() => setpillTab('1')}>{"Promoted Students"}</NavLink>
                                            </NavItem>
                                            <NavItem >
                                                <NavLink href="#javascript" className={pillTab === '2' ? 'active' : ''} onClick={() => setpillTab('2')}>{"Add New Student"}</NavLink>
                                            </NavItem>
                                            </Nav>
                                            <TabContent activeTab={pillTab}>
                                            <TabPane className="fade show" tabId="1">
                                                <Row className="mt-3">
                                                <Col md="6" sm="6" xs='12'>
                                                    <FormGroup >
                                                        <Label for={"title"}>
                                                            Fiscal Year <RequiredField />
                                                        </Label>
                                                        <Input
                                                            name={"classTeacherId"}

                                                            type={"select"}
                                                            size={"sm"}

                                                        >
                                                            <option value={false}>Select Fiscal Year</option>

                                                        </Input>
                                                        {errors["title"] && touched["title"] && (
                                                            <ErrorMessage message={errors["title"]} />
                                                        )}
                                                    </FormGroup>
                                                    
                                                </Col>
                                                <Col md="6" sm="6" xs='12'>
                                                    <FormGroup >
                                                        <Label for={"title"}>
                                                            Class <RequiredField />
                                                        </Label>
                                                        <Input
                                                            name={"classTeacherId"}

                                                            type={"select"}
                                                            size={"sm"}

                                                        >
                                                            <option value={false}>Select Class</option>

                                                        </Input>
                                                        {errors["title"] && touched["title"] && (
                                                            <ErrorMessage message={errors["title"]} />
                                                        )}
                                                    </FormGroup>
                                                    
                                                </Col>
                                                </Row>

                                                <Table className="mt-3" hover borderless>
                                                    <thead>
                                                        <tr>
                                                        <th></th>
                                                        <th>Student</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: "0.15rem" }}>
                                                            <FormGroup>
                                                                <Input type="checkbox" className="checkbox_animated" />
                                                                <Label>{""}</Label>
                                                            </FormGroup>
                                                            </td>
                                                            <td style={{ padding: "0.15rem" }}>{"Monthly Monthly Monthly "}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "0.15rem" }}>
                                                            <FormGroup>
                                                                <Input type="checkbox" className="checkbox_animated" />
                                                                <Label>{""}</Label>
                                                            </FormGroup>
                                                            </td>
                                                            <td style={{ padding: "0.15rem" }}>{"Monthly Monthly Monthly "}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "0.15rem" }}>
                                                            <FormGroup>
                                                                <Input type="checkbox" className="checkbox_animated" />
                                                                <Label>{""}</Label>
                                                            </FormGroup>
                                                            </td>
                                                            <td style={{ padding: "0.15rem" }}>{"Monthly Monthly Monthly "}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "0.15rem" }}>
                                                            <Input type="checkbox" className="checkbox_animated" />
                                                            <Label>{""}</Label>
                                                            </td>
                                                            <td style={{ padding: "0.15rem" }}>{"Monthly Monthly Monthly "}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                
                                            <Col md="12" sm="12" xs='12'>
                                                
                                                <div className="text-right mb-2">
                                                    <Button color="primary" className="mr-1" type='button'  >{"Add Student"}</Button>
                                                </div>
                                                <Table className="mt-3" hover borderless  style={{ width:"70%" }}>
                                                        <thead>
                                                            <tr>
                                                            <th>Student</th>
                                                            <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ padding: "0.35rem" }}>{"Monthly Monthly Monthly "}</td>
                                                                <td style={{ padding: "0.35rem" }}>
                                                                <i className="fa fa-trash font-danger mx-2" style={{ cursor: "pointer" }} ></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ padding: "0.35rem" }}>{"Monthly Monthly Monthly "}</td>
                                                                <td style={{ padding: "0.35rem" }}>
                                                                <i className="fa fa-trash font-danger mx-2" style={{ cursor: "pointer" }} ></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ padding: "0.35rem" }}>{"Monthly Monthly Monthly "}</td>
                                                                <td style={{ padding: "0.35rem" }}>
                                                                <i className="fa fa-trash font-danger mx-2" style={{ cursor: "pointer" }} ></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ padding: "0.35rem" }}>{"Monthly Monthly Monthly "}</td>
                                                                <td style={{ padding: "0.35rem" }}>
                                                                <i className="fa fa-trash font-danger mx-2" style={{ cursor: "pointer" }} ></i>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </TabPane>
                                            </TabContent>
                                    </Col>

                                </Row>
                                
                            </Form>
                        )}
                    </Formik>

                </CardBody>

            </Card>
        </Container>


        <Modal isOpen={addModal} toggle={addToggle} size="lg">
            <ModalHeader toggle={addToggle}>{"Add Subject"}</ModalHeader>
            <ModalBody>
            <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                            <Form className="p-3" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6" sm="6" xs='12'>
                                        <FormGroup >
                                            <Label for={"title"}>
                                                Subject <RequiredField />
                                            </Label>
                                            <Input
                                                name={"classTeacherId"}

                                                type={"select"}
                                                size={"sm"}

                                            >
                                                <option value={false}>Select Subject</option>

                                            </Input>
                                            {errors["title"] && touched["title"] && (
                                                <ErrorMessage message={errors["title"]} />
                                            )}
                                        </FormGroup>
                                        
                                    </Col>
                                    <Col md="6" sm="6" xs='12'>
                                        <FormGroup >
                                            <Label for={"range"}>
                                            Teacher <RequiredField />
                                            </Label>
                                            <Input
                                                name={"classTeacherId"}

                                                type={"select"}
                                                size={"sm"}

                                            >
                                                <option value={false}>Select Teacher</option>

                                            </Input>
                                            {errors["range"] && touched["range"] && (
                                                <ErrorMessage message={errors["range"]} />
                                            )}
                                        </FormGroup>
                                        
                                    </Col>
                                    <Col md="6" sm="6" xs='12'>
                                        <FormGroup >
                                            <Label for={"range"}>
                                            Start Time <RequiredField />
                                            </Label>
                                            <Input
                                                name={"range"}
                                                onChange={e => {
                                                    handleChange(e)
                                                }}
                                                type={"time"}
                                                size={"sm"}
                                            />
                                            {errors["range"] && touched["range"] && (
                                                <ErrorMessage message={errors["range"]} />
                                            )}
                                        </FormGroup>
                                        
                                    </Col>
                                    <Col md="6" sm="6" xs='12'>
                                        <FormGroup >
                                            <Label for={"range"}>
                                            End Time <RequiredField />
                                            </Label>
                                            <Input
                                                name={"range"}
                                                onChange={e => {
                                                    handleChange(e)
                                                }}
                                                type={"time"}
                                                size={"sm"}
                                            />
                                            {errors["range"] && touched["range"] && (
                                                <ErrorMessage message={errors["range"]} />
                                            )}
                                        </FormGroup>
                                        
                                    </Col>
                                    
                                    <Col md="6" sm="6" xs='12'>
                                        <FormGroup>
                                            <Label> Subject Type:</Label>
                                            <div className="m-checkbox-inline">
                                                <Label for="edo-ani">
                                                <Input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" defaultChecked />{" Compulsory"}
                                                    </Label>
                                                <Label for="edo-ani1">
                                                <Input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />{"Optional"}
                                                    </Label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md="12" sm="12" xs='12' className="mt-5">
                                        <FormGroup>
                                            <Label for={"range"}>
                                                Search Student
                                                </Label>
                                            <Input
                                                name={"range"}
                                                onChange={e => {
                                                    handleChange(e)
                                                }}
                                                type={"text"}
                                                size={"sm"}
                                            />
                                        </FormGroup>

                                        <Table className="mt-3" hover borderless>
                                            <thead>
                                                <tr>
                                                <th></th>
                                                <th>Student</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding: "0.15rem" }}>
                                                    <FormGroup>
                                                        <Input type="checkbox" className="checkbox_animated" />
                                                        <Label>{""}</Label>
                                                    </FormGroup>
                                                    </td>
                                                    <td style={{ padding: "0.15rem" }}>{"Monthly Monthly Monthly "}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: "0.15rem" }}>
                                                    <FormGroup>
                                                        <Input type="checkbox" className="checkbox_animated" />
                                                        <Label>{""}</Label>
                                                    </FormGroup>
                                                    </td>
                                                    <td style={{ padding: "0.15rem" }}>{"Monthly Monthly Monthly "}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: "0.15rem" }}>
                                                    <FormGroup>
                                                        <Input type="checkbox" className="checkbox_animated" />
                                                        <Label>{""}</Label>
                                                    </FormGroup>
                                                    </td>
                                                    <td style={{ padding: "0.15rem" }}>{"Monthly Monthly Monthly "}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: "0.15rem" }}>
                                                    <Input type="checkbox" className="checkbox_animated" />
                                                    <Label>{""}</Label>
                                                    </td>
                                                    <td style={{ padding: "0.15rem" }}>{"Monthly Monthly Monthly "}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </Col>
                                </Row>
                                <div class="modal-footer">
                                    
                                    <Button color="secondary"  type='button' >{"Close"}</Button>
                                    <Button color="primary" className="mr-1" type='submit' disabled={isSubmitting}>{"Add Subject"}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>

            </ModalBody>
        </Modal>


    </Fragment>
}