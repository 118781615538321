import React, { useState } from "react";
import { ReactComponent as EmailVerifiedSVG } from "../../../assets/svg/email_verified.svg";
import { ReactComponent as EmailUnVerifiedSVG } from "../../../assets/svg/error_email.svg";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verify_email } from "../../../constant/url";
import { apiCall } from "../../../redux/apiCall";

export function EmailVerified() {
  const [isLoading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const verifyEmail = () => {
    dispatch(
      apiCall(`${verify_email}/${token}`, "GET", {}, (resp) => {
        if (resp.status === 200) {
          setLoading(false);
          setIsVerified(true);
          setMessage(
            "Your email is successfully verified, redirecting to Login..."
          );
          setTimeout(() => {
            history.push("/", { replace: true });
          }, 3000);
        } else {
          setLoading(false);
          setIsVerified(false);
          setMessage(resp.message);
        }
      })
    );
  };
  React.useEffect(() => {
    if (token) {
      verifyEmail();
    }
  }, [token]);
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading ? (
        <>
          {" "}
          <div className="loader"></div>
        </>
      ) : (
        <>
          {isVerified ? (
            <div>
              <EmailVerifiedSVG height={"200px"} />
              <h5 className="text-dark text-center">{message}</h5>
            </div>
          ) : (
            <div>
              <EmailUnVerifiedSVG height={"200px"} />
              <h5 className="text-dark text-center">{message}</h5>
            </div>
          )}
        </>
      )}
    </div>
  );
}
