//student
export const get_students_metadata = "/common/create-student-meta-data"
export const create_student = "/branch-admin/student"
export const students_paginated = "/branch-admin/get-students-paginated"
export const student_by_id = "/branch-admin/student/get-details"
export const student_address_add = '/branch-admin/student/add-address'
export const student_address_update = '/branch-admin/student/update-address'
export const student_medical_add = '/branch-admin/student/add-medical'
export const student_guardian_add = '/branch-admin/student/add-guardian'
export const student_guardian_update = '/branch-admin/student/update-guardian'
export const student_guardian_remove = "/branch-admin/student/delete-guardian"
export const student_previous_schools = "/branch-admin/student/get-previous-school"
export const student_pevschool_add = "/branch-admin/student/add-previous-school"
export const student_pevschool_update = "/branch-admin/student/update-previous-school"
export const student_pevschool_delete = "/branch-admin/student/delete-previous-school"
export const student_bank_details = "/branch-admin/student/get-bank-details"
export const student_bank_details_add = "/branch-admin/student/add-bank-details"
export const student_bank_details_update = "/branch-admin/student/update-bank-details"
export const student_bank_details_delete = "/branch-admin/student/delete-bank-details"
export const student_accounts = '/branch-admin/get-students-account'
export const student_account_add = "/branch-admin/add-students-account"
export const student_account_update = "/branch-admin/update-students-account"
export const item_list = '/branch-admin/get-invoice-items'
export const student_account_remove = "/branch-admin/delete-students-account"

//classes
export const get_classes_paginated = '/branch-admin/get-class-paginated'

//Item
export const create_item = '/branch-admin/invoice/item/create'
export const get_item = '/branch-admin/invoice/item/get'
export const get_single_item = '/branch-admin/invoice/item/get-single'
export const update_item = '/branch-admin/invoice/item/update'

//Fiscal
export const create_fiscal_year = "/branch-admin/invoice/fiscal-year/create"
export const fiscal_year_paginated = '/branch-admin/invoice/fiscal-year/get'
export const fiscal_year_by_id = "/branch-admin/invoice/fiscal-year/details"
//Tariff
export const create_tariff = "/branch-admin/invoice/tariff/create"
export const create_tariff_item = "/branch-admin/invoice/tariff-item/create"
export const tariff_by_class_and_fiscal = '/branch-admin/invoice/tariff/get'