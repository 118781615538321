import React, { Fragment, useState } from "react";
import Loader from "../layout/loader";
import Taptop from "../layout/tap-top";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import Footer from "../layout/footer";
import ThemeCustomize from "../layout/theme-customizer";
import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import "./app.css";
import { Alert } from "reactstrap";
const App = ({ children }) => {
  const [sideBar, setSideBar] = useState("dashboard");
  const ETE = atob(localStorage.getItem("TET"));
  const parsedETE = JSON.parse(ETE);

  return (
    <Fragment>
      <Loader />
      <Taptop />

      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header handleSideBar={(value) => setSideBar(value)} />
        <div className="page-body-wrapper sidebar-icon">
          <Sidebar menuType={sideBar} />
          <div className="page-body">
            {parsedETE?.is_trail && (
              <Alert color="dark">{`You are currently using the trial version, and your trial will end in ${parsedETE?.trail_end_in} days`}</Alert>
            )}
            {children}
          </div>
          <Footer />
        </div>
      </div>
      {/* <ThemeCustomize /> */}
      <ToastContainer />
    </Fragment>
  );
};
export default withRouter(App);
