import React, { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom"
import TableComponent from '../../../common/TableComponent';
import { Spinner } from 'reactstrap';
import moment from "moment"
import { CreateRoleModal } from '../create-role/createRoleModal';
import { toast } from 'react-toastify';
import { apiCall } from '../../../../redux/apiCall';
import { useDispatch } from 'react-redux';
import { remove_role } from '../../../../constant/url';
const RolesData = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)
    const history = useHistory()
    const saloon = localStorage.getItem('saloon') ? JSON.parse(localStorage.getItem('saloon')) : null

    const handleAction = (element) => {
        setOpen(true)
        setSelected(element)
    }
    const removeRole = (element) => {
        dispatch(apiCall(remove_role, 'DELETE', { record_id: element.id, }, (resp) => {
            if (resp.status) {
                toast.dark("Record removed!")
                props.getRoles()
            }
        }))
    }
    const schoolTableColumns = [

        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            center: true,
        },

        {
            name: 'Created On',
            selector: 'created_at',
            sortable: true,
            center: true,
        },
        {
            name: 'Actions',
            selector: 'actions',
            center: true,

        }

    ]

    useEffect(() => {
        var BranchData = [];
        const data = props.data
        data.forEach(element => {
            const mappedData = {
                name: element.name,
                created_at: moment(element.created_at).format("DD/MM/YYYY"),
                actions: [
                    <i className="fa fa-eye font-primary" style={{ cursor: "pointer" }} onClick={() => handleAction(element)} ></i>,
                    <i className="fa fa-trash font-danger" style={{ cursor: "pointer" }} onClick={() => removeRole(element)} ></i>,
                ]
            }
            BranchData.push(mappedData)
        });


        setData(BranchData)
    }, [props])


    const tableConfig = {
        tableColumns: schoolTableColumns,
        data,
        onClickPagination: props.onClickPagination,
        paginationConfig: props.paginationConfig
    }

    return (
        <Fragment>
            {props.loading ? <Spinner /> : <TableComponent loading={props.loading} tableConfig={tableConfig} numPages={props.data?.numPages} setPaginationConfig={props.setPaginationConfig} />}
            <CreateRoleModal open={open} setOpen={setOpen} selected={selected} callback={props.getRoles} />
        </Fragment>

    );
};

export default RolesData;