import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Form, Row, FormGroup, Input, Label, Col, Button } from "reactstrap";
import ErrorMessage from "../../../utils/errorMessage";
import { RequiredField } from "../../../utils/fieldRequired";
import { Formik } from "formik";
import { Submit } from "../../../../constant";
import { validationSchema } from "./validationSchema";
export function PriceModal({ open, setOpen, handleSubmit }) {
  const initialValues = {
    amount_paid: "",
    pay_with: "",
  };
  const onSubmit = (values, formActions) => {
    handleSubmit(values);
  };

  return (
    <>
      <Modal isOpen={open} toggle={setOpen} size="sm">
        <ModalHeader toggle={setOpen}>Appointment</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form className="p-3" onSubmit={handleSubmit} id="reciept">
                <Row>
                  <Col md="12" sm="12" xs="12">
                    <FormGroup>
                      <Label for={"amount_paid"}>
                        Amount Paid <RequiredField />
                      </Label>
                      <Input
                        name={"amount_paid"}
                        onChange={handleChange}
                        type={"text"}
                        size={"sm"}
                        value={values["amount_paid"]}
                      />
                      {errors["amount_paid"] && touched["amount_paid"] && (
                        <ErrorMessage message={errors["amount_paid"]} />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="12" sm="12" xs="12">
                    <FormGroup>
                      <Label for={"pay_with"}>
                        Paid by <RequiredField />
                      </Label>
                      <Input
                        name={"pay_with"}
                        onChange={handleChange}
                        type={"select"}
                        size={"sm"}
                        value={values["pay_with"]}
                      >
                        <option value={""}>Select </option>
                        <option value={"cash"}>Cash</option>
                        <option value={"card"}>Card</option>
                      </Input>
                      {errors["pay_with"] && touched["pay_with"] && (
                        <ErrorMessage message={errors["pay_with"]} />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-right">
                  <Button color="primary" className="mr-1" type="submit">
                    {"Update"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}
