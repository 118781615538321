export const fields = [
    {
        name: "InvoiceDate",
        type: "date",
        label: "Invoice Date",
        placeholder: "Item Code",
        required: true,
    }, 
    {
        name: "Class",
        type: "select",
        label: "Class",
        placeholder: "Item Name",
        required: true,
    },  
]