import { INSTANCE } from "../config/axiosInstance";

export default class Service {
    call = async (url, method, data) => {
        return new Promise((resolve, reject) => {
            INSTANCE({
                method,
                url,
                data,
            })
                .then(resolve)
                .catch(reject);
        });
    };
}