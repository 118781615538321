import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import TeacherData from "./teacher-data/TeacherData";
import {
  getTeachers,
  getTeacherDetail,
} from "../../../../redux/branch-admin/teacherActions";
import { TABLE_COMPONENT_CONTEXT } from "../../../../contexts/schoolRequests";
import { TeacherFilters } from "./teacher-data/TeacherFilters";

const Teacher = () => {
  const [tableData, setTableData] = useState({
    total_records: 0,
    data: [],
  });
  const dispatch = useDispatch();
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    pageSize: 5,
    sortBy: "id",
    sortDirection: "DESC",
  });

  const getTeachersRequest = async () => {
    dispatch(
      getTeachers(paginationConfig, (resp) => {
        if (resp.status) {
          setTableData({
            total_records: resp.data.pagination.totalCount,
            numPages: resp.data.pagination.numPages,
            data: resp.data.records,
          });
        }
      })
    );
  };

  useEffect(() => {
    getTeachersRequest();
  }, [paginationConfig]);

  const handlePagination = (paginationData) => {
    let temp = { ...paginationConfig };
    temp["pageNumber"] = paginationData.current;
    setPaginationConfig(temp);
  };
  const handleSorting = (sortingData) => {
    setPaginationConfig(sortingData);
  };
  const handleSearch = (searchReq) => {
    // setSearchReq(searchReq)
  };
  const handleActionId = (id) => {
    dispatch(getTeacherDetail({ teacherId: id }, (resp) => {}));
  };

  return (
    <Fragment>
      <Breadcrumb parent="Project" title="Teacher" />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="pt-0">
                <Row className="p-3">
                  <Col sm="12">
                    <TABLE_COMPONENT_CONTEXT.Provider
                      value={{
                        paginationConfig,
                        handlePagination,
                        setPaginationConfig,
                      }}
                    >
                      <TeacherFilters />
                      <div>
                        <h6>Total Records : {tableData.total_records}</h6>
                      </div>
                      <TeacherData
                        loading={false}
                        paginationConfig={paginationConfig}
                        data={tableData}
                        onClickSort={(value) => handleSorting(value)}
                        onClickPagination={(value) => handlePagination(value)}
                        onClickAction={(e) => handleActionId(e)}
                        setPaginationConfig={setPaginationConfig}
                      />
                    </TABLE_COMPONENT_CONTEXT.Provider>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Teacher;
