import React, { Fragment } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import SortBy from "../../../../common/sortBy";
import TableComponent from "../../../../common/TableComponent";
import Spinner from "../../../../common/spinner";

const TeacherData = (props) => {
  const [data, setData] = useState([]);

  const handleAction = (id) => {
    props.onClickAction(id);
  };
  const tableColumns = [
    {
      name: "First Name",
      selector: "firstName",
      sortable: true,
      center: true,
    },
    {
      name: "Last Name",
      selector: "lastName",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      selector: "actions",
      center: true,
    },
  ];

  useEffect(() => {
    var TeacherData = [];
    const data = props.data.data;
    data.forEach((element) => {
      const mappedData = {
        firstName: element.firstName,
        lastName: element.lastName,
        email: element.email,
        actions: [
          <i
            className="fa fa-pencil font-primary"
            onClick={() => handleAction(element.id)}
          ></i>,
        ],
      };
      TeacherData.push(mappedData);
    });

    setData(TeacherData);
  }, [props]);

  const tableConfig = {
    tableColumns: tableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };

  return (
    <Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <TableComponent
          loading={props.loading}
          tableConfig={tableConfig}
          numPages={props.data?.numPages}
          setPaginationConfig={props.setPaginationConfig}
        />
      )}
    </Fragment>
  );
};

export default TeacherData;
