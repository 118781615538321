import React from "react"
import { Formik } from 'formik';
import { useDispatch } from "react-redux"
import * as Yup from 'yup';
import { Card, Container, Form, Row, FormGroup, Input, Label, Col, CardBody, CardFooter, Button } from "reactstrap"
import { RequiredField } from "../../../utils/fieldRequired";
import ErrorMessage from "../../../utils/errorMessage";
import { getSaloon } from "../../../utils/getSaloon";
import { get_branches, update_staff_schedule } from "../../../../constant/url";
import { apiCall } from "../../../../redux/apiCall";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";



export function ScheduleField({ schedule, branches }) {
    const saloon = getSaloon()
    const dispatch = useDispatch()
    const { id } = useParams()
    const [initialValues, setInitialValues] = React.useState({
        branch_id: "",
        working_day: "",
        repeating_days: "",
        start_time: "",
        end_time: ""
    })

    const validationSchema = Yup.object().shape({
        branch_id: Yup.string().required('Select branch'),
        working_day: Yup.string().required('Working day is not selected'),
        repeating_days: Yup.string().required('Set repeating days'),
        start_time: Yup.mixed().required('Start time is required'),
        end_time: Yup.mixed().required('End time is required'),
    });
    const timein24H = [
        "00:00:00",
        "01:00:00",
        "02:00:00",
        "03:00:00",
        "04:00:00",
        "05:00:00",
        "06:00:00",
        "07:00:00",
        "08:00:00",
        "09:00:00",
        "10:00:00",
        "11:00:00",
        "12:00:00",
        "13:00:00",
        "14:00:00",
        "15:00:00",
        "16:00:00",
        "17:00:00",
        "18:00:00",
        "19:00:00",
        "20:00:00",
        "21:00:00",
        "22:00:00",
        "23:00:00"
    ]
    const repeatingDays = ['1w', '2w', '3w', '1m']

    const onSubmit = (values, formActions) => {
        if(moment(values.start_time,'HH:mm:ss').isSameOrAfter(moment(values.end_time,'HH:mm:ss'))){
            toast.error("Shift starting time should be earlier than ending time")
            formActions.setSubmitting(false);
            return
         }
        dispatch(apiCall(update_staff_schedule, 'POST', { ...values, staff_id: id }, (resp) => {
            if (resp.status === 200) {
                
                formActions.setSubmitting(false);
                // formActions.resetForm()
                toast.success("Schedule changed successfully!")
            }
            else {
                formActions.setSubmitting(false);
                toast.error(resp.message)
            }

        }))

    };

    React.useEffect(() => {
        if (schedule && branches) {

            setInitialValues({ ...schedule, branch_id: schedule?.branch?.id })
        }

    }, [schedule, branches])
    return <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
    >
        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <Form className="p-3" onSubmit={handleSubmit}>
                <Row>
                    <Col >
                        <FormGroup >
                            <Label for={"role"}>
                                Branch  <RequiredField />
                            </Label>
                            <Input type="select" name="branch_id" onChange={handleChange}>
                                <option>Branches</option>
                                {branches.map((branch, index) => <option value={branch.id} key={index} selected={parseInt(values?.branch_id) === parseInt(branch.id)}> {branch.name}</option>)}
                            </Input>
                            {errors["branch_id"] && touched["branch_id"] && (
                                <ErrorMessage message={errors["branch_id"]} />
                            )}
                        </FormGroup>
                    </Col>
                    <Col >
                        <FormGroup >
                            <Label for={"role"}>
                                Working Day  <RequiredField />
                            </Label>
                            <Input type="text" name="working_day" disabled value={values?.working_day} >
                            </Input>
                            {errors["working_day"] && touched["working_day"] && (
                                <ErrorMessage message={errors["working_day"]} />
                            )}
                        </FormGroup>
                    </Col>
                    <Col >
                        <FormGroup >
                            <Label for={"role"}>
                                Repeating Days  <RequiredField />
                            </Label>
                            <Input type="select" name="repeating_days" onChange={handleChange}>
                                <option value="">Select repeating day</option>
                                {repeatingDays.map((day, index) => <option value={day} key={index} selected={values?.repeating_days === day}>{day}</option>)}
                            </Input>
                            {errors["repeating_days"] && touched["repeating_days"] && (
                                <ErrorMessage message={errors["repeating_days"]} />
                            )}
                        </FormGroup>
                    </Col>
                    <Col >
                        <FormGroup >
                            <Label for={"role"}>
                                Start Time  <RequiredField />
                            </Label>
                            <Input type="select" name="start_time" onChange={handleChange}>
                                <option value="">Select Start time</option>
                                {timein24H.map((time, index) => <option value={time} key={index} selected={values?.start_time === time}>{time}</option>)}
                            </Input>
                            {errors["start_time"] && touched["start_time"] && (
                                <ErrorMessage message={errors["start_time"]} />
                            )}
                        </FormGroup>
                    </Col>
                    <Col >
                        <FormGroup >
                            <Label for={"role"}>
                                End Time  <RequiredField />
                            </Label>
                            <Input type="select" name="end_time" onChange={handleChange}>
                                <option value="">Select End time</option>
                                {timein24H.map((time, index) => <option value={time} key={index} selected={values?.end_time === time}>{time}</option>)}
                            </Input>
                            {errors["end_time"] && touched["end_time"] && (
                                <ErrorMessage message={errors["end_time"]} />
                            )}
                        </FormGroup>
                    </Col>
                </Row>
                <div className="text-right">
                    <Button color="primary" className="mr-1" type='submit' disabled={isSubmitting || !saloon}>{"Save"}</Button>
                </div>
            </Form>
        )}
    </Formik>
}